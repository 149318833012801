.Shake {
  animation-name: shake;
  animation-duration: .4s;
  animation-fill-mode: both;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }

  10%, 50%, 90% {
    transform: translateX(-10px);
  }

  30%, 70% {
    transform: translateX(10px);
  }
}

@keyframes slidein {
  from {
    margin-top: -50%;
  }

  to {
    margin-top: 0%;
  }
}

@keyframes slideout {
  from {
    margin-top: 0%;
  }

  to {
    margin-top: -50%;
  }
}

@keyframes slideup_soft {
  from {
    opacity: 0;
    margin-top: 2%;
  }

  to {
    opacity: 1;
    margin-top: 0%;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadein_delay {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.form-checkbox {
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #4299e1;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #d2d6dc;
  border-radius: .25rem;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  display: inline-block;
}

.form-checkbox:checked {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media not print {
  .form-checkbox::-ms-check {
    color: #0000;
    background: inherit;
    border-width: 1px;
    border-color: inherit;
    border-radius: inherit;
  }
}

.form-checkbox:focus {
  border-color: #63b3ed;
  outline: none;
  box-shadow: 0 0 0 3px #4299e180;
}

.form-radio {
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #4299e1;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #d2d6dc;
  border-radius: 100%;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  display: inline-block;
}

.form-radio:checked {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media not print {
  .form-radio::-ms-check {
    color: #0000;
    background: inherit;
    border-width: 1px;
    border-color: inherit;
    border-radius: inherit;
  }
}

.form-radio:focus {
  border-color: #63b3ed;
  outline: none;
  box-shadow: 0 0 0 3px #4299e180;
}

.form-checkbox:checked:focus, .form-radio:checked {
  border-color: #0000;
}

body.tenant-tesla {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.tenant-tesla .btn, .tenant-tesla .btn.btn-magic {
  text-transform: uppercase !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
  background: none !important;
  border: 1px solid #fff !important;
  font-weight: 500 !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke !important;
  transition-duration: .1s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.tenant-tesla .btn:hover {
  background: radial-gradient(50% 50%, #6666 0%, #c4c4c400 100%) !important;
}

.tenant-tesla .btn.btn-blue, .tenant-tesla .btn.btn-magic {
  background: linear-gradient(#83838399 0%, #50505000 100%) !important;
}

.tenant-tesla .btn.btn-blue:hover, .tenant-tesla .btn.btn-magic:hover {
  background: linear-gradient(180deg, #83838399 0%, #50505000 100%) radial-gradient(50% 50% at 50% 50%, #6666 0%, #c4c4c400 100%) !important;
}

.tenant-tesla .Base {
  background: radial-gradient(50% 50%, #6666 0%, #c4c4c400 100%);
}

.tenant-tesla .Nav {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.tenant-tesla .Nav .btn {
  border: none !important;
}

.tenant-tesla .text-black, .tenant-tesla .text-gray-700, .tenant-tesla .text-cool-gray-600, .tenant-tesla .text-cool-gray-700, .tenant-tesla .text-cool-gray-800, .tenant-tesla .text-cool-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity, 1));
}

.tenant-tesla .text-cool-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.tenant-tesla .bg-cool-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 47 63 / var(--tw-bg-opacity, 1));
}

.tenant-tesla .bg-cool-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.tenant-tesla .bg-cool-gray-100, .tenant-tesla .hover\:.bg-cool-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.tenant-tesla .select-btn {
  --tw-border-opacity: 1 !important;
  border-width: 1px !important;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1)) !important;
  background-color: #0000 !important;
}

.tenant-tesla .Modal {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1)) !important;
}

.tenant-tesla .bg-blue-100, .tenant-tesla .hover\:bg-blue-100:hover, .tenant-tesla .hover\:bg-cool-gray-100:hover {
  background-color: #5f5f5f;
}

.tenant-tesla .border-blue-300 {
  border-color: #5f5f5f;
}

.tenant-tesla .text-blue-600 {
  color: #fff;
}

.tenant-tesla .border-blue-600, .tenant-tesla .border-cool-gray-600, .tenant-tesla .border-cool-gray-400 {
  border-color: #fff;
}

.tenant-tesla {
  font-family: Gotham A, Gotham B;
  font-style: normal;
  font-weight: 500;
}

.tenant-tesla .ManagePolicy {
  color: #fff;
}

.tenant-tesla .ManagePolicy .bg-white {
  background-color: #313131;
}

.tenant-tesla .ManagePolicy .bg-gray-50 {
  background-color: #4c4c4c;
}

.tenant-tesla .ManagePolicy .text-gray-900, .tenant-tesla .ManagePolicy .text-gray-800, .tenant-tesla .ManagePolicy .text-gray-700, .tenant-tesla .ManagePolicy .text-gray-600, .tenant-tesla .ManagePolicy .text-gray-500 {
  color: #fff;
}

.tenant-tesla .ManagePolicy .text-indigo-600 {
  color: #bebbf9;
}

.tenant-tesla .ManagePolicy .bg-blue-600, .tenant-tesla .ManagePolicy .bg-blue-400 {
  background-color: #5e5e5e;
}

.tenant-tesla .bg-blue-300, .tenant-tesla .bg-blue-500 {
  background-color: #1b1b1b;
}

.tenant-tesla .text-blue-500 {
  color: #fff;
}

.tenant-tesla .form-checkbox {
  --tw-bg-opacity: 1;
  background-color: rgb(159 166 178 / var(--tw-bg-opacity, 1));
}

.tenant-tesla .bg-green-100 {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.tenant-tesla .text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

@media (width >= 640px) {
  .tenant-tesla .ManagePolicy .sm\:bg-cool-gray-50 {
    background-color: #4e4e4e;
  }
}

.tenant-usaa .text-cool-gray-600, .tenant-usaa .text-cool-gray-700, .tenant-usaa .textbox, .tenant-usaa .Select-multi-value-wrapper, .tenant-usaa .Select-value, .tenant-usaa .Select-value-label, .tenant-usaa .Select-input, .tenant-usaa .Dropdown, .tenant-usaa .form-checkbox {
  color: #003a63 !important;
}

.tenant-usaa .btn-blue {
  background-color: #003a63;
  border: none;
}

.tenant-usaa .btn-blue:hover, .tenant-usaa .btn-blue:focus {
  background-color: #00668a;
  border: none;
}

.tenant-usaa .btn-magic {
  background: linear-gradient(#00668a 0%, #12395bcc 100%) !important;
}

.tenant-mercury .btn-blue {
  background-color: #991b1e;
  border: none;
}

.tenant-mercury.can-hover .btn-blue:hover, .tenant-mercury.can-hover .btn-blue:focus, .tenant-mercury.can-hover .btn-blue.btn-disabled:hover {
  background-color: #77080a;
  border: none;
}

.tenant-mercury .btn-magic {
  background: linear-gradient(90deg, #941a1b 0%, #77080a 100%) !important;
}

.tenant-progressive {
  --color-primary: #0077b3;
  --color-primary-active: #2862a4;
  --color-primary-muted: #b6def2;
}

.tenant-progressive .btn-blue {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.tenant-progressive.can-hover .btn-blue:hover, .tenant-progressive.can-hover .btn-blue.btn-disabled:hover, .tenant-progressive.can-hover .btn-blue:focus, .tenant-progressive.can-hover .btn-blue.btn-disabled:focus {
  background-color: var(--color-primary-active);
  border-color: var(--color-primary-active);
}

.tenant-progressive .btn-magic {
  background: var(--color-primary) !important;
}

.tenant-progressive.can-hover .btn-magic:hover, .tenant-progressive.can-hover .btn-magic:focus {
  background: var(--color-primary-active) !important;
}

.tenant-progressive .bg-blue-500, .tenant-progressive .bg-blue-600 {
  background-color: var(--color-primary);
}

.tenant-progressive .border-blue-600 {
  border-color: var(--color-primary);
}

.tenant-progressive .text-blue-500, .tenant-progressive .text-blue-600 {
  color: var(--color-primary);
}

.tenant-progressive .bg-blue-300 {
  background-color: var(--color-primary-muted);
}

.tenant-hiroad {
  --color-primary: #95d600;
  --color-primary-active: #8cc900;
  --color-text-on-primary: #233746;
  --color-gray-600: #41424c;
}

.tenant-hiroad .btn-blue {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-text-on-primary);
  font-weight: 500;
}

.tenant-hiroad.can-hover .btn-blue:hover, .tenant-hiroad.can-hover .btn-blue:focus, .tenant-hiroad.can-hover .btn-blue.btn-disabled:hover {
  background-color: var(--color-primary-active);
  border-color: var(--color-primary-active);
}

.tenant-hiroad .btn-magic {
  color: var(--color-text-on-primary);
  font-weight: 500;
  background: var(--color-primary) !important;
}

.tenant-hiroad.can-hover .btn-magic:hover {
  background: var(--color-primary-active) !important;
}

.tenant-hiroad .bg-blue-600 {
  background-color: var(--color-primary);
  color: var(--color-text-on-primary);
}

.tenant-hiroad .border-blue-600 {
  border-color: var(--color-primary);
}

.tenant-hiroad .btn.border-blue-600.text-blue-600 {
  color: var(--color-gray-600);
}

.tenant-statefarm {
  --color-primary: #d62311;
  --color-primary-active: #a11b13;
  --color-primary-active-background: #f4f3f3;
  --color-primary-muted: #e4e3e3;
  font-family: MecherleSans, Arial;
  font-style: normal;
  font-weight: 500;
}

body.tenant-statefarm {
  background-color: #fff;
}

.tenant-statefarm .text-cool-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(37 47 63 / var(--tw-text-opacity, 1));
}

.tenant-tesla .ManagePolicy .tenant-statefarm .text-cool-gray-800 {
  color: #fff;
}

.tenant-statefarm .text-cool-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.tenant-tesla .tenant-statefarm .text-cool-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity, 1));
}

.tenant-tesla .ManagePolicy .tenant-statefarm .text-cool-gray-700 {
  color: #fff;
}

.tenant-statefarm .text-cool-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.tenant-tesla .ManagePolicy .tenant-statefarm .text-cool-gray-600 {
  color: #fff;
}

.tenant-statefarm .text-cool-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.tenant-tesla .ManagePolicy .tenant-statefarm .text-cool-gray-500 {
  color: #fff;
}

.tenant-statefarm .text-cool-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(159 166 178 / var(--tw-text-opacity, 1));
}

.tenant-statefarm .text-cool-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(210 214 220 / var(--tw-text-opacity, 1));
}

.tenant-statefarm .bg-cool-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 47 63 / var(--tw-bg-opacity, 1));
}

.tenant-statefarm .bg-cool-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.tenant-statefarm .bg-cool-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.tenant-statefarm .bg-cool-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}

.tenant-statefarm .bg-cool-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(159 166 178 / var(--tw-bg-opacity, 1));
}

.tenant-statefarm .bg-cool-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(210 214 220 / var(--tw-bg-opacity, 1));
}

.tenant-statefarm h1, .tenant-statefarm h2, .tenant-statefarm h3, .tenant-statefarm h4 {
  font-weight: 500 !important;
  line-height: inherit !important;
}

.tenant-statefarm .text-md {
  font-size: 1rem !important;
}

.tenant-statefarm .Base {
  border: 1px solid #e4e3e3;
  box-shadow: none !important;
}

.tenant-statefarm .Nav {
  background-color: #fff;
  box-shadow: none !important;
  border: none !important;
  border-bottom: 1px solid #e4e3e3 !important;
}

.tenant-statefarm .btn {
  color: #403c3c;
  background-color: #fff;
  border: 1px solid #e4e3e3 !important;
  border-radius: 28px !important;
  font-weight: 600 !important;
}

.tenant-statefarm.can-hover .btn:hover, .tenant-statefarm.can-hover .btn:focus {
  background-color: #e4e3e3;
}

.tenant-statefarm .btn-blue {
  color: #fff;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.tenant-statefarm.can-hover .btn-blue:hover, .tenant-statefarm.can-hover .btn-blue.btn-disabled:hover, .tenant-statefarm.can-hover .btn-blue:focus, .tenant-statefarm.can-hover .btn-blue.btn-disabled:focus {
  background-color: var(--color-primary-active);
  border-color: var(--color-primary-active);
}

.tenant-statefarm .btn-magic {
  color: #fff;
  background: var(--color-primary) !important;
}

.tenant-statefarm.can-hover .btn-magic:hover, .tenant-statefarm.can-hover .btn-magic:focus {
  background: var(--color-primary-active) !important;
}

.tenant-statefarm .bg-blue-500, .tenant-statefarm .bg-blue-600 {
  background-color: var(--color-primary);
}

.tenant-statefarm .border-blue-400, .tenant-statefarm .border-blue-500, .tenant-statefarm .border-blue-600 {
  border-color: var(--color-primary);
}

.tenant-statefarm .text-blue-500, .tenant-statefarm .text-blue-600, .tenant-statefarm .text-blue-700 {
  color: var(--color-primary);
}

.tenant-statefarm .hover\:text-blue-700:hover {
  color: var(--color-primary-active);
}

.tenant-statefarm .bg-blue-300 {
  background-color: var(--color-primary-muted);
}

.tenant-statefarm .border-blue-300, .tenant-statefarm .focus\:border-blue-300:focus {
  border-color: var(--color-primary);
}

.tenant-statefarm .bg-blue-100 {
  background-color: var(--color-primary-active-background);
}

.tenant-statefarm .select-btn {
  --tw-border-opacity: 1;
  border-color: rgb(210 214 220 / var(--tw-border-opacity, 1));
}

.tenant-statefarm.can-hover .select-btn:not(.accented):hover, .tenant-statefarm.can-hover .select-btn:not(.accented):focus {
  --tw-bg-opacity: 1;
  background-color: rgb(244 245 247 / var(--tw-bg-opacity, 1));
}

.tenant-statefarm .textbox {
  --tw-border-opacity: 1;
  border-color: rgb(210 214 220 / var(--tw-border-opacity, 1));
}

.tenant-statefarm .textbox::placeholder {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.tenant-tesla .ManagePolicy .tenant-statefarm .textbox::placeholder {
  color: #fff;
}

.tenant-statefarm .Select-placeholder {
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1)) !important;
}

.tenant-tesla .ManagePolicy .tenant-statefarm .Select-placeholder {
  color: #fff !important;
}

.tenant-statefarm .Select-control {
  --tw-border-opacity: 1 !important;
  border-color: rgb(210 214 220 / var(--tw-border-opacity, 1)) !important;
}

.tenant-statefarm .shadow-outline-blue, .tenant-statefarm .focus\:shadow-outline-blue:focus, .tenant-statefarm .btn:focus, .tenant-statefarm.can-hover .btn:active, .tenant-statefarm.can-hover .btn.btn-subtle:active, .tenant-statefarm.can-hover .btn.btn-blue:active, .tenant-statefarm.can-hover .btn.btn-magic:active, .tenant-statefarm .textbox:focus, .tenant-statefarm .textbox:focus-within, .tenant-statefarm.can-hover .select-btn:focus, .tenant-statefarm.can-hover .select-btn:not(.accented):focus, .tenant-statefarm.can-hover .select-btn:active, .tenant-statefarm.can-hover .select-btn:not(.accented):active {
  --tw-shadow-color: #e5e7eb;
  --tw-shadow: var(--tw-shadow-colored);
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.tenant-statefarm footer.text-cool-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(159 166 178 / var(--tw-text-opacity, 1));
}

.tenant-statefarm .ManagePolicy.Base {
  border: none !important;
}

.tenant-statefarm .ManagePolicy .shadow-md {
  border: 1px solid #e4e3e3;
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

input:not([type="radio"]):not([type="checkbox"]) {
  -webkit-appearance: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3f83f880;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Inter var, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9fa6b2;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.container {
  width: 100%;
}

@media (width >= 390px) {
  .container {
    max-width: 390px;
  }
}

@media (width >= 412px) {
  .container {
    max-width: 412px;
  }
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (width >= 1920px) {
  .container {
    max-width: 1920px;
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #d2d6dc;
}

body {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity, 1));
  font-family: Inter var, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.block-scroll {
  height: 100%;
  overflow: hidden;
}

.btn {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  touch-action: manipulation;
  border-radius: .5rem;
  margin: .5rem;
  padding: 1rem 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.btn:focus {
  --tw-shadow: 0 0 0 3px #76a9fa73;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.can-hover .btn:hover, .can-hover .btn:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
}

.can-hover .btn:active, .can-hover .btn.btn-subtle:active, .can-hover .btn.btn-blue:active, .can-hover .btn.btn-magic:active {
  --tw-shadow: 0 0 0 3px #76a9fa73;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.btn.btn-disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.btn.btn-disabled.btn-magic {
  opacity: .5;
}

.can-hover .btn.btn-disabled:active {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.can-hover .btn.btn-disabled:hover, .can-hover .btn.btn-disabled:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity, 1));
}

.btn-blue, .can-hover .btn-blue.btn-disabled:hover, .can-hover .btn-blue.btn-disabled:focus {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(37 99 235 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}

.can-hover .btn-blue:hover, .can-hover .btn-blue:focus {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(30 66 159 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(30 66 159 / var(--tw-bg-opacity, 1));
}

.btn-subtle, .can-hover .btn-subtle.btn-disabled:hover {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity, 1));
  background-color: #0000;
}

.can-hover .btn-subtle:hover, .can-hover .btn-subtle:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.btn-blue.btn-subtle {
  --tw-border-opacity: 1;
  border-color: rgb(118 169 250 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity, 1));
  background-color: #0000;
}

.can-hover .btn-blue.btn-subtle:hover, .can-hover .btn-blue.btn-subtle:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.btn-magic {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  background-image: linear-gradient(135deg, #58b1ff, #2b6fd4);
  border-style: none;
  transition-property: all;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.can-hover .btn-magic:hover:not(.btn-disabled), .can-hover .btn-magic:focus:not(.btn-disabled) {
  background-image: linear-gradient(135deg, #5fa5e2, #106ffb);
}

.btn-text {
  --tw-text-opacity: 1;
  color: rgb(37 47 63 / var(--tw-text-opacity, 1));
  background-color: #0000;
  align-self: center;
  padding: .5rem 1rem;
  font-weight: 500;
}

.can-hover .btn-text:hover, .can-hover .btn-text:focus {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  background-color: #0000;
}

.select-btn {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
  text-align: center;
  border-radius: .5rem;
  align-items: center;
  margin: .5rem;
  padding: .75rem 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.can-hover .select-btn:not(.accented):hover, .can-hover .select-btn:not(.accented):focus {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.can-hover .select-btn:focus, .can-hover .select-btn:not(.accented):focus, .can-hover .select-btn:active, .can-hover .select-btn:not(.accented):active {
  --tw-shadow: 0 0 0 3px #76a9fa73;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.textbox {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
  border-radius: .5rem;
  width: 100%;
  padding: .5rem 1rem;
  transition: all .2s;
}

.textbox:focus, .textbox:focus-within {
  --tw-shadow: 0 0 0 3px #76a9fa73;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.textbox::placeholder {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}

.Select-placeholder {
  --tw-text-opacity: 1 !important;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1)) !important;
}

.Select-placeholder, .Select-input, .Select-value {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.Select-menu .Select-option {
  text-transform: capitalize;
}

.error-message {
  --tw-text-opacity: 1;
  color: rgb(200 30 30 / var(--tw-text-opacity, 1));
  margin-top: .5rem;
  font-size: .875rem;
  animation-name: fadein;
  animation-duration: .4s;
}

.Select-control > :last-child {
  padding-right: 8px !important;
}

.Select-value-label {
  text-transform: capitalize;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-bottom-5 {
  bottom: -1.25rem;
}

.-left-2 {
  left: -.5rem;
}

.-left-80 {
  left: -20rem;
}

.-left-\[12px\] {
  left: -12px;
}

.-right-2 {
  right: -.5rem;
}

.-right-\[0px\] {
  right: 0;
}

.-right-\[224px\] {
  right: -224px;
}

.-top-0\.5 {
  top: -.125rem;
}

.-top-7 {
  top: -1.75rem;
}

.-top-\[0\.05rem\] {
  top: -.05rem;
}

.-top-\[0\.2rem\] {
  top: -.2rem;
}

.-top-\[10\%\] {
  top: -10%;
}

.-top-\[16px\] {
  top: -16px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-0\.5 {
  bottom: .125rem;
}

.bottom-1 {
  bottom: .25rem;
}

.bottom-1\.5 {
  bottom: .375rem;
}

.bottom-12 {
  bottom: 3rem;
}

.bottom-16 {
  bottom: 4rem;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-2\.5 {
  bottom: .625rem;
}

.bottom-20 {
  bottom: 5rem;
}

.bottom-3 {
  bottom: .75rem;
}

.bottom-3\.5 {
  bottom: .875rem;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-8 {
  bottom: 2rem;
}

.bottom-\[-1px\] {
  bottom: -1px;
}

.bottom-\[-3px\] {
  bottom: -3px;
}

.bottom-\[-4px\] {
  bottom: -4px;
}

.bottom-\[-5\%\] {
  bottom: -5%;
}

.bottom-\[-6px\] {
  bottom: -6px;
}

.bottom-\[10px\] {
  bottom: 10px;
}

.bottom-\[12px\] {
  bottom: 12px;
}

.bottom-\[1px\] {
  bottom: 1px;
}

.bottom-\[26px\] {
  bottom: 26px;
}

.bottom-\[28px\] {
  bottom: 28px;
}

.bottom-\[2px\] {
  bottom: 2px;
}

.bottom-\[6px\] {
  bottom: 6px;
}

.bottom-\[90px\] {
  bottom: 90px;
}

.bottom-px {
  bottom: 1px;
}

.left-0 {
  left: 0;
}

.left-0\.5 {
  left: .125rem;
}

.left-1 {
  left: .25rem;
}

.left-1\.5 {
  left: .375rem;
}

.left-1\/2 {
  left: 50%;
}

.left-1\/3 {
  left: 33.3333%;
}

.left-12 {
  left: 3rem;
}

.left-2 {
  left: .5rem;
}

.left-2\.5 {
  left: .625rem;
}

.left-2\/3 {
  left: 66.6667%;
}

.left-3 {
  left: .75rem;
}

.left-3\.5 {
  left: .875rem;
}

.left-4 {
  left: 1rem;
}

.left-5 {
  left: 1.25rem;
}

.left-6 {
  left: 1.5rem;
}

.left-8 {
  left: 2rem;
}

.left-\[-10000px\] {
  left: -10000px;
}

.left-\[-10px\] {
  left: -10px;
}

.left-\[-2px\] {
  left: -2px;
}

.left-\[-3px\] {
  left: -3px;
}

.left-\[-8px\] {
  left: -8px;
}

.left-\[10px\] {
  left: 10px;
}

.left-\[12px\] {
  left: 12px;
}

.left-\[140px\] {
  left: 140px;
}

.left-\[192px\] {
  left: 192px;
}

.left-\[1px\] {
  left: 1px;
}

.left-\[25\%\] {
  left: 25%;
}

.left-\[2px\] {
  left: 2px;
}

.left-\[50\%\] {
  left: 50%;
}

.left-\[60px\] {
  left: 60px;
}

.left-\[75\%\] {
  left: 75%;
}

.left-\[calc\(50\%-12px\)\] {
  left: calc(50% - 12px);
}

.left-\[calc\(50\%-150px\/2\)\] {
  left: calc(50% - 75px);
}

.left-\[calc\(50\%-180px\/2\)\] {
  left: calc(50% - 90px);
}

.left-\[calc\(50\%-50px\)\] {
  left: calc(50% - 50px);
}

.left-px {
  left: 1px;
}

.right-0 {
  right: 0;
}

.right-0\.5 {
  right: .125rem;
}

.right-1 {
  right: .25rem;
}

.right-1\.5 {
  right: .375rem;
}

.right-1\/2 {
  right: 50%;
}

.right-1\/3 {
  right: 33.3333%;
}

.right-10 {
  right: 2.5rem;
}

.right-12 {
  right: 3rem;
}

.right-2 {
  right: .5rem;
}

.right-2\.5 {
  right: .625rem;
}

.right-2\/3 {
  right: 66.6667%;
}

.right-3 {
  right: .75rem;
}

.right-3\.5 {
  right: .875rem;
}

.right-4 {
  right: 1rem;
}

.right-5 {
  right: 1.25rem;
}

.right-6 {
  right: 1.5rem;
}

.right-8 {
  right: 2rem;
}

.right-\[-100\%\] {
  right: -100%;
}

.right-\[-10px\] {
  right: -10px;
}

.right-\[-3px\] {
  right: -3px;
}

.right-\[-8px\] {
  right: -8px;
}

.right-\[100\%\] {
  right: 100%;
}

.right-\[10px\] {
  right: 10px;
}

.right-\[12px\] {
  right: 12px;
}

.right-\[4px\] {
  right: 4px;
}

.right-px {
  right: 1px;
}

.top-0 {
  top: 0;
}

.top-0\.5 {
  top: .125rem;
}

.top-1 {
  top: .25rem;
}

.top-1\.5 {
  top: .375rem;
}

.top-1\/2 {
  top: 50%;
}

.top-10 {
  top: 2.5rem;
}

.top-12 {
  top: 3rem;
}

.top-14 {
  top: 3.5rem;
}

.top-2 {
  top: .5rem;
}

.top-2\.5 {
  top: .625rem;
}

.top-3 {
  top: .75rem;
}

.top-3\.5 {
  top: .875rem;
}

.top-4 {
  top: 1rem;
}

.top-5 {
  top: 1.25rem;
}

.top-6 {
  top: 1.5rem;
}

.top-8 {
  top: 2rem;
}

.top-\[-10\%\] {
  top: -10%;
}

.top-\[-1px\] {
  top: -1px;
}

.top-\[-2px\] {
  top: -2px;
}

.top-\[-3px\] {
  top: -3px;
}

.top-\[10px\] {
  top: 10px;
}

.top-\[110px\] {
  top: 110px;
}

.top-\[140px\] {
  top: 140px;
}

.top-\[151px\] {
  top: 151px;
}

.top-\[172px\] {
  top: 172px;
}

.top-\[178px\] {
  top: 178px;
}

.top-\[184px\] {
  top: 184px;
}

.top-\[187px\] {
  top: 187px;
}

.top-\[1px\] {
  top: 1px;
}

.top-\[2\.1rem\] {
  top: 2.1rem;
}

.top-\[2\.75rem\] {
  top: 2.75rem;
}

.top-\[2\.85rem\] {
  top: 2.85rem;
}

.top-\[201px\] {
  top: 201px;
}

.top-\[21px\] {
  top: 21px;
}

.top-\[227px\] {
  top: 227px;
}

.top-\[29px\] {
  top: 29px;
}

.top-\[2px\] {
  top: 2px;
}

.top-\[33px\] {
  top: 33px;
}

.top-\[3px\] {
  top: 3px;
}

.top-\[4px\] {
  top: 4px;
}

.top-\[50\%\] {
  top: 50%;
}

.top-\[68\%\] {
  top: 68%;
}

.top-\[calc\(50\%-24px\)\] {
  top: calc(50% - 24px);
}

.top-\[calc\(50\%-40vw\)\] {
  top: calc(50% - 40vw);
}

.top-auto {
  top: auto;
}

.top-full {
  top: 100%;
}

.top-px {
  top: 1px;
}

.isolate {
  isolation: isolate;
}

.-z-10 {
  z-index: -10;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[1000\] {
  z-index: 1000;
}

.z-\[1001\] {
  z-index: 1001;
}

.z-\[1002\] {
  z-index: 1002;
}

.z-\[100\] {
  z-index: 100;
}

.z-\[1010\] {
  z-index: 1010;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[2000\] {
  z-index: 2000;
}

.z-\[2001\] {
  z-index: 2001;
}

.z-\[2010\] {
  z-index: 2010;
}

.z-\[50\] {
  z-index: 50;
}

.z-\[60\] {
  z-index: 60;
}

.z-\[9999999\] {
  z-index: 9999999;
}

.z-\[999999\] {
  z-index: 999999;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-first {
  order: -9999;
}

.order-last {
  order: 9999;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-full {
  grid-column: 1 / -1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-end-4 {
  grid-column-end: 4;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-span-full {
  grid-row: 1 / -1;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-end-4 {
  grid-row-end: 4;
}

.float-right {
  float: right;
}

.\!m-0 {
  margin: 0 !important;
}

.-m-1 {
  margin: -.25rem;
}

.-m-2 {
  margin: -.5rem;
}

.-m-4 {
  margin: -1rem;
}

.-m-6 {
  margin: -1.5rem;
}

.m-0 {
  margin: 0;
}

.m-0\.5 {
  margin: .125rem;
}

.m-1 {
  margin: .25rem;
}

.m-2 {
  margin: .5rem;
}

.m-4 {
  margin: 1rem;
}

.m-8 {
  margin: 2rem;
}

.m-\[1px\] {
  margin: 1px;
}

.m-\[2px\] {
  margin: 2px;
}

.m-auto {
  margin: auto;
}

.-mx-0\.5 {
  margin-left: -.125rem;
  margin-right: -.125rem;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.-mx-3 {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-mx-48 {
  margin-left: -12rem;
  margin-right: -12rem;
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.-mx-\[22px\] {
  margin-left: -22px;
  margin-right: -22px;
}

.-my-1\.5 {
  margin-top: -.375rem;
  margin-bottom: -.375rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.-my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.-my-3 {
  margin-top: -.75rem;
  margin-bottom: -.75rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-0\.5 {
  margin-left: .125rem;
  margin-right: .125rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-1\.5 {
  margin-left: .375rem;
  margin-right: .375rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-\[2px\] {
  margin-left: 2px;
  margin-right: 2px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-1\.5 {
  margin-top: .375rem;
  margin-bottom: .375rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-\[0\.4px\] {
  margin-top: .4px;
  margin-bottom: .4px;
}

.my-\[2px\] {
  margin-top: 2px;
  margin-bottom: 2px;
}

.\!mb-0 {
  margin-bottom: 0 !important;
}

.\!ml-1 {
  margin-left: .25rem !important;
}

.\!ml-2 {
  margin-left: .5rem !important;
}

.\!mr-2 {
  margin-right: .5rem !important;
}

.\!mt-0 {
  margin-top: 0 !important;
}

.\!mt-1 {
  margin-top: .25rem !important;
}

.-mb-0 {
  margin-bottom: 0;
}

.-mb-0\.5 {
  margin-bottom: -.125rem;
}

.-mb-1 {
  margin-bottom: -.25rem;
}

.-mb-1\.5 {
  margin-bottom: -.375rem;
}

.-mb-10 {
  margin-bottom: -2.5rem;
}

.-mb-16 {
  margin-bottom: -4rem;
}

.-mb-2 {
  margin-bottom: -.5rem;
}

.-mb-2\.5 {
  margin-bottom: -.625rem;
}

.-mb-3 {
  margin-bottom: -.75rem;
}

.-mb-3\.5 {
  margin-bottom: -.875rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.-mb-5 {
  margin-bottom: -1.25rem;
}

.-mb-6 {
  margin-bottom: -1.5rem;
}

.-mb-8 {
  margin-bottom: -2rem;
}

.-mb-\[1px\] {
  margin-bottom: -1px;
}

.-mb-\[2px\] {
  margin-bottom: -2px;
}

.-mb-\[3px\] {
  margin-bottom: -3px;
}

.-mb-\[50px\] {
  margin-bottom: -50px;
}

.-mb-\[8\%\] {
  margin-bottom: -8%;
}

.-mb-px {
  margin-bottom: -1px;
}

.-ml-0 {
  margin-left: 0;
}

.-ml-0\.5 {
  margin-left: -.125rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-ml-1\.5 {
  margin-left: -.375rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.-ml-2\.5 {
  margin-left: -.625rem;
}

.-ml-3 {
  margin-left: -.75rem;
}

.-ml-3\.5 {
  margin-left: -.875rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.-ml-5 {
  margin-left: -1.25rem;
}

.-ml-6 {
  margin-left: -1.5rem;
}

.-ml-8 {
  margin-left: -2rem;
}

.-ml-\[10px\] {
  margin-left: -10px;
}

.-ml-\[18px\] {
  margin-left: -18px;
}

.-ml-\[1px\] {
  margin-left: -1px;
}

.-ml-\[20px\] {
  margin-left: -20px;
}

.-ml-\[3px\] {
  margin-left: -3px;
}

.-ml-px {
  margin-left: -1px;
}

.-mr-0 {
  margin-right: 0;
}

.-mr-0\.5 {
  margin-right: -.125rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.-mr-1\.5 {
  margin-right: -.375rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.-mr-2\.5 {
  margin-right: -.625rem;
}

.-mr-3 {
  margin-right: -.75rem;
}

.-mr-3\.5 {
  margin-right: -.875rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.-mr-5 {
  margin-right: -1.25rem;
}

.-mr-6 {
  margin-right: -1.5rem;
}

.-mr-8 {
  margin-right: -2rem;
}

.-mr-\[10px\] {
  margin-right: -10px;
}

.-mr-\[18px\] {
  margin-right: -18px;
}

.-mr-\[20px\] {
  margin-right: -20px;
}

.-mr-px {
  margin-right: -1px;
}

.-mt-0 {
  margin-top: 0;
}

.-mt-0\.5 {
  margin-top: -.125rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-1\.5 {
  margin-top: -.375rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.-mt-11 {
  margin-top: -2.75rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-2\.5 {
  margin-top: -.625rem;
}

.-mt-3 {
  margin-top: -.75rem;
}

.-mt-3\.5 {
  margin-top: -.875rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.-mt-7 {
  margin-top: -1.75rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.-mt-\[16px\] {
  margin-top: -16px;
}

.-mt-\[18px\] {
  margin-top: -18px;
}

.-mt-\[20px\] {
  margin-top: -20px;
}

.-mt-\[2px\] {
  margin-top: -2px;
}

.-mt-\[3px\] {
  margin-top: -3px;
}

.-mt-\[50px\] {
  margin-top: -50px;
}

.-mt-\[5px\] {
  margin-top: -5px;
}

.-mt-px {
  margin-top: -1px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-2\.5 {
  margin-bottom: .625rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-3\.5 {
  margin-bottom: .875rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-\[-1px\] {
  margin-bottom: -1px;
}

.mb-auto {
  margin-bottom: auto;
}

.mb-px {
  margin-bottom: 1px;
}

.ml-0 {
  margin-left: 0;
}

.ml-0\.5 {
  margin-left: .125rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-1\.5 {
  margin-left: .375rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-2\.5 {
  margin-left: .625rem;
}

.ml-20 {
  margin-left: 5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-3\.5 {
  margin-left: .875rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-\[-1px\] {
  margin-left: -1px;
}

.ml-\[-2px\] {
  margin-left: -2px;
}

.ml-\[14px\] {
  margin-left: 14px;
}

.ml-\[1px\] {
  margin-left: 1px;
}

.ml-\[200px\] {
  margin-left: 200px;
}

.ml-\[288px\] {
  margin-left: 288px;
}

.ml-\[40px\] {
  margin-left: 40px;
}

.ml-\[44px\] {
  margin-left: 44px;
}

.ml-\[5px\] {
  margin-left: 5px;
}

.ml-\[6px\] {
  margin-left: 6px;
}

.ml-auto {
  margin-left: auto;
}

.ml-px {
  margin-left: 1px;
}

.mr-0 {
  margin-right: 0;
}

.mr-0\.5 {
  margin-right: .125rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-2\.5 {
  margin-right: .625rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-3\.5 {
  margin-right: .875rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-\[0\.375rem\] {
  margin-right: .375rem;
}

.mr-\[14px\] {
  margin-right: 14px;
}

.mr-\[2px\] {
  margin-right: 2px;
}

.mr-\[48px\] {
  margin-right: 48px;
}

.mr-\[6px\] {
  margin-right: 6px;
}

.mr-\[7px\] {
  margin-right: 7px;
}

.mr-auto {
  margin-right: auto;
}

.mr-px {
  margin-right: 1px;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-2\.5 {
  margin-top: .625rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-3\.5 {
  margin-top: .875rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-\[-13\%\] {
  margin-top: -13%;
}

.mt-\[-30\%\] {
  margin-top: -30%;
}

.mt-\[-60px\] {
  margin-top: -60px;
}

.mt-\[0\.5\] {
  margin-top: .5px;
}

.mt-\[1px\] {
  margin-top: 1px;
}

.mt-\[22px\] {
  margin-top: 22px;
}

.mt-\[2px\] {
  margin-top: 2px;
}

.mt-auto {
  margin-top: auto;
}

.mt-px {
  margin-top: 1px;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-5 {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.\!inline {
  display: inline !important;
}

.inline {
  display: inline;
}

.\!flex {
  display: flex !important;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.aspect-\[1\/1\.05\] {
  aspect-ratio: 1 / 1.05;
}

.aspect-\[2\.46\] {
  aspect-ratio: 2.46;
}

.aspect-\[4\/3\] {
  aspect-ratio: 4 / 3;
}

.aspect-square {
  aspect-ratio: 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.\!h-\[39px\] {
  height: 39px !important;
}

.h-0 {
  height: 0;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-1\/2 {
  height: 50%;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-32 {
  height: 8rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-5\/6 {
  height: 83.3333%;
}

.h-52 {
  height: 13rem;
}

.h-56 {
  height: 14rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-72 {
  height: 18rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-9 {
  height: 2.25rem;
}

.h-96 {
  height: 24rem;
}

.h-\[1000px\] {
  height: 1000px;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[100vh\] {
  height: 100vh;
}

.h-\[100vw\] {
  height: 100vw;
}

.h-\[104px\] {
  height: 104px;
}

.h-\[10px\] {
  height: 10px;
}

.h-\[120px\] {
  height: 120px;
}

.h-\[127px\] {
  height: 127px;
}

.h-\[128px\] {
  height: 128px;
}

.h-\[12px\] {
  height: 12px;
}

.h-\[13px\] {
  height: 13px;
}

.h-\[144px\] {
  height: 144px;
}

.h-\[14px\] {
  height: 14px;
}

.h-\[15px\] {
  height: 15px;
}

.h-\[168px\] {
  height: 168px;
}

.h-\[16px\] {
  height: 16px;
}

.h-\[170px\] {
  height: 170px;
}

.h-\[18px\] {
  height: 18px;
}

.h-\[196px\] {
  height: 196px;
}

.h-\[19px\] {
  height: 19px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[2000px\] {
  height: 2000px;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[21px\] {
  height: 21px;
}

.h-\[220px\] {
  height: 220px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[25px\] {
  height: 25px;
}

.h-\[26px\] {
  height: 26px;
}

.h-\[280px\] {
  height: 280px;
}

.h-\[28px\] {
  height: 28px;
}

.h-\[29\.5rem\] {
  height: 29.5rem;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[32px\] {
  height: 32px;
}

.h-\[33px\] {
  height: 33px;
}

.h-\[35px\] {
  height: 35px;
}

.h-\[36px\] {
  height: 36px;
}

.h-\[38px\] {
  height: 38px;
}

.h-\[400px\] {
  height: 400px;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[40x\] {
  height: 40x;
}

.h-\[426px\] {
  height: 426px;
}

.h-\[42px\] {
  height: 42px;
}

.h-\[436px\] {
  height: 436px;
}

.h-\[44px\] {
  height: 44px;
}

.h-\[451px\] {
  height: 451px;
}

.h-\[46\%\] {
  height: 46%;
}

.h-\[472px\] {
  height: 472px;
}

.h-\[48px\] {
  height: 48px;
}

.h-\[4px\] {
  height: 4px;
}

.h-\[50\%\] {
  height: 50%;
}

.h-\[500px\] {
  height: 500px;
}

.h-\[508px\] {
  height: 508px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[526px\] {
  height: 526px;
}

.h-\[52px\] {
  height: 52px;
}

.h-\[54\%\] {
  height: 54%;
}

.h-\[54px\] {
  height: 54px;
}

.h-\[55px\] {
  height: 55px;
}

.h-\[56px\] {
  height: 56px;
}

.h-\[57px\] {
  height: 57px;
}

.h-\[600px\] {
  height: 600px;
}

.h-\[64px\] {
  height: 64px;
}

.h-\[65\%\] {
  height: 65%;
}

.h-\[6px\] {
  height: 6px;
}

.h-\[70\%\] {
  height: 70%;
}

.h-\[72px\] {
  height: 72px;
}

.h-\[76px\] {
  height: 76px;
}

.h-\[800px\] {
  height: 800px;
}

.h-\[80dvh\] {
  height: 80dvh;
}

.h-\[80px\] {
  height: 80px;
}

.h-\[80vw\] {
  height: 80vw;
}

.h-\[85px\] {
  height: 85px;
}

.h-\[88px\] {
  height: 88px;
}

.h-\[90vh\] {
  height: 90vh;
}

.h-\[98px\] {
  height: 98px;
}

.h-\[calc\(100\%-212px\)\] {
  height: calc(100% - 212px);
}

.h-\[calc\(100\%-36px\)\] {
  height: calc(100% - 36px);
}

.h-\[calc\(100\%-57px-56px\)\] {
  height: calc(100% - 113px);
}

.h-\[calc\(100dvh-281px\)\] {
  height: calc(100dvh - 281px);
}

.h-\[calc\(80dvh_-_100px\)\] {
  height: calc(80dvh - 100px);
}

.h-auto {
  height: auto;
}

.h-dvh {
  height: 100dvh;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-max {
  height: max-content;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.max-h-0 {
  max-height: 0;
}

.max-h-12 {
  max-height: 3rem;
}

.max-h-16 {
  max-height: 4rem;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-8 {
  max-height: 2rem;
}

.max-h-\[106px\] {
  max-height: 106px;
}

.max-h-\[140px\] {
  max-height: 140px;
}

.max-h-\[20\%\] {
  max-height: 20%;
}

.max-h-\[228px\] {
  max-height: 228px;
}

.max-h-\[382px\] {
  max-height: 382px;
}

.max-h-\[400px\] {
  max-height: 400px;
}

.max-h-\[450px\] {
  max-height: 450px;
}

.max-h-\[500px\] {
  max-height: 500px;
}

.max-h-\[528px\] {
  max-height: 528px;
}

.max-h-\[60vh\] {
  max-height: 60vh;
}

.max-h-\[800px\] {
  max-height: 800px;
}

.max-h-\[80dvh\] {
  max-height: 80dvh;
}

.max-h-\[90\%\] {
  max-height: 90%;
}

.max-h-\[90vh\] {
  max-height: 90vh;
}

.max-h-\[calc\(100vh-64px\)\] {
  max-height: calc(100vh - 64px);
}

.max-h-full {
  max-height: 100%;
}

.max-h-screen {
  max-height: 100vh;
}

.\!min-h-\[32px\] {
  min-height: 32px !important;
}

.min-h-0 {
  min-height: 0;
}

.min-h-5 {
  min-height: 1.25rem;
}

.min-h-\[110px\] {
  min-height: 110px;
}

.min-h-\[114px\] {
  min-height: 114px;
}

.min-h-\[116px\] {
  min-height: 116px;
}

.min-h-\[156px\] {
  min-height: 156px;
}

.min-h-\[225px\] {
  min-height: 225px;
}

.min-h-\[22px\] {
  min-height: 22px;
}

.min-h-\[24px\] {
  min-height: 24px;
}

.min-h-\[250px\] {
  min-height: 250px;
}

.min-h-\[256px\] {
  min-height: 256px;
}

.min-h-\[26px\] {
  min-height: 26px;
}

.min-h-\[270px\] {
  min-height: 270px;
}

.min-h-\[280px\] {
  min-height: 280px;
}

.min-h-\[28px\] {
  min-height: 28px;
}

.min-h-\[300px\] {
  min-height: 300px;
}

.min-h-\[30px\] {
  min-height: 30px;
}

.min-h-\[32px\] {
  min-height: 32px;
}

.min-h-\[350px\] {
  min-height: 350px;
}

.min-h-\[36px\] {
  min-height: 36px;
}

.min-h-\[39px\] {
  min-height: 39px;
}

.min-h-\[400px\] {
  min-height: 400px;
}

.min-h-\[40px\] {
  min-height: 40px;
}

.min-h-\[480px\] {
  min-height: 480px;
}

.min-h-\[48px\] {
  min-height: 48px;
}

.min-h-\[50\%\] {
  min-height: 50%;
}

.min-h-\[550px\] {
  min-height: 550px;
}

.min-h-\[55px\] {
  min-height: 55px;
}

.min-h-\[56px\] {
  min-height: 56px;
}

.min-h-\[580px\] {
  min-height: 580px;
}

.min-h-\[60px\] {
  min-height: 60px;
}

.min-h-\[64px\] {
  min-height: 64px;
}

.min-h-\[72px\] {
  min-height: 72px;
}

.min-h-\[750px\] {
  min-height: 750px;
}

.min-h-\[80px\] {
  min-height: 80px;
}

.min-h-\[84px\] {
  min-height: 84px;
}

.min-h-\[88px\] {
  min-height: 88px;
}

.min-h-\[8px\] {
  min-height: 8px;
}

.min-h-\[90px\] {
  min-height: 90px;
}

.min-h-\[96px\] {
  min-height: 96px;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.\!w-1\/3 {
  width: 33.3333% !important;
}

.\!w-\[240px\] {
  width: 240px !important;
}

.w-0 {
  width: 0;
}

.w-0\.5 {
  width: .125rem;
}

.w-1 {
  width: .25rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/5 {
  width: 20%;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-2\/12 {
  width: 16.6667%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-3\/12 {
  width: 25%;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-40 {
  width: 10rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-9 {
  width: 2.25rem;
}

.w-96 {
  width: 24rem;
}

.w-\[1\.5rem\] {
  width: 1.5rem;
}

.w-\[10\.5rem\] {
  width: 10.5rem;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[100px\] {
  width: 100px;
}

.w-\[100vw\] {
  width: 100vw;
}

.w-\[104px\] {
  width: 104px;
}

.w-\[10px\] {
  width: 10px;
}

.w-\[11\.5625rem\] {
  width: 11.5625rem;
}

.w-\[110px\] {
  width: 110px;
}

.w-\[115px\] {
  width: 115px;
}

.w-\[120px\] {
  width: 120px;
}

.w-\[128px\] {
  width: 128px;
}

.w-\[129px\] {
  width: 129px;
}

.w-\[12px\] {
  width: 12px;
}

.w-\[13\.3px\] {
  width: 13.3px;
}

.w-\[130px\] {
  width: 130px;
}

.w-\[135px\] {
  width: 135px;
}

.w-\[136px\] {
  width: 136px;
}

.w-\[13px\] {
  width: 13px;
}

.w-\[140px\] {
  width: 140px;
}

.w-\[142px\] {
  width: 142px;
}

.w-\[144px\] {
  width: 144px;
}

.w-\[14px\] {
  width: 14px;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[15px\] {
  width: 15px;
}

.w-\[160px\] {
  width: 160px;
}

.w-\[16px\] {
  width: 16px;
}

.w-\[175px\] {
  width: 175px;
}

.w-\[180px\] {
  width: 180px;
}

.w-\[18px\] {
  width: 18px;
}

.w-\[192px\] {
  width: 192px;
}

.w-\[19px\] {
  width: 19px;
}

.w-\[1px\] {
  width: 1px;
}

.w-\[20px\] {
  width: 20px;
}

.w-\[211px\] {
  width: 211px;
}

.w-\[21px\] {
  width: 21px;
}

.w-\[220px\] {
  width: 220px;
}

.w-\[227px\] {
  width: 227px;
}

.w-\[22px\] {
  width: 22px;
}

.w-\[240px\] {
  width: 240px;
}

.w-\[241px\] {
  width: 241px;
}

.w-\[247\] {
  width: 247px;
}

.w-\[24px\] {
  width: 24px;
}

.w-\[25\%\] {
  width: 25%;
}

.w-\[250px\] {
  width: 250px;
}

.w-\[255px\] {
  width: 255px;
}

.w-\[25px\] {
  width: 25px;
}

.w-\[260px\] {
  width: 260px;
}

.w-\[265px\] {
  width: 265px;
}

.w-\[26px\] {
  width: 26px;
}

.w-\[270px\] {
  width: 270px;
}

.w-\[275px\] {
  width: 275px;
}

.w-\[290px\] {
  width: 290px;
}

.w-\[297px\] {
  width: 297px;
}

.w-\[2px\] {
  width: 2px;
}

.w-\[30\%\] {
  width: 30%;
}

.w-\[300px\] {
  width: 300px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[318px\] {
  width: 318px;
}

.w-\[320px\] {
  width: 320px;
}

.w-\[32px\] {
  width: 32px;
}

.w-\[33px\] {
  width: 33px;
}

.w-\[340px\] {
  width: 340px;
}

.w-\[350px\] {
  width: 350px;
}

.w-\[35px\] {
  width: 35px;
}

.w-\[370px\] {
  width: 370px;
}

.w-\[384px\] {
  width: 384px;
}

.w-\[390px\] {
  width: 390px;
}

.w-\[400px\] {
  width: 400px;
}

.w-\[40px\] {
  width: 40px;
}

.w-\[420px\] {
  width: 420px;
}

.w-\[438px\] {
  width: 438px;
}

.w-\[44\%\] {
  width: 44%;
}

.w-\[440px\] {
  width: 440px;
}

.w-\[44px\] {
  width: 44px;
}

.w-\[480px\] {
  width: 480px;
}

.w-\[48px\] {
  width: 48px;
}

.w-\[490px\] {
  width: 490px;
}

.w-\[50\%\] {
  width: 50%;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[52px\] {
  width: 52px;
}

.w-\[54\%\] {
  width: 54%;
}

.w-\[552px\] {
  width: 552px;
}

.w-\[55px\] {
  width: 55px;
}

.w-\[560px\] {
  width: 560px;
}

.w-\[56px\] {
  width: 56px;
}

.w-\[58vw\] {
  width: 58vw;
}

.w-\[600px\] {
  width: 600px;
}

.w-\[60px\] {
  width: 60px;
}

.w-\[62px\] {
  width: 62px;
}

.w-\[640px\] {
  width: 640px;
}

.w-\[64px\] {
  width: 64px;
}

.w-\[68px\] {
  width: 68px;
}

.w-\[6px\] {
  width: 6px;
}

.w-\[700px\] {
  width: 700px;
}

.w-\[72\%\] {
  width: 72%;
}

.w-\[73px\] {
  width: 73px;
}

.w-\[75px\] {
  width: 75px;
}

.w-\[80dvw\] {
  width: 80dvw;
}

.w-\[80px\] {
  width: 80px;
}

.w-\[80vw\] {
  width: 80vw;
}

.w-\[82px\] {
  width: 82px;
}

.w-\[83px\] {
  width: 83px;
}

.w-\[87px\] {
  width: 87px;
}

.w-\[88px\] {
  width: 88px;
}

.w-\[8px\] {
  width: 8px;
}

.w-\[90\%\] {
  width: 90%;
}

.w-\[930px\] {
  width: 930px;
}

.w-\[948px\] {
  width: 948px;
}

.w-\[95px\] {
  width: 95px;
}

.w-\[96px\] {
  width: 96px;
}

.w-\[98\%\] {
  width: 98%;
}

.w-\[9px\] {
  width: 9px;
}

.w-\[calc\(100\%-10rem\)\] {
  width: calc(100% - 10rem);
}

.w-\[calc\(100\%-112px\)\] {
  width: calc(100% - 112px);
}

.w-\[calc\(100\%-290px\)\] {
  width: calc(100% - 290px);
}

.w-\[calc\(100\%-353px\)\] {
  width: calc(100% - 353px);
}

.w-\[calc\(100\%-40px\)\] {
  width: calc(100% - 40px);
}

.w-\[calc\(100\%-440px\)\] {
  width: calc(100% - 440px);
}

.w-\[calc\(100\%-482px\)\] {
  width: calc(100% - 482px);
}

.w-\[calc\(50\%-12px\)\] {
  width: calc(50% - 12px);
}

.w-auto {
  width: auto;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-min {
  width: min-content;
}

.w-screen {
  width: 100vw;
}

.min-w-0 {
  min-width: 0;
}

.min-w-12 {
  min-width: 3rem;
}

.min-w-\[0px\] {
  min-width: 0;
}

.min-w-\[100px\] {
  min-width: 100px;
}

.min-w-\[10px\] {
  min-width: 10px;
}

.min-w-\[10rem\] {
  min-width: 10rem;
}

.min-w-\[1120px\] {
  min-width: 1120px;
}

.min-w-\[144px\] {
  min-width: 144px;
}

.min-w-\[160px\] {
  min-width: 160px;
}

.min-w-\[170px\] {
  min-width: 170px;
}

.min-w-\[180px\] {
  min-width: 180px;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-\[20px\] {
  min-width: 20px;
}

.min-w-\[243px\] {
  min-width: 243px;
}

.min-w-\[24px\] {
  min-width: 24px;
}

.min-w-\[260px\] {
  min-width: 260px;
}

.min-w-\[280px\] {
  min-width: 280px;
}

.min-w-\[28px\] {
  min-width: 28px;
}

.min-w-\[300px\] {
  min-width: 300px;
}

.min-w-\[30px\] {
  min-width: 30px;
}

.min-w-\[400px\] {
  min-width: 400px;
}

.min-w-\[460px\] {
  min-width: 460px;
}

.min-w-\[50\%\] {
  min-width: 50%;
}

.min-w-\[50px\] {
  min-width: 50px;
}

.min-w-\[61px\] {
  min-width: 61px;
}

.min-w-\[660px\] {
  min-width: 660px;
}

.min-w-\[700px\] {
  min-width: 700px;
}

.min-w-\[760px\] {
  min-width: 760px;
}

.min-w-\[80px\] {
  min-width: 80px;
}

.min-w-\[8rem\] {
  min-width: 8rem;
}

.min-w-\[90px\] {
  min-width: 90px;
}

.min-w-\[theme\(spacing\.8\)\] {
  min-width: 2rem;
}

.min-w-fit {
  min-width: fit-content;
}

.min-w-full {
  min-width: 100%;
}

.min-w-min {
  min-width: min-content;
}

.\!max-w-\[1080px\] {
  max-width: 1080px !important;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-80 {
  max-width: 20rem;
}

.max-w-\[100px\] {
  max-width: 100px;
}

.max-w-\[1280px\] {
  max-width: 1280px;
}

.max-w-\[140px\] {
  max-width: 140px;
}

.max-w-\[142px\] {
  max-width: 142px;
}

.max-w-\[144px\] {
  max-width: 144px;
}

.max-w-\[150px\] {
  max-width: 150px;
}

.max-w-\[1536px\] {
  max-width: 1536px;
}

.max-w-\[1800px\] {
  max-width: 1800px;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[220px\] {
  max-width: 220px;
}

.max-w-\[224px\] {
  max-width: 224px;
}

.max-w-\[228px\] {
  max-width: 228px;
}

.max-w-\[250px\] {
  max-width: 250px;
}

.max-w-\[265px\] {
  max-width: 265px;
}

.max-w-\[280px\] {
  max-width: 280px;
}

.max-w-\[286px\] {
  max-width: 286px;
}

.max-w-\[300px\] {
  max-width: 300px;
}

.max-w-\[320px\] {
  max-width: 320px;
}

.max-w-\[32px\] {
  max-width: 32px;
}

.max-w-\[342px\] {
  max-width: 342px;
}

.max-w-\[352px\] {
  max-width: 352px;
}

.max-w-\[35rem\] {
  max-width: 35rem;
}

.max-w-\[360px\] {
  max-width: 360px;
}

.max-w-\[386px\] {
  max-width: 386px;
}

.max-w-\[40\%\] {
  max-width: 40%;
}

.max-w-\[400px\] {
  max-width: 400px;
}

.max-w-\[420px\] {
  max-width: 420px;
}

.max-w-\[440px\] {
  max-width: 440px;
}

.max-w-\[500px\] {
  max-width: 500px;
}

.max-w-\[540px\] {
  max-width: 540px;
}

.max-w-\[560px\] {
  max-width: 560px;
}

.max-w-\[600px\] {
  max-width: 600px;
}

.max-w-\[64px\] {
  max-width: 64px;
}

.max-w-\[65px\] {
  max-width: 65px;
}

.max-w-\[70\%\] {
  max-width: 70%;
}

.max-w-\[79px\] {
  max-width: 79px;
}

.max-w-\[90\%\] {
  max-width: 90%;
}

.max-w-\[900px\] {
  max-width: 900px;
}

.max-w-\[90vw\] {
  max-width: 90vw;
}

.max-w-\[91px\] {
  max-width: 91px;
}

.max-w-\[calc\(50\%-8px\)\] {
  max-width: calc(50% - 8px);
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-max {
  max-width: max-content;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-none {
  max-width: none;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.basis-0 {
  flex-basis: 0;
}

.basis-1\/2 {
  flex-basis: 50%;
}

.basis-1\/3 {
  flex-basis: 33.3333%;
}

.basis-1\/4 {
  flex-basis: 25%;
}

.basis-2\/3 {
  flex-basis: 66.6667%;
}

.basis-2\/4 {
  flex-basis: 50%;
}

.basis-24 {
  flex-basis: 6rem;
}

.basis-3\/4 {
  flex-basis: 75%;
}

.basis-40 {
  flex-basis: 10rem;
}

.basis-6 {
  flex-basis: 1.5rem;
}

.basis-8 {
  flex-basis: 2rem;
}

.basis-\[100px\] {
  flex-basis: 100px;
}

.basis-\[120px\] {
  flex-basis: 120px;
}

.basis-\[144px\] {
  flex-basis: 144px;
}

.basis-\[166px\] {
  flex-basis: 166px;
}

.basis-\[280px\] {
  flex-basis: 280px;
}

.basis-\[290px\] {
  flex-basis: 290px;
}

.basis-\[297px\] {
  flex-basis: 297px;
}

.basis-\[32px\] {
  flex-basis: 32px;
}

.basis-\[80px\] {
  flex-basis: 80px;
}

.basis-\[90px\] {
  flex-basis: 90px;
}

.basis-\[calc\(100\%-190px\)\] {
  flex-basis: calc(100% - 190px);
}

.basis-\[calc\(100\%-200px\)\] {
  flex-basis: calc(100% - 200px);
}

.basis-\[calc\(100\%-244px\)\] {
  flex-basis: calc(100% - 244px);
}

.basis-\[calc\(100\%-297px\)\] {
  flex-basis: calc(100% - 297px);
}

.basis-\[calc\(100\%-594px\)\] {
  flex-basis: calc(100% - 594px);
}

.basis-\[calc\(33\%_-_16px\)\] {
  flex-basis: calc(33% - 16px);
}

.basis-auto {
  flex-basis: auto;
}

.basis-full {
  flex-basis: 100%;
}

.table-auto {
  table-layout: auto;
}

.table-fixed {
  table-layout: fixed;
}

.border-collapse {
  border-collapse: collapse;
}

.origin-center {
  transform-origin: center;
}

.origin-right {
  transform-origin: 100%;
}

.origin-top {
  transform-origin: top;
}

.origin-top-left {
  transform-origin: 0 0;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.-translate-x-1 {
  --tw-translate-x: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-4 {
  --tw-translate-y: -1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[2px\] {
  --tw-translate-y: -2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[50px\] {
  --tw-translate-y: -50px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[calc\(100\%\+10px\)\] {
  --tw-translate-y: calc(calc(100% + 10px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-3 {
  --tw-translate-x: .75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[-50\%\] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2 {
  --tw-translate-y: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-1\.5px\] {
  --tw-translate-y: -1.5px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-2px\] {
  --tw-translate-y: -2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-50\%\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-95\%\] {
  --tw-translate-y: -95%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[0\.5px\] {
  --tw-translate-y: .5px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[1px\] {
  --tw-translate-y: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[calc\(100\%\+10px\)\] {
  --tw-translate-y: calc(100% + 10px);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-180 {
  --tw-rotate: -180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\!scale-\[1\.6\] {
  --tw-scale-x: 1.6 !important;
  --tw-scale-y: 1.6 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[1\.01\] {
  --tw-scale-x: 1.01;
  --tw-scale-y: 1.01;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[1\.15\] {
  --tw-scale-x: 1.15;
  --tw-scale-y: 1.15;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[1\.6\] {
  --tw-scale-x: 1.6;
  --tw-scale-y: 1.6;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-90 {
  --tw-scale-x: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes keyframes-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-fadein {
  animation: .2s ease-in-out keyframes-fadein;
}

@keyframes keyframes-fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.animate-fadeout {
  animation: .2s ease-in-out keyframes-fadeout;
}

@keyframes keyframes-flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-flash {
  animation: .3s ease-in-out keyframes-flash;
}

@keyframes highlight-indigo {
  0% {
    color: #4f46e5;
    background-color: #eef2ff;
  }

  100% {
    background-color: #eef2ff00;
  }
}

.animate-highlight-indigo {
  animation: 2s linear highlight-indigo;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes keyframes-scale {
  0%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(.92);
  }
}

.animate-scale {
  animation: .2s ease-in-out keyframes-scale;
}

@keyframes keyframes-slideup {
  0% {
    opacity: 0;
    transform: translateY(30%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slideup {
  animation: .2s linear keyframes-slideup;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-grab {
  cursor: grab;
}

.cursor-move {
  cursor: move;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.select-text {
  -webkit-user-select: text;
  user-select: text;
}

.select-all {
  -webkit-user-select: all;
  user-select: all;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.snap-x {
  scroll-snap-type: x var(--tw-scroll-snap-strictness);
}

.snap-mandatory {
  --tw-scroll-snap-strictness: mandatory;
}

.snap-center {
  scroll-snap-align: center;
}

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.list-none {
  list-style-type: none;
}

.appearance-none {
  appearance: none;
}

.auto-cols-auto {
  grid-auto-columns: auto;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-\[142px_1fr\] {
  grid-template-columns: 142px 1fr;
}

.grid-cols-\[1fr_190px_24px\] {
  grid-template-columns: 1fr 190px 24px;
}

.grid-cols-\[1fr_1px_1fr\] {
  grid-template-columns: 1fr 1px 1fr;
}

.grid-cols-\[24px_auto\] {
  grid-template-columns: 24px auto;
}

.grid-cols-\[2fr_1fr\] {
  grid-template-columns: 2fr 1fr;
}

.grid-cols-\[40\%\,60\%\] {
  grid-template-columns: 40% 60%;
}

.grid-cols-\[minmax\(90px\,260px\)_auto_minmax\(90px\,260px\)\] {
  grid-template-columns: minmax(90px, 260px) auto minmax(90px, 260px);
}

.grid-cols-none {
  grid-template-columns: none;
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.grid-rows-\[0fr\] {
  grid-template-rows: 0fr;
}

.grid-rows-\[16px_auto\] {
  grid-template-rows: 16px auto;
}

.grid-rows-\[1fr\] {
  grid-template-rows: 1fr;
}

.grid-rows-\[1fr_17px_1fr\] {
  grid-template-rows: 1fr 17px 1fr;
}

.grid-rows-\[80px_16px\] {
  grid-template-rows: 80px 16px;
}

.grid-rows-\[auto_1fr_1fr\] {
  grid-template-rows: auto 1fr 1fr;
}

.grid-rows-subgrid {
  grid-template-rows: subgrid;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-items-center {
  place-items: center;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-stretch {
  justify-content: stretch;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-3\.5 {
  gap: .875rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[1\.125rem\] {
  gap: 1.125rem;
}

.gap-\[1\.5rem\] {
  gap: 1.5rem;
}

.gap-\[10px\] {
  gap: 10px;
}

.gap-\[14px\] {
  gap: 14px;
}

.gap-\[1px\] {
  gap: 1px;
}

.gap-\[28px\] {
  gap: 28px;
}

.gap-\[2px\] {
  gap: 2px;
}

.gap-\[3px\] {
  gap: 3px;
}

.gap-\[5px\] {
  gap: 5px;
}

.gap-\[6px\] {
  gap: 6px;
}

.gap-\[7px\] {
  gap: 7px;
}

.gap-\[9\.5px\] {
  gap: 9.5px;
}

.gap-px {
  gap: 1px;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-x-\[1px\] {
  column-gap: 1px;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.gap-y-\[1px\] {
  row-gap: 1px;
}

.-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1px * var(--tw-space-y-reverse));
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.125rem * var(--tw-space-x-reverse));
  margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.375rem * var(--tw-space-x-reverse));
  margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.625rem * var(--tw-space-x-reverse));
  margin-left: calc(.625rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.875rem * var(--tw-space-x-reverse));
  margin-left: calc(.875rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-\[1px\] > :not([hidden]) ~ :not([hidden]), .space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1px * var(--tw-space-x-reverse));
  margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(16rem * var(--tw-space-y-reverse));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-black\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00000026;
}

.divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(195 221 253 / var(--tw-divide-opacity, 1));
}

.divide-cool-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-divide-opacity, 1));
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(244 245 247 / var(--tw-divide-opacity, 1));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity, 1));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(210 214 220 / var(--tw-divide-opacity, 1));
}

.divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 237 255 / var(--tw-divide-opacity, 1));
}

.divide-tremor-border > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-divide-opacity, 1));
}

.place-self-start {
  place-self: start;
}

.place-self-center {
  place-self: center;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.self-baseline {
  align-self: baseline;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-clip {
  overflow: clip;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-clip {
  overflow-x: clip;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.\!overflow-y-scroll {
  overflow-y: scroll !important;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overscroll-contain {
  overscroll-behavior: contain;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflow-ellipsis, .text-ellipsis {
  text-overflow: ellipsis;
}

.\!whitespace-normal {
  white-space: normal !important;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.text-wrap {
  text-wrap: wrap;
}

.text-nowrap {
  text-wrap: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.\!rounded {
  border-radius: .25rem !important;
}

.\!rounded-lg {
  border-radius: .5rem !important;
}

.\!rounded-none {
  border-radius: 0 !important;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[16px\] {
  border-radius: 16px;
}

.rounded-\[18px\] {
  border-radius: 18px;
}

.rounded-\[1px\] {
  border-radius: 1px;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded-\[4px\] {
  border-radius: 4px;
}

.rounded-\[5px\] {
  border-radius: 5px;
}

.rounded-\[6px\] {
  border-radius: 6px;
}

.rounded-\[7px\] {
  border-radius: 7px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-tremor-default {
  border-radius: .5rem;
}

.rounded-tremor-full {
  border-radius: 9999px;
}

.rounded-tremor-small {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-b-md {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-b-tremor-default {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-l-tremor-default {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-l-tremor-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-l-tremor-small {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-r-tremor-default {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-r-tremor-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-r-tremor-small {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-t-tremor-default {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.rounded-bl-md {
  border-bottom-left-radius: .375rem;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-br-md {
  border-bottom-right-radius: .375rem;
}

.rounded-tl {
  border-top-left-radius: .25rem;
}

.rounded-tl-lg {
  border-top-left-radius: .5rem;
}

.rounded-tl-md {
  border-top-left-radius: .375rem;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.rounded-tr-md {
  border-top-right-radius: .375rem;
}

.\!border {
  border-width: 1px !important;
}

.\!border-0 {
  border-width: 0 !important;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-\[0\.5px\] {
  border-width: .5px;
}

.border-\[1\.6px\] {
  border-width: 1.6px;
}

.border-\[2px\] {
  border-width: 2px;
}

.border-\[3px\] {
  border-width: 3px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-b-\[1px\] {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-0 {
  border-left-width: 0;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-l-\[1px\] {
  border-left-width: 1px;
}

.border-l-\[2px\] {
  border-left-width: 2px;
}

.border-r {
  border-right-width: 1px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-t-\[1px\] {
  border-top-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.\!border-none {
  border-style: none !important;
}

.border-none {
  border-style: none;
}

.\!border-blue-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(225 239 254 / var(--tw-border-opacity, 1)) !important;
}

.\!border-brandPrimary {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 207 49 / var(--tw-border-opacity, 1)) !important;
}

.\!border-cool-gray-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(203 213 225 / var(--tw-border-opacity, 1)) !important;
}

.\!border-gray-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(210 214 220 / var(--tw-border-opacity, 1)) !important;
}

.\!border-indigo-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(88 80 236 / var(--tw-border-opacity, 1)) !important;
}

.\!border-red-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(240 82 82 / var(--tw-border-opacity, 1)) !important;
}

.\!border-red-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(224 36 36 / var(--tw-border-opacity, 1)) !important;
}

.\!border-transparent {
  border-color: #0000 !important;
}

.border-\[\#059669\] {
  --tw-border-opacity: 1;
  border-color: rgb(5 150 105 / var(--tw-border-opacity, 1));
}

.border-\[\#2F3846\] {
  --tw-border-opacity: 1;
  border-color: rgb(47 56 70 / var(--tw-border-opacity, 1));
}

.border-\[\#4F46E5\], .border-\[\#4f46e5\] {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity, 1));
}

.border-\[\#818cf8\] {
  --tw-border-opacity: 1;
  border-color: rgb(129 140 248 / var(--tw-border-opacity, 1));
}

.border-\[\#BFDBFE\] {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity, 1));
}

.border-\[\#D2D6DB\] {
  --tw-border-opacity: 1;
  border-color: rgb(210 214 219 / var(--tw-border-opacity, 1));
}

.border-\[\#D2D6DC\] {
  --tw-border-opacity: 1;
  border-color: rgb(210 214 220 / var(--tw-border-opacity, 1));
}

.border-\[\#e02424\] {
  --tw-border-opacity: 1;
  border-color: rgb(224 36 36 / var(--tw-border-opacity, 1));
}

.border-\[var\(--indigo-600\,\#4F46E5\)\] {
  border-color: var(--indigo-600, #4f46e5);
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

.border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgb(225 239 254 / var(--tw-border-opacity, 1));
}

.border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgb(195 221 253 / var(--tw-border-opacity, 1));
}

.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgb(164 202 254 / var(--tw-border-opacity, 1));
}

.border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgb(118 169 250 / var(--tw-border-opacity, 1));
}

.border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgb(235 245 255 / var(--tw-border-opacity, 1));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(63 131 248 / var(--tw-border-opacity, 1));
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity, 1));
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(26 86 219 / var(--tw-border-opacity, 1));
}

.border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 66 159 / var(--tw-border-opacity, 1));
}

.border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgb(35 56 118 / var(--tw-border-opacity, 1));
}

.border-blue-bright-100 {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity, 1));
}

.border-blue-light-200 {
  --tw-border-opacity: 1;
  border-color: rgb(186 230 253 / var(--tw-border-opacity, 1));
}

.border-borderPrimary {
  --tw-border-opacity: 1;
  border-color: rgb(227 227 227 / var(--tw-border-opacity, 1));
}

.border-brandPrimary {
  --tw-border-opacity: 1;
  border-color: rgb(255 207 49 / var(--tw-border-opacity, 1));
}

.border-cool-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249 / var(--tw-border-opacity, 1));
}

.border-cool-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
}

.border-cool-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
}

.border-cool-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity, 1));
}

.border-cool-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity, 1));
}

.border-cool-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity, 1));
}

.border-cool-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity, 1));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(244 245 247 / var(--tw-border-opacity, 1));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(210 214 220 / var(--tw-border-opacity, 1));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(159 166 178 / var(--tw-border-opacity, 1));
}

.border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-border-opacity, 1));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(37 47 63 / var(--tw-border-opacity, 1));
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(22 30 46 / var(--tw-border-opacity, 1));
}

.border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgb(222 247 236 / var(--tw-border-opacity, 1));
}

.border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgb(188 240 218 / var(--tw-border-opacity, 1));
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgb(132 225 188 / var(--tw-border-opacity, 1));
}

.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgb(49 196 141 / var(--tw-border-opacity, 1));
}

.border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgb(243 250 247 / var(--tw-border-opacity, 1));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(14 159 110 / var(--tw-border-opacity, 1));
}

.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgb(5 122 85 / var(--tw-border-opacity, 1));
}

.border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgb(4 108 78 / var(--tw-border-opacity, 1));
}

.border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgb(3 84 63 / var(--tw-border-opacity, 1));
}

.border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgb(1 71 55 / var(--tw-border-opacity, 1));
}

.border-green-warm-100 {
  --tw-border-opacity: 1;
  border-color: rgb(220 252 231 / var(--tw-border-opacity, 1));
}

.border-green-warm-200 {
  --tw-border-opacity: 1;
  border-color: rgb(187 247 208 / var(--tw-border-opacity, 1));
}

.border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgb(229 237 255 / var(--tw-border-opacity, 1));
}

.border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgb(205 219 254 / var(--tw-border-opacity, 1));
}

.border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgb(180 198 252 / var(--tw-border-opacity, 1));
}

.border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgb(141 162 251 / var(--tw-border-opacity, 1));
}

.border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 245 255 / var(--tw-border-opacity, 1));
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(104 117 245 / var(--tw-border-opacity, 1));
}

.border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgb(88 80 236 / var(--tw-border-opacity, 1));
}

.border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgb(81 69 205 / var(--tw-border-opacity, 1));
}

.border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgb(66 56 157 / var(--tw-border-opacity, 1));
}

.border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgb(54 47 120 / var(--tw-border-opacity, 1));
}

.border-indigo-bright-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 231 255 / var(--tw-border-opacity, 1));
}

.border-indigo-bright-200 {
  --tw-border-opacity: 1;
  border-color: rgb(199 210 254 / var(--tw-border-opacity, 1));
}

.border-indigo-bright-200\/\[0\.3\] {
  border-color: #c7d2fe4d;
}

.border-indigo-bright-300 {
  --tw-border-opacity: 1;
  border-color: rgb(165 180 252 / var(--tw-border-opacity, 1));
}

.border-indigo-bright-50 {
  --tw-border-opacity: 1;
  border-color: rgb(238 242 255 / var(--tw-border-opacity, 1));
}

.border-indigo-bright-600 {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity, 1));
}

.border-indigo-bright-700 {
  --tw-border-opacity: 1;
  border-color: rgb(67 56 202 / var(--tw-border-opacity, 1));
}

.border-indigo-bright-800 {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity, 1));
}

.border-indigo-bright-900 {
  --tw-border-opacity: 1;
  border-color: rgb(54 62 123 / var(--tw-border-opacity, 1));
}

.border-orange-100 {
  --tw-border-opacity: 1;
  border-color: rgb(254 236 220 / var(--tw-border-opacity, 1));
}

.border-orange-200 {
  --tw-border-opacity: 1;
  border-color: rgb(252 217 189 / var(--tw-border-opacity, 1));
}

.border-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 186 140 / var(--tw-border-opacity, 1));
}

.border-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgb(255 138 76 / var(--tw-border-opacity, 1));
}

.border-orange-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 248 241 / var(--tw-border-opacity, 1));
}

.border-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgb(255 90 31 / var(--tw-border-opacity, 1));
}

.border-orange-600 {
  --tw-border-opacity: 1;
  border-color: rgb(208 56 1 / var(--tw-border-opacity, 1));
}

.border-orange-700 {
  --tw-border-opacity: 1;
  border-color: rgb(180 52 3 / var(--tw-border-opacity, 1));
}

.border-orange-800 {
  --tw-border-opacity: 1;
  border-color: rgb(138 44 13 / var(--tw-border-opacity, 1));
}

.border-orange-900 {
  --tw-border-opacity: 1;
  border-color: rgb(115 35 13 / var(--tw-border-opacity, 1));
}

.border-orange-warm-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 215 170 / var(--tw-border-opacity, 1));
}

.border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgb(252 232 243 / var(--tw-border-opacity, 1));
}

.border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgb(250 209 232 / var(--tw-border-opacity, 1));
}

.border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgb(248 180 217 / var(--tw-border-opacity, 1));
}

.border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgb(241 126 184 / var(--tw-border-opacity, 1));
}

.border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgb(253 242 248 / var(--tw-border-opacity, 1));
}

.border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgb(231 70 148 / var(--tw-border-opacity, 1));
}

.border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgb(214 31 105 / var(--tw-border-opacity, 1));
}

.border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgb(191 18 93 / var(--tw-border-opacity, 1));
}

.border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgb(153 21 75 / var(--tw-border-opacity, 1));
}

.border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgb(117 26 61 / var(--tw-border-opacity, 1));
}

.border-progressive-100 {
  --tw-border-opacity: 1;
  border-color: rgb(0 119 179 / var(--tw-border-opacity, 1));
}

.border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgb(237 235 254 / var(--tw-border-opacity, 1));
}

.border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(220 215 254 / var(--tw-border-opacity, 1));
}

.border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgb(202 191 253 / var(--tw-border-opacity, 1));
}

.border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgb(172 148 250 / var(--tw-border-opacity, 1));
}

.border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgb(246 245 255 / var(--tw-border-opacity, 1));
}

.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(144 97 249 / var(--tw-border-opacity, 1));
}

.border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgb(126 58 242 / var(--tw-border-opacity, 1));
}

.border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgb(108 43 217 / var(--tw-border-opacity, 1));
}

.border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgb(85 33 181 / var(--tw-border-opacity, 1));
}

.border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgb(74 29 150 / var(--tw-border-opacity, 1));
}

.border-purple-bright-500 {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
}

.border-red-100 {
  --tw-border-opacity: 1;
  border-color: rgb(253 232 232 / var(--tw-border-opacity, 1));
}

.border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgb(251 213 213 / var(--tw-border-opacity, 1));
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgb(248 180 180 / var(--tw-border-opacity, 1));
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(249 128 128 / var(--tw-border-opacity, 1));
}

.border-red-50 {
  --tw-border-opacity: 1;
  border-color: rgb(253 242 242 / var(--tw-border-opacity, 1));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(240 82 82 / var(--tw-border-opacity, 1));
}

.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgb(224 36 36 / var(--tw-border-opacity, 1));
}

.border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgb(200 30 30 / var(--tw-border-opacity, 1));
}

.border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgb(155 28 28 / var(--tw-border-opacity, 1));
}

.border-red-900 {
  --tw-border-opacity: 1;
  border-color: rgb(119 29 29 / var(--tw-border-opacity, 1));
}

.border-red-light-100 {
  --tw-border-opacity: 1;
  border-color: rgb(255 215 210 / var(--tw-border-opacity, 1));
}

.border-red-warm-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 202 202 / var(--tw-border-opacity, 1));
}

.border-red-warm-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity, 1));
}

.border-red-warm-700 {
  --tw-border-opacity: 1;
  border-color: rgb(181 30 30 / var(--tw-border-opacity, 1));
}

.border-sky-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 242 254 / var(--tw-border-opacity, 1));
}

.border-sky-200 {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity, 1));
}

.border-sky-300 {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity, 1));
}

.border-sky-400 {
  --tw-border-opacity: 1;
  border-color: rgb(56 189 248 / var(--tw-border-opacity, 1));
}

.border-sky-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 249 255 / var(--tw-border-opacity, 1));
}

.border-sky-500 {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity, 1));
}

.border-sky-600 {
  --tw-border-opacity: 1;
  border-color: rgb(2 132 199 / var(--tw-border-opacity, 1));
}

.border-sky-700 {
  --tw-border-opacity: 1;
  border-color: rgb(3 105 161 / var(--tw-border-opacity, 1));
}

.border-sky-800 {
  --tw-border-opacity: 1;
  border-color: rgb(7 89 133 / var(--tw-border-opacity, 1));
}

.border-sky-900 {
  --tw-border-opacity: 1;
  border-color: rgb(12 74 110 / var(--tw-border-opacity, 1));
}

.border-sky-950 {
  --tw-border-opacity: 1;
  border-color: rgb(8 47 73 / var(--tw-border-opacity, 1));
}

.border-teal-bright-200 {
  --tw-border-opacity: 1;
  border-color: rgb(153 246 228 / var(--tw-border-opacity, 1));
}

.border-teal-bright-600 {
  --tw-border-opacity: 1;
  border-color: rgb(13 148 136 / var(--tw-border-opacity, 1));
}

.border-transparent {
  border-color: #0000;
}

.border-tremor-background {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.border-tremor-border {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
}

.border-tremor-brand {
  --tw-border-opacity: 1;
  border-color: rgb(36 128 254 / var(--tw-border-opacity, 1));
}

.border-tremor-brand-emphasis {
  --tw-border-opacity: 1;
  border-color: rgb(30 64 175 / var(--tw-border-opacity, 1));
}

.border-tremor-brand-inverted {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.border-tremor-brand-subtle {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity, 1));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.border-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgb(253 246 178 / var(--tw-border-opacity, 1));
}

.border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgb(252 233 106 / var(--tw-border-opacity, 1));
}

.border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgb(250 202 21 / var(--tw-border-opacity, 1));
}

.border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgb(227 160 8 / var(--tw-border-opacity, 1));
}

.border-yellow-50 {
  --tw-border-opacity: 1;
  border-color: rgb(253 253 234 / var(--tw-border-opacity, 1));
}

.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(194 120 3 / var(--tw-border-opacity, 1));
}

.border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgb(159 88 10 / var(--tw-border-opacity, 1));
}

.border-yellow-700 {
  --tw-border-opacity: 1;
  border-color: rgb(142 75 16 / var(--tw-border-opacity, 1));
}

.border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgb(114 59 19 / var(--tw-border-opacity, 1));
}

.border-yellow-900 {
  --tw-border-opacity: 1;
  border-color: rgb(99 49 18 / var(--tw-border-opacity, 1));
}

.border-yellow-warm-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 191 36 / var(--tw-border-opacity, 1));
}

.border-b-cool-gray-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
}

.border-b-gray-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.border-b-indigo-400 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(141 162 251 / var(--tw-border-opacity, 1));
}

.border-b-indigo-600 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(88 80 236 / var(--tw-border-opacity, 1));
}

.border-b-transparent {
  border-bottom-color: #0000;
}

.border-l-cool-gray-200 {
  --tw-border-opacity: 1;
  border-left-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
}

.border-l-gray-400 {
  --tw-border-opacity: 1;
  border-left-color: rgb(159 166 178 / var(--tw-border-opacity, 1));
}

.border-l-indigo-600 {
  --tw-border-opacity: 1;
  border-left-color: rgb(88 80 236 / var(--tw-border-opacity, 1));
}

.border-l-transparent {
  border-left-color: #0000;
}

.border-r-gray-200 {
  --tw-border-opacity: 1;
  border-right-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.border-r-gray-300 {
  --tw-border-opacity: 1;
  border-right-color: rgb(210 214 220 / var(--tw-border-opacity, 1));
}

.border-r-red-400 {
  --tw-border-opacity: 1;
  border-right-color: rgb(249 128 128 / var(--tw-border-opacity, 1));
}

.border-r-white\/\[0\.1\] {
  border-right-color: #ffffff1a;
}

.border-t-cool-gray-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
}

.border-t-gray-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.border-t-gray-300 {
  --tw-border-opacity: 1;
  border-top-color: rgb(210 214 220 / var(--tw-border-opacity, 1));
}

.border-t-red-400 {
  --tw-border-opacity: 1;
  border-top-color: rgb(249 128 128 / var(--tw-border-opacity, 1));
}

.\!bg-\[\#eef2ff\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-blue-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(225 239 254 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-cool-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-cool-gray-100\/90 {
  background-color: #f1f5f9e6 !important;
}

.\!bg-cool-gray-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-green-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(132 225 188 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-green-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(14 159 110 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-indigo-100\/90 {
  background-color: #e5edffe6 !important;
}

.\!bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1)) !important;
}

.bg-\[\#003981\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 57 129 / var(--tw-bg-opacity, 1));
}

.bg-\[\#0659A0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(6 89 160 / var(--tw-bg-opacity, 1));
}

.bg-\[\#13803E\] {
  --tw-bg-opacity: 1;
  background-color: rgb(19 128 62 / var(--tw-bg-opacity, 1));
}

.bg-\[\#1A1A1A\] {
  --tw-bg-opacity: 1;
  background-color: rgb(26 26 26 / var(--tw-bg-opacity, 1));
}

.bg-\[\#1B5073\] {
  --tw-bg-opacity: 1;
  background-color: rgb(27 80 115 / var(--tw-bg-opacity, 1));
}

.bg-\[\#1E293B\] {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity, 1));
}

.bg-\[\#241F20\] {
  --tw-bg-opacity: 1;
  background-color: rgb(36 31 32 / var(--tw-bg-opacity, 1));
}

.bg-\[\#475183\] {
  --tw-bg-opacity: 1;
  background-color: rgb(71 81 131 / var(--tw-bg-opacity, 1));
}

.bg-\[\#4F46E5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity, 1));
}

.bg-\[\#94A2B8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(148 162 184 / var(--tw-bg-opacity, 1));
}

.bg-\[\#9D9D9D\] {
  --tw-bg-opacity: 1;
  background-color: rgb(157 157 157 / var(--tw-bg-opacity, 1));
}

.bg-\[\#EEF2FF4D\] {
  background-color: #eef2ff4d;
}

.bg-\[\#EF444482\]\/50 {
  background-color: #ef444480;
}

.bg-\[\#EFF6FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F4F4F4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 244 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F69785\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 151 133 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F7F7F7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F8F8F8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FFCF31\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 207 49 / var(--tw-bg-opacity, 1));
}

.bg-\[\#e0e7ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#e4ecff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(228 236 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#eef2ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#eff6ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f4fffc\] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 255 252 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f5f5f5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1));
}

.bg-\[\#fafbff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 251 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#fff9f9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 249 249 / var(--tw-bg-opacity, 1));
}

.bg-\[\#ffffff33\] {
  background-color: #fff3;
}

.bg-\[lightblue\] {
  --tw-bg-opacity: 1;
  background-color: rgb(173 216 230 / var(--tw-bg-opacity, 1));
}

.bg-\[rgba\(30\,41\,59\,0\.7\)\] {
  background-color: #1e293bb3;
}

.bg-\[rgba\(78\,70\,229\,0\.2\)\] {
  background-color: #4e46e533;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.bg-black\/50 {
  background-color: #00000080;
}

.bg-black\/\[0\.4\] {
  background-color: #0006;
}

.bg-black\/\[0\.9\] {
  background-color: #000000e6;
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(225 239 254 / var(--tw-bg-opacity, 1));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(195 221 253 / var(--tw-bg-opacity, 1));
}

.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(164 202 254 / var(--tw-bg-opacity, 1));
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(118 169 250 / var(--tw-bg-opacity, 1));
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(235 245 255 / var(--tw-bg-opacity, 1));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 131 248 / var(--tw-bg-opacity, 1));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 86 219 / var(--tw-bg-opacity, 1));
}

.bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 66 159 / var(--tw-bg-opacity, 1));
}

.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(35 56 118 / var(--tw-bg-opacity, 1));
}

.bg-blue-bright-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity, 1));
}

.bg-blue-bright-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(36 128 254 / var(--tw-bg-opacity, 1));
}

.bg-blue-light-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity, 1));
}

.bg-brandPrimary {
  --tw-bg-opacity: 1;
  background-color: rgb(255 207 49 / var(--tw-bg-opacity, 1));
}

.bg-brandPrimary_8 {
  background-color: #ffcf3114;
}

.bg-cool-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.bg-cool-gray-100\/90 {
  background-color: #f1f5f9e6;
}

.bg-cool-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity, 1));
}

.bg-cool-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity, 1));
}

.bg-cool-gray-300\/10 {
  background-color: #cbd5e11a;
}

.bg-cool-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity, 1));
}

.bg-cool-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}

.bg-cool-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity, 1));
}

.bg-cool-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
}

.bg-cool-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity, 1));
}

.bg-cool-gray-700\/75 {
  background-color: #334155bf;
}

.bg-cool-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity, 1));
}

.bg-cool-gray-800\/75 {
  background-color: #1e293bbf;
}

.bg-cool-gray-900\/50 {
  background-color: #0f172a80;
}

.bg-cool-gray-900\/75 {
  background-color: #0f172abf;
}

.bg-error {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 245 247 / var(--tw-bg-opacity, 1));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(210 214 220 / var(--tw-bg-opacity, 1));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(159 166 178 / var(--tw-bg-opacity, 1));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 47 63 / var(--tw-bg-opacity, 1));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 30 46 / var(--tw-bg-opacity, 1));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(222 247 236 / var(--tw-bg-opacity, 1));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(188 240 218 / var(--tw-bg-opacity, 1));
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(132 225 188 / var(--tw-bg-opacity, 1));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(49 196 141 / var(--tw-bg-opacity, 1));
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 250 247 / var(--tw-bg-opacity, 1));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 159 110 / var(--tw-bg-opacity, 1));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 122 85 / var(--tw-bg-opacity, 1));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 108 78 / var(--tw-bg-opacity, 1));
}

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 84 63 / var(--tw-bg-opacity, 1));
}

.bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(1 71 55 / var(--tw-bg-opacity, 1));
}

.bg-green-warm-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
}

.bg-green-warm-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity, 1));
}

.bg-green-warm-600\/90 {
  background-color: #16a34ae6;
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 237 255 / var(--tw-bg-opacity, 1));
}

.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(205 219 254 / var(--tw-bg-opacity, 1));
}

.bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(180 198 252 / var(--tw-bg-opacity, 1));
}

.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(141 162 251 / var(--tw-bg-opacity, 1));
}

.bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 245 255 / var(--tw-bg-opacity, 1));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(104 117 245 / var(--tw-bg-opacity, 1));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(88 80 236 / var(--tw-bg-opacity, 1));
}

.bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(81 69 205 / var(--tw-bg-opacity, 1));
}

.bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(66 56 157 / var(--tw-bg-opacity, 1));
}

.bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(54 47 120 / var(--tw-bg-opacity, 1));
}

.bg-indigo-bright-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity, 1));
}

.bg-indigo-bright-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity, 1));
}

.bg-indigo-bright-200\/\[0\.3\] {
  background-color: #c7d2fe4d;
}

.bg-indigo-bright-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity, 1));
}

.bg-indigo-bright-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity, 1));
}

.bg-indigo-bright-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity, 1));
}

.bg-indigo-bright-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity, 1));
}

.bg-indigo-bright-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity, 1));
}

.bg-indigo-bright-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(54 62 123 / var(--tw-bg-opacity, 1));
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 236 220 / var(--tw-bg-opacity, 1));
}

.bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 217 189 / var(--tw-bg-opacity, 1));
}

.bg-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 186 140 / var(--tw-bg-opacity, 1));
}

.bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 138 76 / var(--tw-bg-opacity, 1));
}

.bg-orange-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 248 241 / var(--tw-bg-opacity, 1));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 90 31 / var(--tw-bg-opacity, 1));
}

.bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(208 56 1 / var(--tw-bg-opacity, 1));
}

.bg-orange-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(180 52 3 / var(--tw-bg-opacity, 1));
}

.bg-orange-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(138 44 13 / var(--tw-bg-opacity, 1));
}

.bg-orange-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(115 35 13 / var(--tw-bg-opacity, 1));
}

.bg-orange-warm-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 237 / var(--tw-bg-opacity, 1));
}

.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 232 243 / var(--tw-bg-opacity, 1));
}

.bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 209 232 / var(--tw-bg-opacity, 1));
}

.bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 180 217 / var(--tw-bg-opacity, 1));
}

.bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 126 184 / var(--tw-bg-opacity, 1));
}

.bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 248 / var(--tw-bg-opacity, 1));
}

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(231 70 148 / var(--tw-bg-opacity, 1));
}

.bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(214 31 105 / var(--tw-bg-opacity, 1));
}

.bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 18 93 / var(--tw-bg-opacity, 1));
}

.bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 21 75 / var(--tw-bg-opacity, 1));
}

.bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(117 26 61 / var(--tw-bg-opacity, 1));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-progressive-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 119 179 / var(--tw-bg-opacity, 1));
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 235 254 / var(--tw-bg-opacity, 1));
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 215 254 / var(--tw-bg-opacity, 1));
}

.bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(202 191 253 / var(--tw-bg-opacity, 1));
}

.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(172 148 250 / var(--tw-bg-opacity, 1));
}

.bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(246 245 255 / var(--tw-bg-opacity, 1));
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(144 97 249 / var(--tw-bg-opacity, 1));
}

.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 58 242 / var(--tw-bg-opacity, 1));
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(108 43 217 / var(--tw-bg-opacity, 1));
}

.bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(85 33 181 / var(--tw-bg-opacity, 1));
}

.bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 29 150 / var(--tw-bg-opacity, 1));
}

.bg-purple-bright-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity, 1));
}

.bg-purple-bright-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 232 232 / var(--tw-bg-opacity, 1));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 213 213 / var(--tw-bg-opacity, 1));
}

.bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 180 180 / var(--tw-bg-opacity, 1));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 128 128 / var(--tw-bg-opacity, 1));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 242 / var(--tw-bg-opacity, 1));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 82 82 / var(--tw-bg-opacity, 1));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 36 36 / var(--tw-bg-opacity, 1));
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(200 30 30 / var(--tw-bg-opacity, 1));
}

.bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(155 28 28 / var(--tw-bg-opacity, 1));
}

.bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(119 29 29 / var(--tw-bg-opacity, 1));
}

.bg-red-light-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 246 245 / var(--tw-bg-opacity, 1));
}

.bg-red-warm-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity, 1));
}

.bg-red-warm-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity, 1));
}

.bg-red-warm-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity, 1));
}

.bg-sky-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity, 1));
}

.bg-sky-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity, 1));
}

.bg-sky-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity, 1));
}

.bg-sky-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity, 1));
}

.bg-sky-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity, 1));
}

.bg-sky-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity, 1));
}

.bg-sky-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity, 1));
}

.bg-sky-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity, 1));
}

.bg-sky-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity, 1));
}

.bg-sky-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity, 1));
}

.bg-sky-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 47 73 / var(--tw-bg-opacity, 1));
}

.bg-success {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity, 1));
}

.bg-teal-bright-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity, 1));
}

.bg-teal-bright-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity, 1));
}

.bg-transparent {
  background-color: #0000;
}

.bg-tremor-background {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-tremor-background-emphasis {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity, 1));
}

.bg-tremor-background-muted {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}

.bg-tremor-background-subtle {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.bg-tremor-border {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity, 1));
}

.bg-tremor-brand {
  --tw-bg-opacity: 1;
  background-color: rgb(36 128 254 / var(--tw-bg-opacity, 1));
}

.bg-tremor-brand-faint {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity, 1));
}

.bg-tremor-brand-muted {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity, 1));
}

.bg-tremor-brand-muted\/50 {
  background-color: #bfdbfe80;
}

.bg-tremor-brand-subtle {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity, 1));
}

.bg-tremor-content-subtle {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity, 1));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-white\/25 {
  background-color: #ffffff40;
}

.bg-white\/\[\.75\] {
  background-color: #ffffffbf;
}

.bg-white\/\[\.85\] {
  background-color: #ffffffd9;
}

.bg-white\/\[0\.1\] {
  background-color: #ffffff1a;
}

.bg-white\/\[0\.4\] {
  background-color: #fff6;
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 178 / var(--tw-bg-opacity, 1));
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 233 106 / var(--tw-bg-opacity, 1));
}

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 202 21 / var(--tw-bg-opacity, 1));
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(227 160 8 / var(--tw-bg-opacity, 1));
}

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 253 234 / var(--tw-bg-opacity, 1));
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(194 120 3 / var(--tw-bg-opacity, 1));
}

.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(159 88 10 / var(--tw-bg-opacity, 1));
}

.bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(142 75 16 / var(--tw-bg-opacity, 1));
}

.bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(114 59 19 / var(--tw-bg-opacity, 1));
}

.bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 49 18 / var(--tw-bg-opacity, 1));
}

.bg-yellow-warm-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity, 1));
}

.bg-yellow-warm-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 235 / var(--tw-bg-opacity, 1));
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.bg-opacity-30 {
  --tw-bg-opacity: .3;
}

.bg-opacity-40 {
  --tw-bg-opacity: .4;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-\[linear-gradient\(180deg\,rgba\(255\,255\,255\,0\.16\)_0\%\,rgba\(217\,217\,217\,0\)_100\%\)\] {
  background-image: linear-gradient(#ffffff29 0%, #d9d9d900 100%);
}

.bg-\[linear-gradient\(90deg\,rgb\(27\,163\,239\)2\.78\%\,rgb\(122\,68\,255\)47\.94\%\,rgb\(228\,56\,170\)98\.07\%\)\] {
  background-image: linear-gradient(90deg, #1ba3ef 2.78%, #7a44ff 47.94%, #e438aa 98.07%);
}

.bg-\[linear-gradient\(90deg\,rgba\(27\,163\,239\,0\.85\)2\.78\%\,rgba\(122\,68\,255\,0\.85\)47\.94\%\,rgba\(228\,56\,170\,0\.85\)98\.07\%\)\] {
  background-image: linear-gradient(90deg, #1ba3efd9 2.78%, #7a44ffd9 47.94%, #e438aad9 98.07%);
}

.bg-gradient-180-degrees-indigo {
  background-image: linear-gradient(#4f46e5 -41.67%, #4f46e500 100%);
}

.bg-gradient-98-degrees {
  background-image: linear-gradient(98deg, var(--tw-gradient-stops));
}

.bg-gradient-emma {
  background-image: linear-gradient(to right, #cffafe, #e0e7ff);
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.bg-none {
  background-image: none;
}

.from-\[\#2480fe_5\.51\%\] {
  --tw-gradient-from: #2480fe 5.51% var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#ECFEFF\] {
  --tw-gradient-from: #ecfeff var(--tw-gradient-from-position);
  --tw-gradient-to: #ecfeff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#EEF2FF\] {
  --tw-gradient-from: #eef2ff var(--tw-gradient-from-position);
  --tw-gradient-to: #eef2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black {
  --tw-gradient-from: #000 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black\/\[0\.25\] {
  --tw-gradient-from: #00000040 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black\/\[0\.50\], .from-black\/\[0\.5\] {
  --tw-gradient-from: #00000080 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cool-gray-50 {
  --tw-gradient-from: #f8fafc var(--tw-gradient-from-position);
  --tw-gradient-to: #f8fafc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-50 {
  --tw-gradient-from: #f9fafb var(--tw-gradient-from-position);
  --tw-gradient-to: #f9fafb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent {
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white {
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-20\% {
  --tw-gradient-from-position: 20%;
}

.from-50\% {
  --tw-gradient-from-position: 50%;
}

.via-black\/\[0\.5\] {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #00000080 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-white {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fff var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-30\% {
  --tw-gradient-via-position: 30%;
}

.to-\[\#1E40AF_121\.39\%\] {
  --tw-gradient-to: #1e40af 121.39% var(--tw-gradient-to-position);
}

.to-\[\#DDF9FE\] {
  --tw-gradient-to: #ddf9fe var(--tw-gradient-to-position);
}

.to-\[\#DDFAFE\] {
  --tw-gradient-to: #ddfafe var(--tw-gradient-to-position);
}

.to-\[\#EEF2FF\] {
  --tw-gradient-to: #eef2ff var(--tw-gradient-to-position);
}

.to-black {
  --tw-gradient-to: #000 var(--tw-gradient-to-position);
}

.to-gray-300 {
  --tw-gradient-to: #d2d6dc var(--tw-gradient-to-position);
}

.to-transparent {
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.to-white {
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
}

.to-50\% {
  --tw-gradient-to-position: 50%;
}

.to-55\% {
  --tw-gradient-to-position: 55%;
}

.bg-\[length\:120\%\] {
  background-size: 120%;
}

.bg-\[length\:170\%\] {
  background-size: 170%;
}

.bg-contain {
  background-size: contain;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-\[\#0077B3\] {
  fill: #0077b3;
}

.fill-black {
  fill: #000;
}

.fill-blue-100 {
  fill: #e1effe;
}

.fill-blue-200 {
  fill: #c3ddfd;
}

.fill-blue-300 {
  fill: #a4cafe;
}

.fill-blue-400 {
  fill: #76a9fa;
}

.fill-blue-50 {
  fill: #ebf5ff;
}

.fill-blue-500 {
  fill: #3f83f8;
}

.fill-blue-600 {
  fill: #2563eb;
}

.fill-blue-700 {
  fill: #1a56db;
}

.fill-blue-800 {
  fill: #1e429f;
}

.fill-blue-900 {
  fill: #233876;
}

.fill-brandPrimary {
  fill: #ffcf31;
}

.fill-cool-gray-400\/60 {
  fill: #94a3b899;
}

.fill-cool-gray-500 {
  fill: #64748b;
}

.fill-cool-gray-700 {
  fill: #334155;
}

.fill-cool-gray-800 {
  fill: #1e293b;
}

.fill-current {
  fill: currentColor;
}

.fill-gray-100 {
  fill: #f4f5f7;
}

.fill-gray-200 {
  fill: #e5e7eb;
}

.fill-gray-300 {
  fill: #d2d6dc;
}

.fill-gray-400 {
  fill: #9fa6b2;
}

.fill-gray-50 {
  fill: #f9fafb;
}

.fill-gray-500 {
  fill: #6b7280;
}

.fill-gray-600 {
  fill: #4b5563;
}

.fill-gray-700 {
  fill: #374151;
}

.fill-gray-800 {
  fill: #252f3f;
}

.fill-gray-900 {
  fill: #161e2e;
}

.fill-green-100 {
  fill: #def7ec;
}

.fill-green-200 {
  fill: #bcf0da;
}

.fill-green-300 {
  fill: #84e1bc;
}

.fill-green-400 {
  fill: #31c48d;
}

.fill-green-50 {
  fill: #f3faf7;
}

.fill-green-500 {
  fill: #0e9f6e;
}

.fill-green-600 {
  fill: #057a55;
}

.fill-green-700 {
  fill: #046c4e;
}

.fill-green-800 {
  fill: #03543f;
}

.fill-green-900 {
  fill: #014737;
}

.fill-green-warm-600 {
  fill: #16a34a;
}

.fill-icOnBrandPrimary {
  fill: #2f2c2c;
}

.fill-icPrimary {
  fill: #4a4645;
}

.fill-indigo-100 {
  fill: #e5edff;
}

.fill-indigo-200 {
  fill: #cddbfe;
}

.fill-indigo-300 {
  fill: #b4c6fc;
}

.fill-indigo-400 {
  fill: #8da2fb;
}

.fill-indigo-50 {
  fill: #f0f5ff;
}

.fill-indigo-500 {
  fill: #6875f5;
}

.fill-indigo-600 {
  fill: #5850ec;
}

.fill-indigo-700 {
  fill: #5145cd;
}

.fill-indigo-800 {
  fill: #42389d;
}

.fill-indigo-900 {
  fill: #362f78;
}

.fill-indigo-bright-100 {
  fill: #e0e7ff;
}

.fill-indigo-bright-200 {
  fill: #c7d2fe;
}

.fill-indigo-bright-200\/\[0\.3\] {
  fill: #c7d2fe4d;
}

.fill-indigo-bright-300 {
  fill: #a5b4fc;
}

.fill-indigo-bright-50 {
  fill: #eef2ff;
}

.fill-indigo-bright-600 {
  fill: #4f46e5;
}

.fill-indigo-bright-700 {
  fill: #4338ca;
}

.fill-indigo-bright-800 {
  fill: #3730a3;
}

.fill-indigo-bright-900 {
  fill: #363e7b;
}

.fill-orange-100 {
  fill: #feecdc;
}

.fill-orange-200 {
  fill: #fcd9bd;
}

.fill-orange-300 {
  fill: #fdba8c;
}

.fill-orange-400 {
  fill: #ff8a4c;
}

.fill-orange-50 {
  fill: #fff8f1;
}

.fill-orange-500 {
  fill: #ff5a1f;
}

.fill-orange-600 {
  fill: #d03801;
}

.fill-orange-700 {
  fill: #b43403;
}

.fill-orange-800 {
  fill: #8a2c0d;
}

.fill-orange-900 {
  fill: #73230d;
}

.fill-pink-100 {
  fill: #fce8f3;
}

.fill-pink-200 {
  fill: #fad1e8;
}

.fill-pink-300 {
  fill: #f8b4d9;
}

.fill-pink-400 {
  fill: #f17eb8;
}

.fill-pink-50 {
  fill: #fdf2f8;
}

.fill-pink-500 {
  fill: #e74694;
}

.fill-pink-600 {
  fill: #d61f69;
}

.fill-pink-700 {
  fill: #bf125d;
}

.fill-pink-800 {
  fill: #99154b;
}

.fill-pink-900 {
  fill: #751a3d;
}

.fill-purple-100 {
  fill: #edebfe;
}

.fill-purple-200 {
  fill: #dcd7fe;
}

.fill-purple-300 {
  fill: #cabffd;
}

.fill-purple-400 {
  fill: #ac94fa;
}

.fill-purple-50 {
  fill: #f6f5ff;
}

.fill-purple-500 {
  fill: #9061f9;
}

.fill-purple-600 {
  fill: #7e3af2;
}

.fill-purple-700 {
  fill: #6c2bd9;
}

.fill-purple-800 {
  fill: #5521b5;
}

.fill-purple-900 {
  fill: #4a1d96;
}

.fill-purple-bright-500 {
  fill: #a855f7;
}

.fill-red-100 {
  fill: #fde8e8;
}

.fill-red-200 {
  fill: #fbd5d5;
}

.fill-red-300 {
  fill: #f8b4b4;
}

.fill-red-400 {
  fill: #f98080;
}

.fill-red-50 {
  fill: #fdf2f2;
}

.fill-red-500 {
  fill: #f05252;
}

.fill-red-600 {
  fill: #e02424;
}

.fill-red-700 {
  fill: #c81e1e;
}

.fill-red-800 {
  fill: #9b1c1c;
}

.fill-red-900 {
  fill: #771d1d;
}

.fill-red-warm-300 {
  fill: #fca5a5;
}

.fill-red-warm-400 {
  fill: #f87171;
}

.fill-sky-100 {
  fill: #e0f2fe;
}

.fill-sky-200 {
  fill: #bfdbfe;
}

.fill-sky-300 {
  fill: #93c5fd;
}

.fill-sky-400 {
  fill: #38bdf8;
}

.fill-sky-50 {
  fill: #f0f9ff;
}

.fill-sky-500 {
  fill: #0ea5e9;
}

.fill-sky-600 {
  fill: #0284c7;
}

.fill-sky-700 {
  fill: #0369a1;
}

.fill-sky-800 {
  fill: #075985;
}

.fill-sky-900 {
  fill: #0c4a6e;
}

.fill-sky-950 {
  fill: #082f49;
}

.fill-teal-bright-200 {
  fill: #99f6e4;
}

.fill-teal-bright-600 {
  fill: #0d9488;
}

.fill-tremor-content {
  fill: #64748b;
}

.fill-tremor-content-emphasis {
  fill: #334155;
}

.fill-white {
  fill: #fff;
}

.fill-yellow-100 {
  fill: #fdf6b2;
}

.fill-yellow-200 {
  fill: #fce96a;
}

.fill-yellow-300 {
  fill: #faca15;
}

.fill-yellow-400 {
  fill: #e3a008;
}

.fill-yellow-50 {
  fill: #fdfdea;
}

.fill-yellow-500 {
  fill: #c27803;
}

.fill-yellow-600 {
  fill: #9f580a;
}

.fill-yellow-700 {
  fill: #8e4b10;
}

.fill-yellow-800 {
  fill: #723b13;
}

.fill-yellow-900 {
  fill: #633112;
}

.fill-yellow-warm-400 {
  fill: #fbbf24;
}

.fill-yellow-warm-600 {
  fill: #f59e0b;
}

.stroke-\[\#F9BDC1\] {
  stroke: #f9bdc1;
}

.stroke-blue-100 {
  stroke: #e1effe;
}

.stroke-blue-200 {
  stroke: #c3ddfd;
}

.stroke-blue-300 {
  stroke: #a4cafe;
}

.stroke-blue-400 {
  stroke: #76a9fa;
}

.stroke-blue-50 {
  stroke: #ebf5ff;
}

.stroke-blue-500 {
  stroke: #3f83f8;
}

.stroke-blue-600 {
  stroke: #2563eb;
}

.stroke-blue-700 {
  stroke: #1a56db;
}

.stroke-blue-800 {
  stroke: #1e429f;
}

.stroke-blue-900 {
  stroke: #233876;
}

.stroke-cool-gray-300 {
  stroke: #cbd5e1;
}

.stroke-cool-gray-400 {
  stroke: #94a3b8;
}

.stroke-cool-gray-700 {
  stroke: #334155;
}

.stroke-gray-100 {
  stroke: #f4f5f7;
}

.stroke-gray-200 {
  stroke: #e5e7eb;
}

.stroke-gray-300 {
  stroke: #d2d6dc;
}

.stroke-gray-400 {
  stroke: #9fa6b2;
}

.stroke-gray-50 {
  stroke: #f9fafb;
}

.stroke-gray-500 {
  stroke: #6b7280;
}

.stroke-gray-600 {
  stroke: #4b5563;
}

.stroke-gray-700 {
  stroke: #374151;
}

.stroke-gray-800 {
  stroke: #252f3f;
}

.stroke-gray-900 {
  stroke: #161e2e;
}

.stroke-green-100 {
  stroke: #def7ec;
}

.stroke-green-200 {
  stroke: #bcf0da;
}

.stroke-green-300 {
  stroke: #84e1bc;
}

.stroke-green-400 {
  stroke: #31c48d;
}

.stroke-green-50 {
  stroke: #f3faf7;
}

.stroke-green-500 {
  stroke: #0e9f6e;
}

.stroke-green-600 {
  stroke: #057a55;
}

.stroke-green-700 {
  stroke: #046c4e;
}

.stroke-green-800 {
  stroke: #03543f;
}

.stroke-green-900 {
  stroke: #014737;
}

.stroke-indigo-100 {
  stroke: #e5edff;
}

.stroke-indigo-200 {
  stroke: #cddbfe;
}

.stroke-indigo-300 {
  stroke: #b4c6fc;
}

.stroke-indigo-400 {
  stroke: #8da2fb;
}

.stroke-indigo-50 {
  stroke: #f0f5ff;
}

.stroke-indigo-500 {
  stroke: #6875f5;
}

.stroke-indigo-600 {
  stroke: #5850ec;
}

.stroke-indigo-700 {
  stroke: #5145cd;
}

.stroke-indigo-800 {
  stroke: #42389d;
}

.stroke-indigo-900 {
  stroke: #362f78;
}

.stroke-indigo-bright-100 {
  stroke: #e0e7ff;
}

.stroke-indigo-bright-200 {
  stroke: #c7d2fe;
}

.stroke-indigo-bright-200\/\[0\.3\] {
  stroke: #c7d2fe4d;
}

.stroke-indigo-bright-300 {
  stroke: #a5b4fc;
}

.stroke-indigo-bright-50 {
  stroke: #eef2ff;
}

.stroke-indigo-bright-600 {
  stroke: #4f46e5;
}

.stroke-indigo-bright-700 {
  stroke: #4338ca;
}

.stroke-indigo-bright-800 {
  stroke: #3730a3;
}

.stroke-indigo-bright-900 {
  stroke: #363e7b;
}

.stroke-orange-100 {
  stroke: #feecdc;
}

.stroke-orange-200 {
  stroke: #fcd9bd;
}

.stroke-orange-300 {
  stroke: #fdba8c;
}

.stroke-orange-400 {
  stroke: #ff8a4c;
}

.stroke-orange-50 {
  stroke: #fff8f1;
}

.stroke-orange-500 {
  stroke: #ff5a1f;
}

.stroke-orange-600 {
  stroke: #d03801;
}

.stroke-orange-700 {
  stroke: #b43403;
}

.stroke-orange-800 {
  stroke: #8a2c0d;
}

.stroke-orange-900 {
  stroke: #73230d;
}

.stroke-pink-100 {
  stroke: #fce8f3;
}

.stroke-pink-200 {
  stroke: #fad1e8;
}

.stroke-pink-300 {
  stroke: #f8b4d9;
}

.stroke-pink-400 {
  stroke: #f17eb8;
}

.stroke-pink-50 {
  stroke: #fdf2f8;
}

.stroke-pink-500 {
  stroke: #e74694;
}

.stroke-pink-600 {
  stroke: #d61f69;
}

.stroke-pink-700 {
  stroke: #bf125d;
}

.stroke-pink-800 {
  stroke: #99154b;
}

.stroke-pink-900 {
  stroke: #751a3d;
}

.stroke-purple-100 {
  stroke: #edebfe;
}

.stroke-purple-200 {
  stroke: #dcd7fe;
}

.stroke-purple-300 {
  stroke: #cabffd;
}

.stroke-purple-400 {
  stroke: #ac94fa;
}

.stroke-purple-50 {
  stroke: #f6f5ff;
}

.stroke-purple-500 {
  stroke: #9061f9;
}

.stroke-purple-600 {
  stroke: #7e3af2;
}

.stroke-purple-700 {
  stroke: #6c2bd9;
}

.stroke-purple-800 {
  stroke: #5521b5;
}

.stroke-purple-900 {
  stroke: #4a1d96;
}

.stroke-purple-bright-500 {
  stroke: #a855f7;
}

.stroke-red-100 {
  stroke: #fde8e8;
}

.stroke-red-200 {
  stroke: #fbd5d5;
}

.stroke-red-300 {
  stroke: #f8b4b4;
}

.stroke-red-400 {
  stroke: #f98080;
}

.stroke-red-50 {
  stroke: #fdf2f2;
}

.stroke-red-500 {
  stroke: #f05252;
}

.stroke-red-600 {
  stroke: #e02424;
}

.stroke-red-700 {
  stroke: #c81e1e;
}

.stroke-red-800 {
  stroke: #9b1c1c;
}

.stroke-red-900 {
  stroke: #771d1d;
}

.stroke-red-warm-400 {
  stroke: #f87171;
}

.stroke-sky-100 {
  stroke: #e0f2fe;
}

.stroke-sky-200 {
  stroke: #bfdbfe;
}

.stroke-sky-300 {
  stroke: #93c5fd;
}

.stroke-sky-400 {
  stroke: #38bdf8;
}

.stroke-sky-50 {
  stroke: #f0f9ff;
}

.stroke-sky-500 {
  stroke: #0ea5e9;
}

.stroke-sky-600 {
  stroke: #0284c7;
}

.stroke-sky-700 {
  stroke: #0369a1;
}

.stroke-sky-800 {
  stroke: #075985;
}

.stroke-sky-900 {
  stroke: #0c4a6e;
}

.stroke-sky-950 {
  stroke: #082f49;
}

.stroke-teal-bright-200 {
  stroke: #99f6e4;
}

.stroke-teal-bright-600 {
  stroke: #0d9488;
}

.stroke-tremor-background {
  stroke: #fff;
}

.stroke-tremor-border {
  stroke: #e2e8f0;
}

.stroke-tremor-brand {
  stroke: #2480fe;
}

.stroke-tremor-brand-muted\/50 {
  stroke: #bfdbfe80;
}

.stroke-white {
  stroke: #fff;
}

.stroke-yellow-100 {
  stroke: #fdf6b2;
}

.stroke-yellow-200 {
  stroke: #fce96a;
}

.stroke-yellow-300 {
  stroke: #faca15;
}

.stroke-yellow-400 {
  stroke: #e3a008;
}

.stroke-yellow-50 {
  stroke: #fdfdea;
}

.stroke-yellow-500 {
  stroke: #c27803;
}

.stroke-yellow-600 {
  stroke: #9f580a;
}

.stroke-yellow-700 {
  stroke: #8e4b10;
}

.stroke-yellow-800 {
  stroke: #723b13;
}

.stroke-yellow-900 {
  stroke: #633112;
}

.stroke-yellow-warm-400 {
  stroke: #fbbf24;
}

.stroke-1 {
  stroke-width: 1px;
}

.stroke-\[3\] {
  stroke-width: 3px;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.\!p-0 {
  padding: 0 !important;
}

.\!p-2 {
  padding: .5rem !important;
}

.\!p-3 {
  padding: .75rem !important;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-12 {
  padding: 3rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-3\.5 {
  padding: .875rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-60 {
  padding: 15rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[10px\] {
  padding: 10px;
}

.p-\[14px\] {
  padding: 14px;
}

.p-\[16px\] {
  padding: 16px;
}

.p-\[18px\] {
  padding: 18px;
}

.p-\[1px\] {
  padding: 1px;
}

.p-\[3px\] {
  padding: 3px;
}

.p-px {
  padding: 1px;
}

.\!px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.\!py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.\!py-2\.5 {
  padding-top: .625rem !important;
  padding-bottom: .625rem !important;
}

.\!py-\[1px\] {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.px-\[13px\] {
  padding-left: 13px;
  padding-right: 13px;
}

.px-\[16\.5px\] {
  padding-left: 16.5px;
  padding-right: 16.5px;
}

.px-\[18px\] {
  padding-left: 18px;
  padding-right: 18px;
}

.px-\[2px\] {
  padding-left: 2px;
  padding-right: 2px;
}

.px-\[3px\] {
  padding-left: 3px;
  padding-right: 3px;
}

.px-\[5px\] {
  padding-left: 5px;
  padding-right: 5px;
}

.px-\[6px\] {
  padding-left: 6px;
  padding-right: 6px;
}

.px-\[7px\] {
  padding-left: 7px;
  padding-right: 7px;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-\[11px\] {
  padding-top: 11px;
  padding-bottom: 11px;
}

.py-\[13\.5px\] {
  padding-top: 13.5px;
  padding-bottom: 13.5px;
}

.py-\[18px\] {
  padding-top: 18px;
  padding-bottom: 18px;
}

.py-\[1px\] {
  padding-top: 1px;
  padding-bottom: 1px;
}

.py-\[22px\] {
  padding-top: 22px;
  padding-bottom: 22px;
}

.py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px;
}

.py-\[3px\] {
  padding-top: 3px;
  padding-bottom: 3px;
}

.py-\[4px\] {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-\[5px\] {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.py-\[8px\] {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-\[9\.5\], .py-\[9\.5px\] {
  padding-top: 9.5px;
  padding-bottom: 9.5px;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.\!pb-0 {
  padding-bottom: 0 !important;
}

.\!pr-0 {
  padding-right: 0 !important;
}

.\!pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-0\.5 {
  padding-bottom: .125rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-2\.5 {
  padding-bottom: .625rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-3\.5 {
  padding-bottom: .875rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-\[10px\] {
  padding-bottom: 10px;
}

.pb-\[144px\] {
  padding-bottom: 144px;
}

.pb-\[14px\] {
  padding-bottom: 14px;
}

.pb-\[15\.5\%\] {
  padding-bottom: 15.5%;
}

.pb-\[16px\] {
  padding-bottom: 16px;
}

.pb-\[18px\] {
  padding-bottom: 18px;
}

.pb-\[2px\] {
  padding-bottom: 2px;
}

.pb-\[36px\] {
  padding-bottom: 36px;
}

.pb-\[5px\] {
  padding-bottom: 5px;
}

.pb-\[6px\] {
  padding-bottom: 6px;
}

.pb-\[72px\] {
  padding-bottom: 72px;
}

.pb-\[80px\] {
  padding-bottom: 80px;
}

.pb-px {
  padding-bottom: 1px;
}

.pl-0 {
  padding-left: 0;
}

.pl-0\.5 {
  padding-left: .125rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-1\.5 {
  padding-left: .375rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-2\.5 {
  padding-left: .625rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-3\.5 {
  padding-left: .875rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-\[10px\] {
  padding-left: 10px;
}

.pl-\[18px\] {
  padding-left: 18px;
}

.pl-\[32px\] {
  padding-left: 32px;
}

.pl-\[4\.5rem\] {
  padding-left: 4.5rem;
}

.pl-\[5px\] {
  padding-left: 5px;
}

.pl-\[60px\] {
  padding-left: 60px;
}

.pl-\[8px\] {
  padding-left: 8px;
}

.pl-\[9px\] {
  padding-left: 9px;
}

.pl-px {
  padding-left: 1px;
}

.pr-0 {
  padding-right: 0;
}

.pr-0\.5 {
  padding-right: .125rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-1\.5 {
  padding-right: .375rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-2\.5 {
  padding-right: .625rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-3\.5 {
  padding-right: .875rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pr-\[12px\] {
  padding-right: 12px;
}

.pr-\[18px\] {
  padding-right: 18px;
}

.pr-\[32px\] {
  padding-right: 32px;
}

.pr-\[350px\] {
  padding-right: 350px;
}

.pr-\[40px\] {
  padding-right: 40px;
}

.pr-\[48px\] {
  padding-right: 48px;
}

.pr-\[5px\] {
  padding-right: 5px;
}

.pr-px {
  padding-right: 1px;
}

.pt-0 {
  padding-top: 0;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-1\.5 {
  padding-top: .375rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-2\.5 {
  padding-top: .625rem;
}

.pt-28 {
  padding-top: 7rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-3\.5 {
  padding-top: .875rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-\[10px\] {
  padding-top: 10px;
}

.pt-\[18px\] {
  padding-top: 18px;
}

.pt-\[20px\] {
  padding-top: 20px;
}

.pt-\[26px\] {
  padding-top: 26px;
}

.pt-\[30px\] {
  padding-top: 30px;
}

.pt-\[54px\] {
  padding-top: 54px;
}

.pt-\[5px\] {
  padding-top: 5px;
}

.pt-\[6px\] {
  padding-top: 6px;
}

.pt-\[7\.5\%\] {
  padding-top: 7.5%;
}

.pt-px {
  padding-top: 1px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-end {
  text-align: end;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-\[-1px\] {
  vertical-align: -1px;
}

.align-\[-2px\] {
  vertical-align: -2px;
}

.align-\[-5px\] {
  vertical-align: -5px;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.font-sans {
  font-family: Inter var, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.\!text-base {
  font-size: 1rem !important;
}

.\!text-lg {
  font-size: 1.125rem !important;
}

.\!text-sm {
  font-size: .875rem !important;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-4xl {
  font-size: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
}

.text-6xl {
  font-size: 4rem;
}

.text-\[0\.813rem\] {
  font-size: .813rem;
}

.text-\[0\.95rem\] {
  font-size: .95rem;
}

.text-\[1\.5rem\] {
  font-size: 1.5rem;
}

.text-\[100px\] {
  font-size: 100px;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[11px\] {
  font-size: 11px;
}

.text-\[12\.9px\] {
  font-size: 12.9px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[164px\] {
  font-size: 164px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[1rem\] {
  font-size: 1rem;
}

.text-\[2\.25rem\] {
  font-size: 2.25rem;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[30px\] {
  font-size: 30px;
}

.text-\[32px\] {
  font-size: 32px;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-\[48px\] {
  font-size: 48px;
}

.text-\[58px\] {
  font-size: 58px;
}

.text-\[5rem\] {
  font-size: 5rem;
}

.text-\[80px\] {
  font-size: 80px;
}

.text-\[8px\] {
  font-size: 8px;
}

.text-\[9px\] {
  font-size: 9px;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-sm {
  font-size: .875rem;
}

.text-tremor-default {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-tremor-label {
  font-size: .75rem;
}

.text-tremor-metric {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-tremor-title {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-xs {
  font-size: .75rem;
}

.text-xxs {
  font-size: .625rem;
}

.\!font-normal {
  font-weight: 400 !important;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-thin {
  font-weight: 100;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-3 {
  line-height: .75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-\[0\.6\] {
  line-height: .6;
}

.leading-\[1\.1\] {
  line-height: 1.1;
}

.leading-\[1\.2\] {
  line-height: 1.2;
}

.leading-\[1\.3\] {
  line-height: 1.3;
}

.leading-\[121px\] {
  line-height: 121px;
}

.leading-\[12px\] {
  line-height: 12px;
}

.leading-\[14\.52px\] {
  line-height: 14.52px;
}

.leading-\[14\.5px\] {
  line-height: 14.5px;
}

.leading-\[14px\] {
  line-height: 14px;
}

.leading-\[15px\] {
  line-height: 15px;
}

.leading-\[16\.77px\] {
  line-height: 16.77px;
}

.leading-\[16\.8px\] {
  line-height: 16.8px;
}

.leading-\[16\.94px\] {
  line-height: 16.94px;
}

.leading-\[16px\] {
  line-height: 16px;
}

.leading-\[17\.22px\] {
  line-height: 17.22px;
}

.leading-\[17px\] {
  line-height: 17px;
}

.leading-\[18px\] {
  line-height: 18px;
}

.leading-\[19\.2px\] {
  line-height: 19.2px;
}

.leading-\[19px\] {
  line-height: 19px;
}

.leading-\[20px\] {
  line-height: 20px;
}

.leading-\[21\.6px\] {
  line-height: 21.6px;
}

.leading-\[21px\] {
  line-height: 21px;
}

.leading-\[22px\] {
  line-height: 22px;
}

.leading-\[23px\] {
  line-height: 23px;
}

.leading-\[24px\] {
  line-height: 24px;
}

.leading-\[28px\] {
  line-height: 28px;
}

.leading-\[30px\] {
  line-height: 30px;
}

.leading-\[36px\] {
  line-height: 36px;
}

.leading-\[40px\] {
  line-height: 40px;
}

.leading-\[48px\] {
  line-height: 48px;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-\[-2px\] {
  letter-spacing: -2px;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.\!text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1)) !important;
}

.\!text-blue-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(63 131 248 / var(--tw-text-opacity, 1)) !important;
}

.\!text-cool-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(148 163 184 / var(--tw-text-opacity, 1)) !important;
}

.\!text-cool-gray-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(71 85 105 / var(--tw-text-opacity, 1)) !important;
}

.\!text-icPrimary {
  --tw-text-opacity: 1 !important;
  color: rgb(74 70 69 / var(--tw-text-opacity, 1)) !important;
}

.\!text-transparent {
  color: #0000 !important;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.text-\[\#0077B3\] {
  --tw-text-opacity: 1;
  color: rgb(0 119 179 / var(--tw-text-opacity, 1));
}

.text-\[\#047A55\] {
  --tw-text-opacity: 1;
  color: rgb(4 122 85 / var(--tw-text-opacity, 1));
}

.text-\[\#15803D\] {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity, 1));
}

.text-\[\#1E293B\] {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity, 1));
}

.text-\[\#1E3A8A\] {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity, 1));
}

.text-\[\#2F3031\] {
  --tw-text-opacity: 1;
  color: rgb(47 48 49 / var(--tw-text-opacity, 1));
}

.text-\[\#374151\] {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.text-\[\#475569\] {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity, 1));
}

.text-\[\#4F46E5\] {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}

.text-\[\#6B7280\] {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.text-\[\#818CF8\] {
  --tw-text-opacity: 1;
  color: rgb(129 140 248 / var(--tw-text-opacity, 1));
}

.text-\[\#A8B9D1\] {
  --tw-text-opacity: 1;
  color: rgb(168 185 209 / var(--tw-text-opacity, 1));
}

.text-\[\#D97706\] {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity, 1));
}

.text-\[\#FFCF31\] {
  --tw-text-opacity: 1;
  color: rgb(255 207 49 / var(--tw-text-opacity, 1));
}

.text-\[\#d97706\] {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity, 1));
}

.text-\[\#e02424\] {
  --tw-text-opacity: 1;
  color: rgb(224 36 36 / var(--tw-text-opacity, 1));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-blue-100 {
  --tw-text-opacity: 1;
  color: rgb(225 239 254 / var(--tw-text-opacity, 1));
}

.text-blue-200 {
  --tw-text-opacity: 1;
  color: rgb(195 221 253 / var(--tw-text-opacity, 1));
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(164 202 254 / var(--tw-text-opacity, 1));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(118 169 250 / var(--tw-text-opacity, 1));
}

.text-blue-50 {
  --tw-text-opacity: 1;
  color: rgb(235 245 255 / var(--tw-text-opacity, 1));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity, 1));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(26 86 219 / var(--tw-text-opacity, 1));
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 66 159 / var(--tw-text-opacity, 1));
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(35 56 118 / var(--tw-text-opacity, 1));
}

.text-blue-bright-600 {
  --tw-text-opacity: 1;
  color: rgb(36 128 254 / var(--tw-text-opacity, 1));
}

.text-blue-bright-900 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity, 1));
}

.text-blue-light-900 {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity, 1));
}

.text-brandPrimary {
  --tw-text-opacity: 1;
  color: rgb(255 207 49 / var(--tw-text-opacity, 1));
}

.text-cool-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity, 1));
}

.text-cool-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity, 1));
}

.text-cool-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity, 1));
}

.text-cool-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity, 1));
}

.text-cool-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(248 250 252 / var(--tw-text-opacity, 1));
}

.text-cool-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}

.text-cool-gray-500\/90 {
  color: #64748be6;
}

.text-cool-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity, 1));
}

.text-cool-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}

.text-cool-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity, 1));
}

.text-cool-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity, 1));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(244 245 247 / var(--tw-text-opacity, 1));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(210 214 220 / var(--tw-text-opacity, 1));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(159 166 178 / var(--tw-text-opacity, 1));
}

.text-gray-450 {
  --tw-text-opacity: 1;
  color: rgb(136 136 148 / var(--tw-text-opacity, 1));
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity, 1));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(37 47 63 / var(--tw-text-opacity, 1));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(22 30 46 / var(--tw-text-opacity, 1));
}

.text-green-100 {
  --tw-text-opacity: 1;
  color: rgb(222 247 236 / var(--tw-text-opacity, 1));
}

.text-green-200 {
  --tw-text-opacity: 1;
  color: rgb(188 240 218 / var(--tw-text-opacity, 1));
}

.text-green-300 {
  --tw-text-opacity: 1;
  color: rgb(132 225 188 / var(--tw-text-opacity, 1));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(49 196 141 / var(--tw-text-opacity, 1));
}

.text-green-50 {
  --tw-text-opacity: 1;
  color: rgb(243 250 247 / var(--tw-text-opacity, 1));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(14 159 110 / var(--tw-text-opacity, 1));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(5 122 85 / var(--tw-text-opacity, 1));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(4 108 78 / var(--tw-text-opacity, 1));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(3 84 63 / var(--tw-text-opacity, 1));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(1 71 55 / var(--tw-text-opacity, 1));
}

.text-green-warm-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity, 1));
}

.text-green-warm-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity, 1));
}

.text-green-warm-900 {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity, 1));
}

.text-icOnBrandPrimary {
  --tw-text-opacity: 1;
  color: rgb(47 44 44 / var(--tw-text-opacity, 1));
}

.text-icPrimary {
  --tw-text-opacity: 1;
  color: rgb(74 70 69 / var(--tw-text-opacity, 1));
}

.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgb(229 237 255 / var(--tw-text-opacity, 1));
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgb(205 219 254 / var(--tw-text-opacity, 1));
}

.text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgb(180 198 252 / var(--tw-text-opacity, 1));
}

.text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgb(141 162 251 / var(--tw-text-opacity, 1));
}

.text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgb(240 245 255 / var(--tw-text-opacity, 1));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(104 117 245 / var(--tw-text-opacity, 1));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(88 80 236 / var(--tw-text-opacity, 1));
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgb(81 69 205 / var(--tw-text-opacity, 1));
}

.text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgb(66 56 157 / var(--tw-text-opacity, 1));
}

.text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgb(54 47 120 / var(--tw-text-opacity, 1));
}

.text-indigo-bright-100 {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity, 1));
}

.text-indigo-bright-200 {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity, 1));
}

.text-indigo-bright-200\/\[0\.3\] {
  color: #c7d2fe4d;
}

.text-indigo-bright-300 {
  --tw-text-opacity: 1;
  color: rgb(165 180 252 / var(--tw-text-opacity, 1));
}

.text-indigo-bright-50 {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity, 1));
}

.text-indigo-bright-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}

.text-indigo-bright-700 {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity, 1));
}

.text-indigo-bright-800 {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity, 1));
}

.text-indigo-bright-900 {
  --tw-text-opacity: 1;
  color: rgb(54 62 123 / var(--tw-text-opacity, 1));
}

.text-orange-100 {
  --tw-text-opacity: 1;
  color: rgb(254 236 220 / var(--tw-text-opacity, 1));
}

.text-orange-200 {
  --tw-text-opacity: 1;
  color: rgb(252 217 189 / var(--tw-text-opacity, 1));
}

.text-orange-300 {
  --tw-text-opacity: 1;
  color: rgb(253 186 140 / var(--tw-text-opacity, 1));
}

.text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(255 138 76 / var(--tw-text-opacity, 1));
}

.text-orange-50 {
  --tw-text-opacity: 1;
  color: rgb(255 248 241 / var(--tw-text-opacity, 1));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(255 90 31 / var(--tw-text-opacity, 1));
}

.text-orange-600 {
  --tw-text-opacity: 1;
  color: rgb(208 56 1 / var(--tw-text-opacity, 1));
}

.text-orange-700 {
  --tw-text-opacity: 1;
  color: rgb(180 52 3 / var(--tw-text-opacity, 1));
}

.text-orange-800 {
  --tw-text-opacity: 1;
  color: rgb(138 44 13 / var(--tw-text-opacity, 1));
}

.text-orange-900 {
  --tw-text-opacity: 1;
  color: rgb(115 35 13 / var(--tw-text-opacity, 1));
}

.text-orange-warm-900 {
  --tw-text-opacity: 1;
  color: rgb(124 45 18 / var(--tw-text-opacity, 1));
}

.text-pink-100 {
  --tw-text-opacity: 1;
  color: rgb(252 232 243 / var(--tw-text-opacity, 1));
}

.text-pink-200 {
  --tw-text-opacity: 1;
  color: rgb(250 209 232 / var(--tw-text-opacity, 1));
}

.text-pink-300 {
  --tw-text-opacity: 1;
  color: rgb(248 180 217 / var(--tw-text-opacity, 1));
}

.text-pink-400 {
  --tw-text-opacity: 1;
  color: rgb(241 126 184 / var(--tw-text-opacity, 1));
}

.text-pink-50 {
  --tw-text-opacity: 1;
  color: rgb(253 242 248 / var(--tw-text-opacity, 1));
}

.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(231 70 148 / var(--tw-text-opacity, 1));
}

.text-pink-600 {
  --tw-text-opacity: 1;
  color: rgb(214 31 105 / var(--tw-text-opacity, 1));
}

.text-pink-700 {
  --tw-text-opacity: 1;
  color: rgb(191 18 93 / var(--tw-text-opacity, 1));
}

.text-pink-800 {
  --tw-text-opacity: 1;
  color: rgb(153 21 75 / var(--tw-text-opacity, 1));
}

.text-pink-900 {
  --tw-text-opacity: 1;
  color: rgb(117 26 61 / var(--tw-text-opacity, 1));
}

.text-progressive-100 {
  --tw-text-opacity: 1;
  color: rgb(0 119 179 / var(--tw-text-opacity, 1));
}

.text-purple-100 {
  --tw-text-opacity: 1;
  color: rgb(237 235 254 / var(--tw-text-opacity, 1));
}

.text-purple-200 {
  --tw-text-opacity: 1;
  color: rgb(220 215 254 / var(--tw-text-opacity, 1));
}

.text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(202 191 253 / var(--tw-text-opacity, 1));
}

.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgb(172 148 250 / var(--tw-text-opacity, 1));
}

.text-purple-50 {
  --tw-text-opacity: 1;
  color: rgb(246 245 255 / var(--tw-text-opacity, 1));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(144 97 249 / var(--tw-text-opacity, 1));
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(126 58 242 / var(--tw-text-opacity, 1));
}

.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgb(108 43 217 / var(--tw-text-opacity, 1));
}

.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(85 33 181 / var(--tw-text-opacity, 1));
}

.text-purple-900 {
  --tw-text-opacity: 1;
  color: rgb(74 29 150 / var(--tw-text-opacity, 1));
}

.text-purple-bright-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}

.text-purple-bright-600 {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity, 1));
}

.text-red-100 {
  --tw-text-opacity: 1;
  color: rgb(253 232 232 / var(--tw-text-opacity, 1));
}

.text-red-200 {
  --tw-text-opacity: 1;
  color: rgb(251 213 213 / var(--tw-text-opacity, 1));
}

.text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(248 180 180 / var(--tw-text-opacity, 1));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(249 128 128 / var(--tw-text-opacity, 1));
}

.text-red-50 {
  --tw-text-opacity: 1;
  color: rgb(253 242 242 / var(--tw-text-opacity, 1));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(240 82 82 / var(--tw-text-opacity, 1));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(224 36 36 / var(--tw-text-opacity, 1));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(200 30 30 / var(--tw-text-opacity, 1));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(155 28 28 / var(--tw-text-opacity, 1));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(119 29 29 / var(--tw-text-opacity, 1));
}

.text-red-warm-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}

.text-red-warm-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}

.text-red-warm-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity, 1));
}

.text-sky-100 {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity, 1));
}

.text-sky-200 {
  --tw-text-opacity: 1;
  color: rgb(191 219 254 / var(--tw-text-opacity, 1));
}

.text-sky-300 {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity, 1));
}

.text-sky-400 {
  --tw-text-opacity: 1;
  color: rgb(56 189 248 / var(--tw-text-opacity, 1));
}

.text-sky-50 {
  --tw-text-opacity: 1;
  color: rgb(240 249 255 / var(--tw-text-opacity, 1));
}

.text-sky-500 {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity, 1));
}

.text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity, 1));
}

.text-sky-700 {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity, 1));
}

.text-sky-800 {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity, 1));
}

.text-sky-900 {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity, 1));
}

.text-sky-950 {
  --tw-text-opacity: 1;
  color: rgb(8 47 73 / var(--tw-text-opacity, 1));
}

.text-teal-bright-200 {
  --tw-text-opacity: 1;
  color: rgb(153 246 228 / var(--tw-text-opacity, 1));
}

.text-teal-bright-600 {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity, 1));
}

.text-textLinkPrimary {
  --tw-text-opacity: 1;
  color: rgb(27 80 115 / var(--tw-text-opacity, 1));
}

.text-textOnBrandPrimary, .text-textPrimary {
  --tw-text-opacity: 1;
  color: rgb(47 44 44 / var(--tw-text-opacity, 1));
}

.text-textSecondary {
  --tw-text-opacity: 1;
  color: rgb(74 70 69 / var(--tw-text-opacity, 1));
}

.text-tremor-brand {
  --tw-text-opacity: 1;
  color: rgb(36 128 254 / var(--tw-text-opacity, 1));
}

.text-tremor-brand-emphasis {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity, 1));
}

.text-tremor-brand-inverted {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-tremor-content {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}

.text-tremor-content-emphasis {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}

.text-tremor-content-subtle {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-white\/80 {
  color: #fffc;
}

.text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgb(253 246 178 / var(--tw-text-opacity, 1));
}

.text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgb(252 233 106 / var(--tw-text-opacity, 1));
}

.text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgb(250 202 21 / var(--tw-text-opacity, 1));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(227 160 8 / var(--tw-text-opacity, 1));
}

.text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgb(253 253 234 / var(--tw-text-opacity, 1));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(194 120 3 / var(--tw-text-opacity, 1));
}

.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(159 88 10 / var(--tw-text-opacity, 1));
}

.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgb(142 75 16 / var(--tw-text-opacity, 1));
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(114 59 19 / var(--tw-text-opacity, 1));
}

.text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgb(99 49 18 / var(--tw-text-opacity, 1));
}

.text-yellow-warm-400 {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity, 1));
}

.text-yellow-warm-600 {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity, 1));
}

.text-yellow-warm-700 {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity, 1));
}

.text-opacity-60 {
  --tw-text-opacity: .6;
}

.text-opacity-80 {
  --tw-text-opacity: .8;
}

.underline {
  text-decoration-line: underline;
}

.line-through {
  text-decoration-line: line-through;
}

.decoration-solid {
  text-decoration-style: solid;
}

.decoration-1 {
  text-decoration-thickness: 1px;
}

.underline-offset-2 {
  text-underline-offset: 2px;
}

.underline-offset-\[3px\] {
  text-underline-offset: 3px;
}

.placeholder-cool-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(148 163 184 / var(--tw-placeholder-opacity, 1));
}

.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(159 166 178 / var(--tw-placeholder-opacity, 1));
}

.placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity, 1));
}

.accent-indigo-600 {
  accent-color: #5850ec;
}

.accent-tremor-brand {
  accent-color: #2480fe;
}

.accent-white {
  accent-color: #fff;
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: .1;
}

.opacity-100 {
  opacity: 1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-25 {
  opacity: .25;
}

.opacity-40 {
  opacity: .4;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-75 {
  opacity: .75;
}

.mix-blend-multiply {
  mix-blend-mode: multiply;
}

.\!shadow-\[0_3px_8px_rgba\(0\,0\,0\,0\.15\)\] {
  --tw-shadow: 0 3px 8px #00000026 !important;
  --tw-shadow-colored: 0 3px 8px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.\!shadow-lightindigo {
  --tw-shadow: 0px 0px 2px 2px #4f46e526 !important;
  --tw-shadow-colored: 0px 0px 2px 2px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.\!shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_-4px_10px_0_rgba\(0\,0\,0\,0\.25\)\] {
  --tw-shadow: 0 -4px 10px 0 #00000040;
  --tw-shadow-colored: 0 -4px 10px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_0_1px_rgb\(67\,56\,202\)\,0_1px_2px_0_rgba\(0\,0\,0\,0\.6\)\] {
  --tw-shadow: 0 0 0 1px #4338ca, 0 1px 2px 0 #0009;
  --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_0_1px_rgb\(67\,56\,202\)\] {
  --tw-shadow: 0 0 0 1px #4338ca;
  --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_0_1px_theme\(colors\.gray\.200\)\] {
  --tw-shadow: 0 0 0 1px #e5e7eb;
  --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_0_2px_rgba\(80\,80\,236\,1\)\] {
  --tw-shadow: 0 0 0 2px #5050ec;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_15px_15px_rgba\(255\,255\,255\,1\)\,0_0_1px_2px_rgba\(0\,0\,0\,0\.5\)\,0_1px_3px_0_rgba\(0\,0\,0\,0\.5\)\] {
  --tw-shadow: 0 0 15px 15px #fff, 0 0 1px 2px #00000080, 0 1px 3px 0 #00000080;
  --tw-shadow-colored: 0 0 15px 15px var(--tw-shadow-color), 0 0 1px 2px var(--tw-shadow-color), 0 1px 3px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_4px_4px_rgba\(255\,255\,255\,1\)\] {
  --tw-shadow: 0 0 4px 4px #fff;
  --tw-shadow-colored: 0 0 4px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_1\.6px_3\.2px_0_rgba\(0\,0\,0\,0\.06\)\,0_1\.6px_4\.8px_0_rgba\(0\,0\,0\,0\.1\)\] {
  --tw-shadow: 0 1.6px 3.2px 0 #0000000f, 0 1.6px 4.8px 0 #0000001a;
  --tw-shadow-colored: 0 1.6px 3.2px 0 var(--tw-shadow-color), 0 1.6px 4.8px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_1px_2px_0_rgba\(0\,0\,0\,0\.06\)\,0_1px_3px_0_rgba\(0\,0\,0\,0\.10\)\] {
  --tw-shadow: 0 1px 2px 0 #0000000f, 0 1px 3px 0 #0000001a;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color), 0 1px 3px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_-2px_10px_0px_\#00000019\] {
  --tw-shadow: 0px -2px 10px 0px #00000019;
  --tw-shadow-colored: 0px -2px 10px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_0px_2px_2px_rgba\(79\,70\,229\,0\.15\)\] {
  --tw-shadow: 0px 0px 2px 2px #4f46e526;
  --tw-shadow-colored: 0px 0px 2px 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_1px_1px_0px_rgba\(0\,_0\,_0\,_0\.25\)_inset\] {
  --tw-shadow: 0px 1px 1px 0px #00000040 inset;
  --tw-shadow-colored: inset 0px 1px 1px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_1px_2px_0px_rgba\(0\,0\,0\,0\.06\)\,0px_1px_3px_0px_rgba\(0\,0\,0\,0\.1\)\] {
  --tw-shadow: 0px 1px 2px 0px #0000000f, 0px 1px 3px 0px #0000001a;
  --tw-shadow-colored: 0px 1px 2px 0px var(--tw-shadow-color), 0px 1px 3px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_1px_2px_0px_rgba\(0_0_0_0\.06\)\,0px_1px_3px_0px_rgba\(0_0_0_0\.1\)\] {
  --tw-shadow: 0px 1px 2px 0px rgba(0 0 0 .06), 0px 1px 3px 0px rgba(0 0 0 .1);
  --tw-shadow-colored: 0px 1px 2px 0px var(--tw-shadow-color), 0px 1px 3px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_1px_3px_0px_rgba\(0\,0\,0\,0\.10\)\,_0px_1px_2px_0px_rgba\(0\,0\,0\,0\.06\)\] {
  --tw-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px 0px #0000000f;
  --tw-shadow-colored: 0px 1px 3px 0px var(--tw-shadow-color), 0px 1px 2px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_2px_3px_0px_rgba\(0\,_0\,_0\,_0\.25\)\,0px_2px_2px_0px_rgba\(0\,_0\,_0\,_0\.5\)_inset\,0px_-2px_2px_0px_rgba\(0\,_0\,_0\,_0\.5\)_inset\] {
  --tw-shadow: 0px 2px 3px 0px #00000040, 0px 2px 2px 0px #00000080 inset, 0px -2px 2px 0px #00000080 inset;
  --tw-shadow-colored: 0px 2px 3px 0px var(--tw-shadow-color), inset 0px 2px 2px 0px var(--tw-shadow-color), inset 0px -2px 2px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_3px_2px_0px_rgba\(0\,_0\,_0\,_0\.1\)\,0px_-1px_1px_0px_rgba\(0\,_0\,_0\,_0\.15\)_inset\,0px_1px_1px_0px_rgba\(255\,_255\,_255\,_0\.5\)_inset\] {
  --tw-shadow: 0px 3px 2px 0px #0000001a, 0px -1px 1px 0px #00000026 inset, 0px 1px 1px 0px #ffffff80 inset;
  --tw-shadow-colored: 0px 3px 2px 0px var(--tw-shadow-color), inset 0px -1px 1px 0px var(--tw-shadow-color), inset 0px 1px 1px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_4px_6px_-1px_rgba\(0\,0\,0\,0\.1\)\,0px_2px_4px_-1px_rgba\(0\,0\,0\,0\.06\)\] {
  --tw-shadow: 0px 4px 6px -1px #0000001a, 0px 2px 4px -1px #0000000f;
  --tw-shadow-colored: 0px 4px 6px -1px var(--tw-shadow-color), 0px 2px 4px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_-1px_0_0_0_rgba\(229\,231\,235\,1\)\,inset_0_-1px_0_0_rgba\(229\,231\,235\,1\)\] {
  --tw-shadow: inset -1px 0 0 0 #e5e7eb, inset 0 -1px 0 0 #e5e7eb;
  --tw-shadow-colored: inset -1px 0 0 0 var(--tw-shadow-color), inset 0 -1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_-7px_-7px_5px_0px_rgba\(249\,250\,251\,1\)\] {
  --tw-shadow: inset -7px -7px 5px 0px #f9fafb;
  --tw-shadow-colored: inset -7px -7px 5px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_-7px_-7px_5px_0px_rgba\(255\,255\,255\,1\)\] {
  --tw-shadow: inset -7px -7px 5px 0px #fff;
  --tw-shadow-colored: inset -7px -7px 5px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0_-1px_0_0_rgba\(229\,231\,235\,1\)\] {
  --tw-shadow: inset 0 -1px 0 0 #e5e7eb;
  --tw-shadow-colored: inset 0 -1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0_0_0_1px_rgb\(67\,56\,202\)\] {
  --tw-shadow: inset 0 0 0 1px #4338ca;
  --tw-shadow-colored: inset 0 0 0 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0_0_0_2px_\#4F46E5\] {
  --tw-shadow: inset 0 0 0 2px #4f46e5;
  --tw-shadow-colored: inset 0 0 0 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0_0_0_2px_\#E3A008\] {
  --tw-shadow: inset 0 0 0 2px #e3a008;
  --tw-shadow-colored: inset 0 0 0 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0_0_0_2px_\#c7d2fe\] {
  --tw-shadow: inset 0 0 0 2px #c7d2fe;
  --tw-shadow-colored: inset 0 0 0 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0_0_0_3px_\#E3A008\] {
  --tw-shadow: inset 0 0 0 3px #e3a008;
  --tw-shadow-colored: inset 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0_1px_0_0_rgba\(229\,231\,235\,1\)\,inset_-1px_0_0_0_rgba\(229\,231\,235\,1\)\] {
  --tw-shadow: inset 0 1px 0 0 #e5e7eb, inset -1px 0 0 0 #e5e7eb;
  --tw-shadow-colored: inset 0 1px 0 0 var(--tw-shadow-color), inset -1px 0 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0_1px_0_0_rgba\(229\,231\,235\,1\)\] {
  --tw-shadow: inset 0 1px 0 0 #e5e7eb;
  --tw-shadow-colored: inset 0 1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_1px_0_0_0_rgba\(229\,231\,235\,1\)\,inset_0_-1px_0_0_rgba\(229\,231\,235\,1\)\] {
  --tw-shadow: inset 1px 0 0 0 #e5e7eb, inset 0 -1px 0 0 #e5e7eb;
  --tw-shadow-colored: inset 1px 0 0 0 var(--tw-shadow-color), inset 0 -1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_1px_0_0_0_rgba\(229\,231\,235\,1\)\,inset_0_1px_0_0_rgba\(229\,231\,235\,1\)\] {
  --tw-shadow: inset 1px 0 0 0 #e5e7eb, inset 0 1px 0 0 #e5e7eb;
  --tw-shadow-colored: inset 1px 0 0 0 var(--tw-shadow-color), inset 0 1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lifted {
  --tw-shadow: 0px 1px 2px 0px #0000000f, 0px 1px 3px 0px #0000001a;
  --tw-shadow-colored: 0px 1px 2px 0px var(--tw-shadow-color), 0px 1px 3px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lifted-lg {
  --tw-shadow: 0px 1px 2px 0px #0000000f, 0px 4px 20px 4px #0000001a;
  --tw-shadow-colored: 0px 1px 2px 0px var(--tw-shadow-color), 0px 4px 20px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lifted-sm {
  --tw-shadow: 0px 4px 6px -1px #0000001a, 0px 2px 4px -1px #0000000f;
  --tw-shadow-colored: 0px 4px 6px -1px var(--tw-shadow-color), 0px 2px 4px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lifted-top-sm {
  --tw-shadow: 0px -2px 4px -1px #0000000f, 0px -4px 6px -1px #0000001a;
  --tw-shadow-colored: 0px -2px 4px -1px var(--tw-shadow-color), 0px -4px 6px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lightgray {
  --tw-shadow: 0px 0px 2px 0px #94a3b840;
  --tw-shadow-colored: 0px 0px 2px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-outline {
  --tw-shadow: 0 0 0 3px #76a9fa73;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-outline-gray {
  --tw-shadow: 0 0 0 3px #fecaca80;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-outline-gray-light {
  --tw-shadow: 0px 1px 3px #0000001a, 0px 1px 2px #0000000f;
  --tw-shadow-colored: 0px 1px 3px var(--tw-shadow-color), 0px 1px 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-outline-indigo {
  --tw-shadow: 0 0 0 3px #c7d2fe80;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-outline-indigo-bright-inset {
  --tw-shadow: inset 0 0 0 3px #c7d2fe;
  --tw-shadow-colored: inset 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-solid {
  --tw-shadow: 0 0 0 2px currentColor;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-tremor-card {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-tremor-dropdown {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-tremor-input {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.outline-0 {
  outline-width: 0;
}

.outline-1 {
  outline-width: 1px;
}

.outline-\[2px\] {
  outline-width: 2px;
}

.outline-\[3px\] {
  outline-width: 3px;
}

.outline-cool-gray-300 {
  outline-color: #cbd5e1;
}

.outline-indigo-500 {
  outline-color: #6875f5;
}

.outline-indigo-600 {
  outline-color: #5850ec;
}

.outline-indigo-bright-200 {
  outline-color: #c7d2fe;
}

.outline-indigo-bright-600 {
  outline-color: #4f46e5;
}

.outline-tremor-brand {
  outline-color: #2480fe;
}

.\!ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.\!ring-\[1\.33px\] {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1.33px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.\!ring-\[2px\] {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.\!ring-\[3\.5px\] {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3.5px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-8 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-\[1\.33px\] {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1.33px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-\[1\.5px\] {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1.5px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.\!ring-\[\#015DD0\] {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(1 93 208 / var(--tw-ring-opacity, 1)) !important;
}

.\!ring-\[\#016839\] {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(1 104 57 / var(--tw-ring-opacity, 1)) !important;
}

.\!ring-black {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity, 1)) !important;
}

.\!ring-indigo-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(88 80 236 / var(--tw-ring-opacity, 1)) !important;
}

.\!ring-red-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(224 36 36 / var(--tw-ring-opacity, 1)) !important;
}

.ring-\[\#0659A0\] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 89 160 / var(--tw-ring-opacity, 1));
}

.ring-\[\#13803E\] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(19 128 62 / var(--tw-ring-opacity, 1));
}

.ring-\[\#B01B2E\] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(176 27 46 / var(--tw-ring-opacity, 1));
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity, 1));
}

.ring-blue-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(225 239 254 / var(--tw-ring-opacity, 1));
}

.ring-blue-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(195 221 253 / var(--tw-ring-opacity, 1));
}

.ring-blue-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(164 202 254 / var(--tw-ring-opacity, 1));
}

.ring-blue-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(118 169 250 / var(--tw-ring-opacity, 1));
}

.ring-blue-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(235 245 255 / var(--tw-ring-opacity, 1));
}

.ring-blue-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(63 131 248 / var(--tw-ring-opacity, 1));
}

.ring-blue-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity, 1));
}

.ring-blue-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(26 86 219 / var(--tw-ring-opacity, 1));
}

.ring-blue-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(30 66 159 / var(--tw-ring-opacity, 1));
}

.ring-blue-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(35 56 118 / var(--tw-ring-opacity, 1));
}

.ring-blue-bright-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(36 128 254 / var(--tw-ring-opacity, 1));
}

.ring-cool-gray-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(51 65 85 / var(--tw-ring-opacity, 1));
}

.ring-gray-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(244 245 247 / var(--tw-ring-opacity, 1));
}

.ring-gray-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity, 1));
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(210 214 220 / var(--tw-ring-opacity, 1));
}

.ring-gray-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(159 166 178 / var(--tw-ring-opacity, 1));
}

.ring-gray-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(249 250 251 / var(--tw-ring-opacity, 1));
}

.ring-gray-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity, 1));
}

.ring-gray-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity, 1));
}

.ring-gray-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity, 1));
}

.ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(37 47 63 / var(--tw-ring-opacity, 1));
}

.ring-gray-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(22 30 46 / var(--tw-ring-opacity, 1));
}

.ring-green-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(222 247 236 / var(--tw-ring-opacity, 1));
}

.ring-green-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(188 240 218 / var(--tw-ring-opacity, 1));
}

.ring-green-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(132 225 188 / var(--tw-ring-opacity, 1));
}

.ring-green-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(49 196 141 / var(--tw-ring-opacity, 1));
}

.ring-green-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(243 250 247 / var(--tw-ring-opacity, 1));
}

.ring-green-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(14 159 110 / var(--tw-ring-opacity, 1));
}

.ring-green-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(5 122 85 / var(--tw-ring-opacity, 1));
}

.ring-green-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(4 108 78 / var(--tw-ring-opacity, 1));
}

.ring-green-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(3 84 63 / var(--tw-ring-opacity, 1));
}

.ring-green-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(1 71 55 / var(--tw-ring-opacity, 1));
}

.ring-indigo-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 237 255 / var(--tw-ring-opacity, 1));
}

.ring-indigo-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(205 219 254 / var(--tw-ring-opacity, 1));
}

.ring-indigo-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(180 198 252 / var(--tw-ring-opacity, 1));
}

.ring-indigo-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(141 162 251 / var(--tw-ring-opacity, 1));
}

.ring-indigo-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(240 245 255 / var(--tw-ring-opacity, 1));
}

.ring-indigo-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(104 117 245 / var(--tw-ring-opacity, 1));
}

.ring-indigo-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(88 80 236 / var(--tw-ring-opacity, 1));
}

.ring-indigo-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(81 69 205 / var(--tw-ring-opacity, 1));
}

.ring-indigo-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(66 56 157 / var(--tw-ring-opacity, 1));
}

.ring-indigo-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(54 47 120 / var(--tw-ring-opacity, 1));
}

.ring-indigo-bright-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(224 231 255 / var(--tw-ring-opacity, 1));
}

.ring-indigo-bright-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(199 210 254 / var(--tw-ring-opacity, 1));
}

.ring-indigo-bright-200\/\[0\.3\] {
  --tw-ring-color: #c7d2fe4d;
}

.ring-indigo-bright-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(165 180 252 / var(--tw-ring-opacity, 1));
}

.ring-indigo-bright-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(238 242 255 / var(--tw-ring-opacity, 1));
}

.ring-indigo-bright-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity, 1));
}

.ring-indigo-bright-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(67 56 202 / var(--tw-ring-opacity, 1));
}

.ring-indigo-bright-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(55 48 163 / var(--tw-ring-opacity, 1));
}

.ring-indigo-bright-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(54 62 123 / var(--tw-ring-opacity, 1));
}

.ring-orange-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(254 236 220 / var(--tw-ring-opacity, 1));
}

.ring-orange-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 217 189 / var(--tw-ring-opacity, 1));
}

.ring-orange-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(253 186 140 / var(--tw-ring-opacity, 1));
}

.ring-orange-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 138 76 / var(--tw-ring-opacity, 1));
}

.ring-orange-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 248 241 / var(--tw-ring-opacity, 1));
}

.ring-orange-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 90 31 / var(--tw-ring-opacity, 1));
}

.ring-orange-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(208 56 1 / var(--tw-ring-opacity, 1));
}

.ring-orange-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(180 52 3 / var(--tw-ring-opacity, 1));
}

.ring-orange-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(138 44 13 / var(--tw-ring-opacity, 1));
}

.ring-orange-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(115 35 13 / var(--tw-ring-opacity, 1));
}

.ring-pink-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 232 243 / var(--tw-ring-opacity, 1));
}

.ring-pink-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(250 209 232 / var(--tw-ring-opacity, 1));
}

.ring-pink-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(248 180 217 / var(--tw-ring-opacity, 1));
}

.ring-pink-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(241 126 184 / var(--tw-ring-opacity, 1));
}

.ring-pink-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(253 242 248 / var(--tw-ring-opacity, 1));
}

.ring-pink-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(231 70 148 / var(--tw-ring-opacity, 1));
}

.ring-pink-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(214 31 105 / var(--tw-ring-opacity, 1));
}

.ring-pink-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(191 18 93 / var(--tw-ring-opacity, 1));
}

.ring-pink-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(153 21 75 / var(--tw-ring-opacity, 1));
}

.ring-pink-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(117 26 61 / var(--tw-ring-opacity, 1));
}

.ring-purple-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(237 235 254 / var(--tw-ring-opacity, 1));
}

.ring-purple-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(220 215 254 / var(--tw-ring-opacity, 1));
}

.ring-purple-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(202 191 253 / var(--tw-ring-opacity, 1));
}

.ring-purple-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(172 148 250 / var(--tw-ring-opacity, 1));
}

.ring-purple-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(246 245 255 / var(--tw-ring-opacity, 1));
}

.ring-purple-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(144 97 249 / var(--tw-ring-opacity, 1));
}

.ring-purple-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(126 58 242 / var(--tw-ring-opacity, 1));
}

.ring-purple-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(108 43 217 / var(--tw-ring-opacity, 1));
}

.ring-purple-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(85 33 181 / var(--tw-ring-opacity, 1));
}

.ring-purple-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(74 29 150 / var(--tw-ring-opacity, 1));
}

.ring-purple-bright-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity, 1));
}

.ring-red-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(253 232 232 / var(--tw-ring-opacity, 1));
}

.ring-red-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(251 213 213 / var(--tw-ring-opacity, 1));
}

.ring-red-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(248 180 180 / var(--tw-ring-opacity, 1));
}

.ring-red-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(249 128 128 / var(--tw-ring-opacity, 1));
}

.ring-red-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(253 242 242 / var(--tw-ring-opacity, 1));
}

.ring-red-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(240 82 82 / var(--tw-ring-opacity, 1));
}

.ring-red-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(224 36 36 / var(--tw-ring-opacity, 1));
}

.ring-red-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(200 30 30 / var(--tw-ring-opacity, 1));
}

.ring-red-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(155 28 28 / var(--tw-ring-opacity, 1));
}

.ring-red-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(119 29 29 / var(--tw-ring-opacity, 1));
}

.ring-red-warm-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity, 1));
}

.ring-sky-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(224 242 254 / var(--tw-ring-opacity, 1));
}

.ring-sky-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity, 1));
}

.ring-sky-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity, 1));
}

.ring-sky-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(56 189 248 / var(--tw-ring-opacity, 1));
}

.ring-sky-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(240 249 255 / var(--tw-ring-opacity, 1));
}

.ring-sky-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(14 165 233 / var(--tw-ring-opacity, 1));
}

.ring-sky-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(2 132 199 / var(--tw-ring-opacity, 1));
}

.ring-sky-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(3 105 161 / var(--tw-ring-opacity, 1));
}

.ring-sky-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(7 89 133 / var(--tw-ring-opacity, 1));
}

.ring-sky-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(12 74 110 / var(--tw-ring-opacity, 1));
}

.ring-sky-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(8 47 73 / var(--tw-ring-opacity, 1));
}

.ring-teal-bright-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(153 246 228 / var(--tw-ring-opacity, 1));
}

.ring-teal-bright-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(13 148 136 / var(--tw-ring-opacity, 1));
}

.ring-tremor-brand-inverted {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity, 1));
}

.ring-tremor-brand-muted {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity, 1));
}

.ring-tremor-ring {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(226 232 240 / var(--tw-ring-opacity, 1));
}

.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity, 1));
}

.ring-yellow-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(253 246 178 / var(--tw-ring-opacity, 1));
}

.ring-yellow-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 233 106 / var(--tw-ring-opacity, 1));
}

.ring-yellow-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(250 202 21 / var(--tw-ring-opacity, 1));
}

.ring-yellow-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(227 160 8 / var(--tw-ring-opacity, 1));
}

.ring-yellow-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(253 253 234 / var(--tw-ring-opacity, 1));
}

.ring-yellow-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(194 120 3 / var(--tw-ring-opacity, 1));
}

.ring-yellow-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(159 88 10 / var(--tw-ring-opacity, 1));
}

.ring-yellow-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(142 75 16 / var(--tw-ring-opacity, 1));
}

.ring-yellow-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(114 59 19 / var(--tw-ring-opacity, 1));
}

.ring-yellow-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 49 18 / var(--tw-ring-opacity, 1));
}

.ring-yellow-warm-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(251 191 36 / var(--tw-ring-opacity, 1));
}

.ring-opacity-40 {
  --tw-ring-opacity: .4;
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.ring-offset-\[0\.75px\] {
  --tw-ring-offset-width: .75px;
}

.ring-offset-\[2px\] {
  --tw-ring-offset-width: 2px;
}

.ring-offset-indigo-600 {
  --tw-ring-offset-color: #5850ec;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.\!drop-shadow-none {
  --tw-drop-shadow: drop-shadow(0 0 #0000) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-sm {
  --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.\!filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-\[2px\] {
  --tw-backdrop-blur: blur(2px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[backdrop-blur\,opacity\] {
  transition-property: backdrop-blur, opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[height\,top\,opacity\] {
  transition-property: height, top, opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[opacity\,transform\,translate-y\] {
  transition-property: opacity, transform, translate-y;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[top\] {
  transition-property: top;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[width\] {
  transition-property: width;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-width {
  transition-property: width;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-100 {
  transition-delay: .1s;
}

.delay-150 {
  transition-delay: .15s;
}

.delay-200 {
  transition-delay: .2s;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-1000 {
  transition-duration: 1s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.\[appearance\:textfield\] {
  appearance: textfield;
}

.\[scrollbar-width\:none\] {
  scrollbar-width: none;
}

.first-letter\:uppercase:first-letter {
  text-transform: uppercase;
}

.first-letter\:capitalize:first-letter {
  text-transform: capitalize;
}

.marker\:text-cool-gray-300 ::marker, .marker\:text-cool-gray-300::marker {
  color: #cbd5e1;
}

.placeholder\:text-cool-gray-300::placeholder {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity, 1));
}

.placeholder\:text-cool-gray-400::placeholder {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity, 1));
}

.placeholder\:text-gray-500::placeholder {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.placeholder\:text-tremor-content::placeholder {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}

.placeholder\:text-tremor-content-subtle::placeholder {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity, 1));
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:left-2:before {
  content: var(--tw-content);
  left: .5rem;
}

.before\:right-2:before {
  content: var(--tw-content);
  right: .5rem;
}

.before\:top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:hidden:before {
  content: var(--tw-content);
  display: none;
}

.before\:h-\[1px\]:before {
  content: var(--tw-content);
  height: 1px;
}

.before\:bg-\[\#94A2B8\]:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(148 162 184 / var(--tw-bg-opacity, 1));
}

.before\:bg-gray-200:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:inset-0:after {
  content: var(--tw-content);
  inset: 0;
}

.after\:inset-0\.5:after {
  content: var(--tw-content);
  inset: .125rem;
}

.after\:bottom-0:after {
  content: var(--tw-content);
  bottom: 0;
}

.after\:bottom-2:after {
  content: var(--tw-content);
  bottom: .5rem;
}

.after\:bottom-\[-10px\]:after {
  content: var(--tw-content);
  bottom: -10px;
}

.after\:left-0:after {
  content: var(--tw-content);
  left: 0;
}

.after\:left-1\/2:after {
  content: var(--tw-content);
  left: 50%;
}

.after\:left-\[2px\]:after {
  content: var(--tw-content);
  left: 2px;
}

.after\:left-\[7px\]:after {
  content: var(--tw-content);
  left: 7px;
}

.after\:right-0:after {
  content: var(--tw-content);
  right: 0;
}

.after\:top-0:after {
  content: var(--tw-content);
  top: 0;
}

.after\:top-2:after {
  content: var(--tw-content);
  top: .5rem;
}

.after\:top-\[2px\]:after {
  content: var(--tw-content);
  top: 2px;
}

.after\:top-\[3px\]:after {
  content: var(--tw-content);
  top: 3px;
}

.after\:z-10:after {
  content: var(--tw-content);
  z-index: 10;
}

.after\:z-\[-1\]:after {
  content: var(--tw-content);
  z-index: -1;
}

.after\:ml-1\.5:after {
  content: var(--tw-content);
  margin-left: .375rem;
}

.after\:mt-\[18px\]:after {
  content: var(--tw-content);
  margin-top: 18px;
}

.after\:block:after {
  content: var(--tw-content);
  display: block;
}

.after\:hidden:after {
  content: var(--tw-content);
  display: none;
}

.after\:\!h-\[20px\]:after {
  content: var(--tw-content);
  height: 20px !important;
}

.after\:h-4:after {
  content: var(--tw-content);
  height: 1rem;
}

.after\:h-5:after {
  content: var(--tw-content);
  height: 1.25rem;
}

.after\:h-6:after {
  content: var(--tw-content);
  height: 1.5rem;
}

.after\:\!w-\[20px\]:after {
  content: var(--tw-content);
  width: 20px !important;
}

.after\:w-4:after {
  content: var(--tw-content);
  width: 1rem;
}

.after\:w-5:after {
  content: var(--tw-content);
  width: 1.25rem;
}

.after\:w-\[1px\]:after {
  content: var(--tw-content);
  width: 1px;
}

.after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.after\:w-px:after {
  content: var(--tw-content);
  width: 1px;
}

.after\:flex-1:after {
  content: var(--tw-content);
  flex: 1;
}

.after\:-translate-x-1\/2:after {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:-rotate-45:after {
  content: var(--tw-content);
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:rounded-lg:after {
  content: var(--tw-content);
  border-radius: .5rem;
}

.after\:rounded-md:after {
  content: var(--tw-content);
  border-radius: .375rem;
}

.after\:rounded-bl-sm:after {
  content: var(--tw-content);
  border-bottom-left-radius: .125rem;
}

.after\:rounded-tr-\[100\%\]:after {
  content: var(--tw-content);
  border-top-right-radius: 100%;
}

.after\:border:after {
  content: var(--tw-content);
  border-width: 1px;
}

.after\:border-\[1\.50px\]:after {
  content: var(--tw-content);
  border-width: 1.5px;
}

.after\:border-\[3px\]:after {
  content: var(--tw-content);
  border-width: 3px;
}

.after\:border-b:after {
  content: var(--tw-content);
  border-bottom-width: 1px;
}

.after\:border-black:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

.after\:border-cool-gray-100:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249 / var(--tw-border-opacity, 1));
}

.after\:border-white:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.after\:bg-\[\#015DD0\]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(1 93 208 / var(--tw-bg-opacity, 1));
}

.after\:bg-\[\#016839\]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(1 104 57 / var(--tw-bg-opacity, 1));
}

.after\:bg-cool-gray-500:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity, 1));
}

.after\:bg-cool-gray-800:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity, 1));
}

.after\:bg-gray-400:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(159 166 178 / var(--tw-bg-opacity, 1));
}

.after\:bg-white:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.after\:bg-gradient-to-t:after {
  content: var(--tw-content);
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.after\:from-white:after {
  content: var(--tw-content);
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.after\:to-transparent:after {
  content: var(--tw-content);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:content-\[\"\"\]:after, .after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.first\:ml-0:first-child {
  margin-left: 0;
}

.first\:justify-between:first-child {
  justify-content: space-between;
}

.first\:rounded-l-\[4px\]:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.first\:rounded-tl-2xl:first-child {
  border-top-left-radius: 1rem;
}

.first\:rounded-tr-2xl:first-child {
  border-top-right-radius: 1rem;
}

.first\:pl-6:first-child {
  padding-left: 1.5rem;
}

.first\:pt-2:first-child {
  padding-top: .5rem;
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.last\:mr-0:last-child {
  margin-right: 0;
}

.last\:hidden:last-child {
  display: none;
}

.last\:rounded-r-\[4px\]:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.last\:border-0:last-child {
  border-width: 0;
}

.last\:border-b-0:last-child {
  border-bottom-width: 0;
}

.last\:border-transparent:last-child {
  border-color: #0000;
}

.last\:border-b-transparent:last-child {
  border-bottom-color: #0000;
}

.last\:border-r-transparent:last-child {
  border-right-color: #0000;
}

.last\:pb-0:last-child {
  padding-bottom: 0;
}

.last\:pb-2:last-child {
  padding-bottom: .5rem;
}

.last\:pt-2:last-child {
  padding-top: .5rem;
}

.only\:mx-auto:only-child {
  margin-left: auto;
  margin-right: auto;
}

.checked\:border-none:checked {
  border-style: none;
}

.checked\:accent-indigo-bright-600:checked {
  accent-color: #4f46e5;
}

.empty\:hidden:empty {
  display: none;
}

.empty\:p-0:empty {
  padding: 0;
}

.empty\:py-0:empty {
  padding-top: 0;
  padding-bottom: 0;
}

.empty\:pb-0:empty {
  padding-bottom: 0;
}

.empty\:pr-0:empty {
  padding-right: 0;
}

.empty\:before\:hidden:empty:before {
  content: var(--tw-content);
  display: none;
}

.focus-within\:relative:focus-within {
  position: relative;
}

.focus-within\:z-10:focus-within {
  z-index: 10;
}

.focus-within\:rounded-\[4px\]:focus-within {
  border-radius: 4px;
}

.focus-within\:border-x:focus-within {
  border-left-width: 1px;
  border-right-width: 1px;
}

.focus-within\:border-blue-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(164 202 254 / var(--tw-border-opacity, 1));
}

.focus-within\:border-indigo-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(141 162 251 / var(--tw-border-opacity, 1));
}

.focus-within\:border-indigo-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(88 80 236 / var(--tw-border-opacity, 1));
}

.focus-within\:bg-white:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.focus-within\:text-gray-500:focus-within {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.focus-within\:shadow-\[0px_0px_4px_0px_\#818CF8\]:focus-within {
  --tw-shadow: 0px 0px 4px 0px #818cf8;
  --tw-shadow-colored: 0px 0px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-within\:ring:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-1:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-2:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-blue-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(195 221 253 / var(--tw-ring-opacity, 1));
}

.focus-within\:ring-primary\/50:focus-within {
  --tw-ring-color: #ffffff80;
}

.focus-within\:ring-opacity-50:focus-within {
  --tw-ring-opacity: .5;
}

.focus-within\:ring-offset-1:focus-within {
  --tw-ring-offset-width: 1px;
}

.focus-within\:ring-offset-\[\#F3F9FC\]:focus-within {
  --tw-ring-offset-color: #f3f9fc;
}

.hover\:z-40:hover {
  z-index: 40;
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-125:hover {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:cursor-default:hover {
  cursor: default;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:rounded-\[4px\]:hover {
  border-radius: 4px;
}

.hover\:border-b-2:hover {
  border-bottom-width: 2px;
}

.hover\:border-solid:hover {
  border-style: solid;
}

.hover\:\!border-gray-400:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(159 166 178 / var(--tw-border-opacity, 1)) !important;
}

.hover\:border-\[\#4F46E5\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity, 1));
}

.hover\:border-blue-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(225 239 254 / var(--tw-border-opacity, 1));
}

.hover\:border-blue-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(195 221 253 / var(--tw-border-opacity, 1));
}

.hover\:border-blue-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(164 202 254 / var(--tw-border-opacity, 1));
}

.hover\:border-blue-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(118 169 250 / var(--tw-border-opacity, 1));
}

.hover\:border-blue-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(235 245 255 / var(--tw-border-opacity, 1));
}

.hover\:border-blue-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(63 131 248 / var(--tw-border-opacity, 1));
}

.hover\:border-blue-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity, 1));
}

.hover\:border-blue-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(26 86 219 / var(--tw-border-opacity, 1));
}

.hover\:border-blue-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(30 66 159 / var(--tw-border-opacity, 1));
}

.hover\:border-blue-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(35 56 118 / var(--tw-border-opacity, 1));
}

.hover\:border-cool-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity, 1));
}

.hover\:border-cool-gray-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity, 1));
}

.hover\:border-cool-gray-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity, 1));
}

.hover\:border-gray-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(244 245 247 / var(--tw-border-opacity, 1));
}

.hover\:border-gray-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(210 214 220 / var(--tw-border-opacity, 1));
}

.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(159 166 178 / var(--tw-border-opacity, 1));
}

.hover\:border-gray-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-border-opacity, 1));
}

.hover\:border-gray-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
}

.hover\:border-gray-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
}

.hover\:border-gray-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}

.hover\:border-gray-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(37 47 63 / var(--tw-border-opacity, 1));
}

.hover\:border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(22 30 46 / var(--tw-border-opacity, 1));
}

.hover\:border-green-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(222 247 236 / var(--tw-border-opacity, 1));
}

.hover\:border-green-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(188 240 218 / var(--tw-border-opacity, 1));
}

.hover\:border-green-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(132 225 188 / var(--tw-border-opacity, 1));
}

.hover\:border-green-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(49 196 141 / var(--tw-border-opacity, 1));
}

.hover\:border-green-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(243 250 247 / var(--tw-border-opacity, 1));
}

.hover\:border-green-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(14 159 110 / var(--tw-border-opacity, 1));
}

.hover\:border-green-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(5 122 85 / var(--tw-border-opacity, 1));
}

.hover\:border-green-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(4 108 78 / var(--tw-border-opacity, 1));
}

.hover\:border-green-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(3 84 63 / var(--tw-border-opacity, 1));
}

.hover\:border-green-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(1 71 55 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(229 237 255 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(205 219 254 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(180 198 252 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(141 162 251 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(240 245 255 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(104 117 245 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(88 80 236 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(81 69 205 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(66 56 157 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(54 47 120 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-bright-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(224 231 255 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-bright-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(199 210 254 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-bright-200\/\[0\.3\]:hover {
  border-color: #c7d2fe4d;
}

.hover\:border-indigo-bright-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(165 180 252 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-bright-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(238 242 255 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-bright-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-bright-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(67 56 202 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-bright-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity, 1));
}

.hover\:border-indigo-bright-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(54 62 123 / var(--tw-border-opacity, 1));
}

.hover\:border-orange-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(254 236 220 / var(--tw-border-opacity, 1));
}

.hover\:border-orange-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(252 217 189 / var(--tw-border-opacity, 1));
}

.hover\:border-orange-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(253 186 140 / var(--tw-border-opacity, 1));
}

.hover\:border-orange-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 138 76 / var(--tw-border-opacity, 1));
}

.hover\:border-orange-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 248 241 / var(--tw-border-opacity, 1));
}

.hover\:border-orange-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 90 31 / var(--tw-border-opacity, 1));
}

.hover\:border-orange-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(208 56 1 / var(--tw-border-opacity, 1));
}

.hover\:border-orange-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(180 52 3 / var(--tw-border-opacity, 1));
}

.hover\:border-orange-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(138 44 13 / var(--tw-border-opacity, 1));
}

.hover\:border-orange-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(115 35 13 / var(--tw-border-opacity, 1));
}

.hover\:border-pink-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(252 232 243 / var(--tw-border-opacity, 1));
}

.hover\:border-pink-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(250 209 232 / var(--tw-border-opacity, 1));
}

.hover\:border-pink-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(248 180 217 / var(--tw-border-opacity, 1));
}

.hover\:border-pink-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(241 126 184 / var(--tw-border-opacity, 1));
}

.hover\:border-pink-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(253 242 248 / var(--tw-border-opacity, 1));
}

.hover\:border-pink-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(231 70 148 / var(--tw-border-opacity, 1));
}

.hover\:border-pink-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(214 31 105 / var(--tw-border-opacity, 1));
}

.hover\:border-pink-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(191 18 93 / var(--tw-border-opacity, 1));
}

.hover\:border-pink-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(153 21 75 / var(--tw-border-opacity, 1));
}

.hover\:border-pink-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(117 26 61 / var(--tw-border-opacity, 1));
}

.hover\:border-purple-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(237 235 254 / var(--tw-border-opacity, 1));
}

.hover\:border-purple-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(220 215 254 / var(--tw-border-opacity, 1));
}

.hover\:border-purple-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(202 191 253 / var(--tw-border-opacity, 1));
}

.hover\:border-purple-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(172 148 250 / var(--tw-border-opacity, 1));
}

.hover\:border-purple-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(246 245 255 / var(--tw-border-opacity, 1));
}

.hover\:border-purple-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(144 97 249 / var(--tw-border-opacity, 1));
}

.hover\:border-purple-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(126 58 242 / var(--tw-border-opacity, 1));
}

.hover\:border-purple-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(108 43 217 / var(--tw-border-opacity, 1));
}

.hover\:border-purple-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(85 33 181 / var(--tw-border-opacity, 1));
}

.hover\:border-purple-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(74 29 150 / var(--tw-border-opacity, 1));
}

.hover\:border-purple-bright-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
}

.hover\:border-red-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(253 232 232 / var(--tw-border-opacity, 1));
}

.hover\:border-red-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(251 213 213 / var(--tw-border-opacity, 1));
}

.hover\:border-red-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(248 180 180 / var(--tw-border-opacity, 1));
}

.hover\:border-red-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(249 128 128 / var(--tw-border-opacity, 1));
}

.hover\:border-red-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(253 242 242 / var(--tw-border-opacity, 1));
}

.hover\:border-red-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(240 82 82 / var(--tw-border-opacity, 1));
}

.hover\:border-red-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(224 36 36 / var(--tw-border-opacity, 1));
}

.hover\:border-red-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(200 30 30 / var(--tw-border-opacity, 1));
}

.hover\:border-red-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(155 28 28 / var(--tw-border-opacity, 1));
}

.hover\:border-red-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(119 29 29 / var(--tw-border-opacity, 1));
}

.hover\:border-red-warm-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity, 1));
}

.hover\:border-sky-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(224 242 254 / var(--tw-border-opacity, 1));
}

.hover\:border-sky-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity, 1));
}

.hover\:border-sky-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity, 1));
}

.hover\:border-sky-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(56 189 248 / var(--tw-border-opacity, 1));
}

.hover\:border-sky-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(240 249 255 / var(--tw-border-opacity, 1));
}

.hover\:border-sky-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity, 1));
}

.hover\:border-sky-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(2 132 199 / var(--tw-border-opacity, 1));
}

.hover\:border-sky-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(3 105 161 / var(--tw-border-opacity, 1));
}

.hover\:border-sky-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(7 89 133 / var(--tw-border-opacity, 1));
}

.hover\:border-sky-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(12 74 110 / var(--tw-border-opacity, 1));
}

.hover\:border-sky-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(8 47 73 / var(--tw-border-opacity, 1));
}

.hover\:border-teal-bright-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(153 246 228 / var(--tw-border-opacity, 1));
}

.hover\:border-teal-bright-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(13 148 136 / var(--tw-border-opacity, 1));
}

.hover\:border-tremor-brand-emphasis:hover {
  --tw-border-opacity: 1;
  border-color: rgb(30 64 175 / var(--tw-border-opacity, 1));
}

.hover\:border-tremor-content:hover {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity, 1));
}

.hover\:border-white:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.hover\:border-yellow-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(253 246 178 / var(--tw-border-opacity, 1));
}

.hover\:border-yellow-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(252 233 106 / var(--tw-border-opacity, 1));
}

.hover\:border-yellow-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(250 202 21 / var(--tw-border-opacity, 1));
}

.hover\:border-yellow-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(227 160 8 / var(--tw-border-opacity, 1));
}

.hover\:border-yellow-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(253 253 234 / var(--tw-border-opacity, 1));
}

.hover\:border-yellow-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(194 120 3 / var(--tw-border-opacity, 1));
}

.hover\:border-yellow-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(159 88 10 / var(--tw-border-opacity, 1));
}

.hover\:border-yellow-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(142 75 16 / var(--tw-border-opacity, 1));
}

.hover\:border-yellow-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(114 59 19 / var(--tw-border-opacity, 1));
}

.hover\:border-yellow-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(99 49 18 / var(--tw-border-opacity, 1));
}

.hover\:border-yellow-warm-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(251 191 36 / var(--tw-border-opacity, 1));
}

.hover\:\!bg-cool-gray-100:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:\!bg-cool-gray-50:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:bg-\[\#FFD95B\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 217 91 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#eef2ff\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#ffffff55\]:hover {
  background-color: #fff5;
}

.hover\:bg-actionSecondaryHovered:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity, 1));
}

.hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(225 239 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(195 221 253 / var(--tw-bg-opacity, 1));
}

.hover\:bg-blue-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(164 202 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-blue-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(118 169 250 / var(--tw-bg-opacity, 1));
}

.hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(235 245 255 / var(--tw-bg-opacity, 1));
}

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(63 131 248 / var(--tw-bg-opacity, 1));
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(26 86 219 / var(--tw-bg-opacity, 1));
}

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 66 159 / var(--tw-bg-opacity, 1));
}

.hover\:bg-blue-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(35 56 118 / var(--tw-bg-opacity, 1));
}

.hover\:bg-brandSecondary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 214 79 / var(--tw-bg-opacity, 1));
}

.hover\:bg-cool-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.hover\:bg-cool-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity, 1));
}

.hover\:bg-cool-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}

.hover\:bg-cool-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity, 1));
}

.hover\:bg-cool-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
}

.hover\:bg-cool-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity, 1));
}

.hover\:bg-error:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(244 245 247 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(210 214 220 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(159 166 178 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 47 63 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 30 46 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(222 247 236 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(188 240 218 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(132 225 188 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(49 196 141 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 250 247 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(14 159 110 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(5 122 85 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(4 108 78 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(3 84 63 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(1 71 55 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 237 255 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(205 219 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(180 198 252 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(141 162 251 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 245 255 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(104 117 245 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(88 80 236 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(81 69 205 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(66 56 157 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(54 47 120 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-bright-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-bright-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-bright-200\/\[0\.3\]:hover {
  background-color: #c7d2fe4d;
}

.hover\:bg-indigo-bright-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-bright-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-bright-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-bright-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-bright-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity, 1));
}

.hover\:bg-indigo-bright-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(54 62 123 / var(--tw-bg-opacity, 1));
}

.hover\:bg-orange-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 236 220 / var(--tw-bg-opacity, 1));
}

.hover\:bg-orange-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(252 217 189 / var(--tw-bg-opacity, 1));
}

.hover\:bg-orange-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(253 186 140 / var(--tw-bg-opacity, 1));
}

.hover\:bg-orange-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 138 76 / var(--tw-bg-opacity, 1));
}

.hover\:bg-orange-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 248 241 / var(--tw-bg-opacity, 1));
}

.hover\:bg-orange-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 90 31 / var(--tw-bg-opacity, 1));
}

.hover\:bg-orange-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(208 56 1 / var(--tw-bg-opacity, 1));
}

.hover\:bg-orange-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(180 52 3 / var(--tw-bg-opacity, 1));
}

.hover\:bg-orange-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(138 44 13 / var(--tw-bg-opacity, 1));
}

.hover\:bg-orange-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(115 35 13 / var(--tw-bg-opacity, 1));
}

.hover\:bg-pink-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(252 232 243 / var(--tw-bg-opacity, 1));
}

.hover\:bg-pink-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(250 209 232 / var(--tw-bg-opacity, 1));
}

.hover\:bg-pink-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 180 217 / var(--tw-bg-opacity, 1));
}

.hover\:bg-pink-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 126 184 / var(--tw-bg-opacity, 1));
}

.hover\:bg-pink-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 248 / var(--tw-bg-opacity, 1));
}

.hover\:bg-pink-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(231 70 148 / var(--tw-bg-opacity, 1));
}

.hover\:bg-pink-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(214 31 105 / var(--tw-bg-opacity, 1));
}

.hover\:bg-pink-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(191 18 93 / var(--tw-bg-opacity, 1));
}

.hover\:bg-pink-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(153 21 75 / var(--tw-bg-opacity, 1));
}

.hover\:bg-pink-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(117 26 61 / var(--tw-bg-opacity, 1));
}

.hover\:bg-progressive-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 119 179 / var(--tw-bg-opacity, 1));
}

.hover\:bg-purple-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(237 235 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-purple-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 215 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-purple-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(202 191 253 / var(--tw-bg-opacity, 1));
}

.hover\:bg-purple-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(172 148 250 / var(--tw-bg-opacity, 1));
}

.hover\:bg-purple-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(246 245 255 / var(--tw-bg-opacity, 1));
}

.hover\:bg-purple-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(144 97 249 / var(--tw-bg-opacity, 1));
}

.hover\:bg-purple-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(126 58 242 / var(--tw-bg-opacity, 1));
}

.hover\:bg-purple-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(108 43 217 / var(--tw-bg-opacity, 1));
}

.hover\:bg-purple-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(85 33 181 / var(--tw-bg-opacity, 1));
}

.hover\:bg-purple-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(74 29 150 / var(--tw-bg-opacity, 1));
}

.hover\:bg-purple-bright-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
}

.hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(253 232 232 / var(--tw-bg-opacity, 1));
}

.hover\:bg-red-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(251 213 213 / var(--tw-bg-opacity, 1));
}

.hover\:bg-red-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 180 180 / var(--tw-bg-opacity, 1));
}

.hover\:bg-red-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 128 128 / var(--tw-bg-opacity, 1));
}

.hover\:bg-red-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 242 / var(--tw-bg-opacity, 1));
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 82 82 / var(--tw-bg-opacity, 1));
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(224 36 36 / var(--tw-bg-opacity, 1));
}

.hover\:bg-red-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(200 30 30 / var(--tw-bg-opacity, 1));
}

.hover\:bg-red-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(155 28 28 / var(--tw-bg-opacity, 1));
}

.hover\:bg-red-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(119 29 29 / var(--tw-bg-opacity, 1));
}

.hover\:bg-red-warm-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity, 1));
}

.hover\:bg-sky-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-sky-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-sky-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity, 1));
}

.hover\:bg-sky-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity, 1));
}

.hover\:bg-sky-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity, 1));
}

.hover\:bg-sky-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity, 1));
}

.hover\:bg-sky-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity, 1));
}

.hover\:bg-sky-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity, 1));
}

.hover\:bg-sky-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity, 1));
}

.hover\:bg-sky-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity, 1));
}

.hover\:bg-sky-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(8 47 73 / var(--tw-bg-opacity, 1));
}

.hover\:bg-success:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity, 1));
}

.hover\:bg-teal-bright-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity, 1));
}

.hover\:bg-teal-bright-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity, 1));
}

.hover\:bg-transparent:hover {
  background-color: #0000;
}

.hover\:bg-tremor-background-muted:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}

.hover\:bg-tremor-background-subtle:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.hover\:bg-tremor-brand-emphasis:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity, 1));
}

.hover\:bg-tremor-brand-faint:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.hover\:bg-white\/50:hover {
  background-color: #ffffff80;
}

.hover\:bg-white\/\[0\.1\]:hover {
  background-color: #ffffff1a;
}

.hover\:bg-yellow-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 178 / var(--tw-bg-opacity, 1));
}

.hover\:bg-yellow-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(252 233 106 / var(--tw-bg-opacity, 1));
}

.hover\:bg-yellow-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(250 202 21 / var(--tw-bg-opacity, 1));
}

.hover\:bg-yellow-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(227 160 8 / var(--tw-bg-opacity, 1));
}

.hover\:bg-yellow-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(253 253 234 / var(--tw-bg-opacity, 1));
}

.hover\:bg-yellow-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(194 120 3 / var(--tw-bg-opacity, 1));
}

.hover\:bg-yellow-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(159 88 10 / var(--tw-bg-opacity, 1));
}

.hover\:bg-yellow-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(142 75 16 / var(--tw-bg-opacity, 1));
}

.hover\:bg-yellow-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(114 59 19 / var(--tw-bg-opacity, 1));
}

.hover\:bg-yellow-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(99 49 18 / var(--tw-bg-opacity, 1));
}

.hover\:bg-yellow-warm-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity, 1));
}

.hover\:bg-opacity-20:hover {
  --tw-bg-opacity: .2;
}

.hover\:text-\[\#4F46E5\]:hover, .hover\:text-\[\#4f46e5\]:hover {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.hover\:text-blue-100:hover {
  --tw-text-opacity: 1;
  color: rgb(225 239 254 / var(--tw-text-opacity, 1));
}

.hover\:text-blue-200:hover {
  --tw-text-opacity: 1;
  color: rgb(195 221 253 / var(--tw-text-opacity, 1));
}

.hover\:text-blue-300:hover {
  --tw-text-opacity: 1;
  color: rgb(164 202 254 / var(--tw-text-opacity, 1));
}

.hover\:text-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgb(118 169 250 / var(--tw-text-opacity, 1));
}

.hover\:text-blue-50:hover {
  --tw-text-opacity: 1;
  color: rgb(235 245 255 / var(--tw-text-opacity, 1));
}

.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity, 1));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}

.hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgb(26 86 219 / var(--tw-text-opacity, 1));
}

.hover\:text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgb(30 66 159 / var(--tw-text-opacity, 1));
}

.hover\:text-blue-900:hover {
  --tw-text-opacity: 1;
  color: rgb(35 56 118 / var(--tw-text-opacity, 1));
}

.hover\:text-cool-gray-50:hover {
  --tw-text-opacity: 1;
  color: rgb(248 250 252 / var(--tw-text-opacity, 1));
}

.hover\:text-cool-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}

.hover\:text-cool-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}

.hover\:text-cool-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgb(244 245 247 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-200:hover {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-300:hover {
  --tw-text-opacity: 1;
  color: rgb(210 214 220 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgb(159 166 178 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-50:hover {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgb(37 47 63 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(22 30 46 / var(--tw-text-opacity, 1));
}

.hover\:text-green-100:hover {
  --tw-text-opacity: 1;
  color: rgb(222 247 236 / var(--tw-text-opacity, 1));
}

.hover\:text-green-200:hover {
  --tw-text-opacity: 1;
  color: rgb(188 240 218 / var(--tw-text-opacity, 1));
}

.hover\:text-green-300:hover {
  --tw-text-opacity: 1;
  color: rgb(132 225 188 / var(--tw-text-opacity, 1));
}

.hover\:text-green-400:hover {
  --tw-text-opacity: 1;
  color: rgb(49 196 141 / var(--tw-text-opacity, 1));
}

.hover\:text-green-50:hover {
  --tw-text-opacity: 1;
  color: rgb(243 250 247 / var(--tw-text-opacity, 1));
}

.hover\:text-green-500:hover {
  --tw-text-opacity: 1;
  color: rgb(14 159 110 / var(--tw-text-opacity, 1));
}

.hover\:text-green-600:hover {
  --tw-text-opacity: 1;
  color: rgb(5 122 85 / var(--tw-text-opacity, 1));
}

.hover\:text-green-700:hover {
  --tw-text-opacity: 1;
  color: rgb(4 108 78 / var(--tw-text-opacity, 1));
}

.hover\:text-green-800:hover {
  --tw-text-opacity: 1;
  color: rgb(3 84 63 / var(--tw-text-opacity, 1));
}

.hover\:text-green-900:hover {
  --tw-text-opacity: 1;
  color: rgb(1 71 55 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-100:hover {
  --tw-text-opacity: 1;
  color: rgb(229 237 255 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-200:hover {
  --tw-text-opacity: 1;
  color: rgb(205 219 254 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-300:hover {
  --tw-text-opacity: 1;
  color: rgb(180 198 252 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-400:hover {
  --tw-text-opacity: 1;
  color: rgb(141 162 251 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-50:hover {
  --tw-text-opacity: 1;
  color: rgb(240 245 255 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgb(104 117 245 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-600:hover {
  --tw-text-opacity: 1;
  color: rgb(88 80 236 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-700:hover {
  --tw-text-opacity: 1;
  color: rgb(81 69 205 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-800:hover {
  --tw-text-opacity: 1;
  color: rgb(66 56 157 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-900:hover {
  --tw-text-opacity: 1;
  color: rgb(54 47 120 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-bright-100:hover {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-bright-200:hover {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-bright-200\/\[0\.3\]:hover {
  color: #c7d2fe4d;
}

.hover\:text-indigo-bright-300:hover {
  --tw-text-opacity: 1;
  color: rgb(165 180 252 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-bright-50:hover {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-bright-600:hover {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-bright-700:hover {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-bright-800:hover {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity, 1));
}

.hover\:text-indigo-bright-900:hover {
  --tw-text-opacity: 1;
  color: rgb(54 62 123 / var(--tw-text-opacity, 1));
}

.hover\:text-orange-100:hover {
  --tw-text-opacity: 1;
  color: rgb(254 236 220 / var(--tw-text-opacity, 1));
}

.hover\:text-orange-200:hover {
  --tw-text-opacity: 1;
  color: rgb(252 217 189 / var(--tw-text-opacity, 1));
}

.hover\:text-orange-300:hover {
  --tw-text-opacity: 1;
  color: rgb(253 186 140 / var(--tw-text-opacity, 1));
}

.hover\:text-orange-400:hover {
  --tw-text-opacity: 1;
  color: rgb(255 138 76 / var(--tw-text-opacity, 1));
}

.hover\:text-orange-50:hover {
  --tw-text-opacity: 1;
  color: rgb(255 248 241 / var(--tw-text-opacity, 1));
}

.hover\:text-orange-500:hover {
  --tw-text-opacity: 1;
  color: rgb(255 90 31 / var(--tw-text-opacity, 1));
}

.hover\:text-orange-600:hover {
  --tw-text-opacity: 1;
  color: rgb(208 56 1 / var(--tw-text-opacity, 1));
}

.hover\:text-orange-700:hover {
  --tw-text-opacity: 1;
  color: rgb(180 52 3 / var(--tw-text-opacity, 1));
}

.hover\:text-orange-800:hover {
  --tw-text-opacity: 1;
  color: rgb(138 44 13 / var(--tw-text-opacity, 1));
}

.hover\:text-orange-900:hover {
  --tw-text-opacity: 1;
  color: rgb(115 35 13 / var(--tw-text-opacity, 1));
}

.hover\:text-pink-100:hover {
  --tw-text-opacity: 1;
  color: rgb(252 232 243 / var(--tw-text-opacity, 1));
}

.hover\:text-pink-200:hover {
  --tw-text-opacity: 1;
  color: rgb(250 209 232 / var(--tw-text-opacity, 1));
}

.hover\:text-pink-300:hover {
  --tw-text-opacity: 1;
  color: rgb(248 180 217 / var(--tw-text-opacity, 1));
}

.hover\:text-pink-400:hover {
  --tw-text-opacity: 1;
  color: rgb(241 126 184 / var(--tw-text-opacity, 1));
}

.hover\:text-pink-50:hover {
  --tw-text-opacity: 1;
  color: rgb(253 242 248 / var(--tw-text-opacity, 1));
}

.hover\:text-pink-500:hover {
  --tw-text-opacity: 1;
  color: rgb(231 70 148 / var(--tw-text-opacity, 1));
}

.hover\:text-pink-600:hover {
  --tw-text-opacity: 1;
  color: rgb(214 31 105 / var(--tw-text-opacity, 1));
}

.hover\:text-pink-700:hover {
  --tw-text-opacity: 1;
  color: rgb(191 18 93 / var(--tw-text-opacity, 1));
}

.hover\:text-pink-800:hover {
  --tw-text-opacity: 1;
  color: rgb(153 21 75 / var(--tw-text-opacity, 1));
}

.hover\:text-pink-900:hover {
  --tw-text-opacity: 1;
  color: rgb(117 26 61 / var(--tw-text-opacity, 1));
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.hover\:text-purple-100:hover {
  --tw-text-opacity: 1;
  color: rgb(237 235 254 / var(--tw-text-opacity, 1));
}

.hover\:text-purple-200:hover {
  --tw-text-opacity: 1;
  color: rgb(220 215 254 / var(--tw-text-opacity, 1));
}

.hover\:text-purple-300:hover {
  --tw-text-opacity: 1;
  color: rgb(202 191 253 / var(--tw-text-opacity, 1));
}

.hover\:text-purple-400:hover {
  --tw-text-opacity: 1;
  color: rgb(172 148 250 / var(--tw-text-opacity, 1));
}

.hover\:text-purple-50:hover {
  --tw-text-opacity: 1;
  color: rgb(246 245 255 / var(--tw-text-opacity, 1));
}

.hover\:text-purple-500:hover {
  --tw-text-opacity: 1;
  color: rgb(144 97 249 / var(--tw-text-opacity, 1));
}

.hover\:text-purple-600:hover {
  --tw-text-opacity: 1;
  color: rgb(126 58 242 / var(--tw-text-opacity, 1));
}

.hover\:text-purple-700:hover {
  --tw-text-opacity: 1;
  color: rgb(108 43 217 / var(--tw-text-opacity, 1));
}

.hover\:text-purple-800:hover {
  --tw-text-opacity: 1;
  color: rgb(85 33 181 / var(--tw-text-opacity, 1));
}

.hover\:text-purple-900:hover {
  --tw-text-opacity: 1;
  color: rgb(74 29 150 / var(--tw-text-opacity, 1));
}

.hover\:text-purple-bright-500:hover {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}

.hover\:text-red-100:hover {
  --tw-text-opacity: 1;
  color: rgb(253 232 232 / var(--tw-text-opacity, 1));
}

.hover\:text-red-200:hover {
  --tw-text-opacity: 1;
  color: rgb(251 213 213 / var(--tw-text-opacity, 1));
}

.hover\:text-red-300:hover {
  --tw-text-opacity: 1;
  color: rgb(248 180 180 / var(--tw-text-opacity, 1));
}

.hover\:text-red-400:hover {
  --tw-text-opacity: 1;
  color: rgb(249 128 128 / var(--tw-text-opacity, 1));
}

.hover\:text-red-50:hover {
  --tw-text-opacity: 1;
  color: rgb(253 242 242 / var(--tw-text-opacity, 1));
}

.hover\:text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgb(240 82 82 / var(--tw-text-opacity, 1));
}

.hover\:text-red-600:hover {
  --tw-text-opacity: 1;
  color: rgb(224 36 36 / var(--tw-text-opacity, 1));
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgb(200 30 30 / var(--tw-text-opacity, 1));
}

.hover\:text-red-800:hover {
  --tw-text-opacity: 1;
  color: rgb(155 28 28 / var(--tw-text-opacity, 1));
}

.hover\:text-red-900:hover {
  --tw-text-opacity: 1;
  color: rgb(119 29 29 / var(--tw-text-opacity, 1));
}

.hover\:text-red-warm-400:hover {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}

.hover\:text-sky-100:hover {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity, 1));
}

.hover\:text-sky-200:hover {
  --tw-text-opacity: 1;
  color: rgb(191 219 254 / var(--tw-text-opacity, 1));
}

.hover\:text-sky-300:hover {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity, 1));
}

.hover\:text-sky-400:hover {
  --tw-text-opacity: 1;
  color: rgb(56 189 248 / var(--tw-text-opacity, 1));
}

.hover\:text-sky-50:hover {
  --tw-text-opacity: 1;
  color: rgb(240 249 255 / var(--tw-text-opacity, 1));
}

.hover\:text-sky-500:hover {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity, 1));
}

.hover\:text-sky-600:hover {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity, 1));
}

.hover\:text-sky-700:hover {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity, 1));
}

.hover\:text-sky-800:hover {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity, 1));
}

.hover\:text-sky-900:hover {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity, 1));
}

.hover\:text-sky-950:hover {
  --tw-text-opacity: 1;
  color: rgb(8 47 73 / var(--tw-text-opacity, 1));
}

.hover\:text-teal-bright-200:hover {
  --tw-text-opacity: 1;
  color: rgb(153 246 228 / var(--tw-text-opacity, 1));
}

.hover\:text-teal-bright-600:hover {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity, 1));
}

.hover\:text-tremor-brand-emphasis:hover {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity, 1));
}

.hover\:text-tremor-content:hover {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}

.hover\:text-tremor-content-emphasis:hover {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.hover\:text-yellow-100:hover {
  --tw-text-opacity: 1;
  color: rgb(253 246 178 / var(--tw-text-opacity, 1));
}

.hover\:text-yellow-200:hover {
  --tw-text-opacity: 1;
  color: rgb(252 233 106 / var(--tw-text-opacity, 1));
}

.hover\:text-yellow-300:hover {
  --tw-text-opacity: 1;
  color: rgb(250 202 21 / var(--tw-text-opacity, 1));
}

.hover\:text-yellow-400:hover {
  --tw-text-opacity: 1;
  color: rgb(227 160 8 / var(--tw-text-opacity, 1));
}

.hover\:text-yellow-50:hover {
  --tw-text-opacity: 1;
  color: rgb(253 253 234 / var(--tw-text-opacity, 1));
}

.hover\:text-yellow-500:hover {
  --tw-text-opacity: 1;
  color: rgb(194 120 3 / var(--tw-text-opacity, 1));
}

.hover\:text-yellow-600:hover {
  --tw-text-opacity: 1;
  color: rgb(159 88 10 / var(--tw-text-opacity, 1));
}

.hover\:text-yellow-700:hover {
  --tw-text-opacity: 1;
  color: rgb(142 75 16 / var(--tw-text-opacity, 1));
}

.hover\:text-yellow-800:hover {
  --tw-text-opacity: 1;
  color: rgb(114 59 19 / var(--tw-text-opacity, 1));
}

.hover\:text-yellow-900:hover {
  --tw-text-opacity: 1;
  color: rgb(99 49 18 / var(--tw-text-opacity, 1));
}

.hover\:text-yellow-warm-400:hover {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity, 1));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:opacity-75:hover {
  opacity: .75;
}

.hover\:\!shadow-lightindigo:hover {
  --tw-shadow: 0px 0px 2px 2px #4f46e526 !important;
  --tw-shadow-colored: 0px 0px 2px 2px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:shadow:hover {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-\[0_0_0_1px_rgba\(159\,166\,178\,1\)\]:hover {
  --tw-shadow: 0 0 0 1px #9fa6b2;
  --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-\[0_0_0_2px_rgba\(80\,80\,236\,1\)\]:hover {
  --tw-shadow: 0 0 0 2px #5050ec;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lightindigo:hover {
  --tw-shadow: 0px 0px 2px 2px #4f46e526;
  --tw-shadow-colored: 0px 0px 2px 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-outline:hover {
  --tw-shadow: 0 0 0 3px #76a9fa73;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-outline-blue:hover {
  --tw-shadow: 0 0 0 3px #bfdbfe80;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:outline:hover {
  outline-style: solid;
}

.hover\:outline-indigo-bright-200:hover {
  outline-color: #c7d2fe;
}

.focus\:not-sr-only:focus {
  clip: auto;
  white-space: normal;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  position: static;
  overflow: visible;
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:border:focus {
  border-width: 1px;
}

.focus\:\!border-transparent:focus {
  border-color: #0000 !important;
}

.focus\:border-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgb(164 202 254 / var(--tw-border-opacity, 1));
}

.focus\:border-blue-400:focus {
  --tw-border-opacity: 1;
  border-color: rgb(118 169 250 / var(--tw-border-opacity, 1));
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(63 131 248 / var(--tw-border-opacity, 1));
}

.focus\:border-blue-600:focus {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity, 1));
}

.focus\:border-cool-gray-400:focus {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity, 1));
}

.focus\:border-gray-300:focus {
  --tw-border-opacity: 1;
  border-color: rgb(210 214 220 / var(--tw-border-opacity, 1));
}

.focus\:border-gray-400:focus {
  --tw-border-opacity: 1;
  border-color: rgb(159 166 178 / var(--tw-border-opacity, 1));
}

.focus\:border-gray-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}

.focus\:border-green-600:focus {
  --tw-border-opacity: 1;
  border-color: rgb(5 122 85 / var(--tw-border-opacity, 1));
}

.focus\:border-indigo-300:focus {
  --tw-border-opacity: 1;
  border-color: rgb(180 198 252 / var(--tw-border-opacity, 1));
}

.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(104 117 245 / var(--tw-border-opacity, 1));
}

.focus\:border-indigo-600:focus {
  --tw-border-opacity: 1;
  border-color: rgb(88 80 236 / var(--tw-border-opacity, 1));
}

.focus\:border-indigo-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(81 69 205 / var(--tw-border-opacity, 1));
}

.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(240 82 82 / var(--tw-border-opacity, 1));
}

.focus\:border-red-600:focus {
  --tw-border-opacity: 1;
  border-color: rgb(224 36 36 / var(--tw-border-opacity, 1));
}

.focus\:border-red-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(200 30 30 / var(--tw-border-opacity, 1));
}

.focus\:border-tremor-brand-subtle:focus {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity, 1));
}

.focus\:border-white:focus {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.focus\:border-yellow-400:focus {
  --tw-border-opacity: 1;
  border-color: rgb(227 160 8 / var(--tw-border-opacity, 1));
}

.focus\:bg-cool-gray-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity, 1));
}

.focus\:bg-cool-gray-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity, 1));
}

.focus\:bg-gray-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(244 245 247 / var(--tw-bg-opacity, 1));
}

.focus\:bg-gray-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.focus\:bg-gray-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(210 214 220 / var(--tw-bg-opacity, 1));
}

.focus\:bg-gray-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.focus\:bg-indigo-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(240 245 255 / var(--tw-bg-opacity, 1));
}

.focus\:bg-indigo-bright-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity, 1));
}

.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.focus\:font-bold:focus {
  font-weight: 700;
}

.focus\:text-cool-gray-700:focus {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}

.focus\:text-cool-gray-800:focus {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity, 1));
}

.focus\:text-gray-700:focus {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.focus\:text-gray-900:focus {
  --tw-text-opacity: 1;
  color: rgb(22 30 46 / var(--tw-text-opacity, 1));
}

.focus\:text-indigo-800:focus {
  --tw-text-opacity: 1;
  color: rgb(66 56 157 / var(--tw-text-opacity, 1));
}

.focus\:text-indigo-bright-600:focus {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}

.focus\:placeholder-gray-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(159 166 178 / var(--tw-placeholder-opacity, 1));
}

.focus\:shadow-\[0px_0px_2px_2px_rgba\(79\,70\,229\,0\.15\)\]:focus {
  --tw-shadow: 0px 0px 2px 2px #4f46e526;
  --tw-shadow-colored: 0px 0px 2px 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-outline:focus {
  --tw-shadow: 0 0 0 3px #76a9fa73;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-outline-blue:focus {
  --tw-shadow: 0 0 0 3px #bfdbfe80;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-outline-gray:focus {
  --tw-shadow: 0 0 0 3px #fecaca80;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-outline-green:focus {
  --tw-shadow: 0 0 0 3px #a7f3d080;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-outline-indigo:focus {
  --tw-shadow: 0 0 0 3px #c7d2fe80;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-outline-indigo-bright-inset:focus {
  --tw-shadow: inset 0 0 0 3px #c7d2fe;
  --tw-shadow-colored: inset 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-outline-red:focus {
  --tw-shadow: 0 0 0 3px #fecaca80;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-outline-yellow:focus {
  --tw-shadow: 0 0 0 3px #fde68a80;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:outline:focus {
  outline-style: solid;
}

.focus\:outline-0:focus {
  outline-width: 0;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-blue-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(195 221 253 / var(--tw-ring-opacity, 1));
}

.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(63 131 248 / var(--tw-ring-opacity, 1));
}

.focus\:ring-cool-gray-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(241 245 249 / var(--tw-ring-opacity, 1));
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(104 117 245 / var(--tw-ring-opacity, 1));
}

.focus\:ring-tremor-brand-muted:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity, 1));
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity, 1));
}

.focus\:ring-opacity-50:focus {
  --tw-ring-opacity: .5;
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:border-blue-800:focus-visible {
  --tw-border-opacity: 1;
  border-color: rgb(30 66 159 / var(--tw-border-opacity, 1));
}

.focus-visible\:border-indigo-500:focus-visible {
  --tw-border-opacity: 1;
  border-color: rgb(104 117 245 / var(--tw-border-opacity, 1));
}

.focus-visible\:border-indigo-600:focus-visible {
  --tw-border-opacity: 1;
  border-color: rgb(88 80 236 / var(--tw-border-opacity, 1));
}

.focus-visible\:border-b-blue-500:focus-visible {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(63 131 248 / var(--tw-border-opacity, 1));
}

.focus-visible\:border-l-blue-500:focus-visible {
  --tw-border-opacity: 1;
  border-left-color: rgb(63 131 248 / var(--tw-border-opacity, 1));
}

.focus-visible\:bg-cool-gray-50:focus-visible {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}

.focus-visible\:bg-gray-50:focus-visible {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.focus-visible\:bg-indigo-700:focus-visible {
  --tw-bg-opacity: 1;
  background-color: rgb(81 69 205 / var(--tw-bg-opacity, 1));
}

.focus-visible\:text-blue-500:focus-visible {
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity, 1));
}

.focus-visible\:text-indigo-700:focus-visible {
  --tw-text-opacity: 1;
  color: rgb(81 69 205 / var(--tw-text-opacity, 1));
}

.focus-visible\:shadow-none:focus-visible {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-visible\:shadow-outline-blue:focus-visible {
  --tw-shadow: 0 0 0 3px #bfdbfe80;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-visible\:shadow-outline-indigo:focus-visible {
  --tw-shadow: 0 0 0 3px #c7d2fe80;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus-visible\:outline-none:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.focus-visible\:outline-0:focus-visible {
  outline-width: 0;
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-\[3px\]:focus-visible {
  outline-width: 3px;
}

.focus-visible\:outline-indigo-bright-200:focus-visible {
  outline-color: #c7d2fe;
}

.focus-visible\:ring-1:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-blue-500:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(63 131 248 / var(--tw-ring-opacity, 1));
}

.active\:z-10:active {
  z-index: 10;
}

.active\:border-b-indigo-600:active {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(88 80 236 / var(--tw-border-opacity, 1));
}

.active\:bg-\[\#F3F9FC\]:active {
  --tw-bg-opacity: 1;
  background-color: rgb(243 249 252 / var(--tw-bg-opacity, 1));
}

.active\:bg-blue-50:active {
  --tw-bg-opacity: 1;
  background-color: rgb(235 245 255 / var(--tw-bg-opacity, 1));
}

.active\:bg-cool-gray-100:active {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.active\:bg-gray-100:active {
  --tw-bg-opacity: 1;
  background-color: rgb(244 245 247 / var(--tw-bg-opacity, 1));
}

.active\:bg-gray-300:active {
  --tw-bg-opacity: 1;
  background-color: rgb(210 214 220 / var(--tw-bg-opacity, 1));
}

.active\:bg-gray-50:active {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.active\:bg-indigo-200:active {
  --tw-bg-opacity: 1;
  background-color: rgb(205 219 254 / var(--tw-bg-opacity, 1));
}

.active\:bg-indigo-bright-50:active {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity, 1));
}

.active\:text-cool-gray-700:active {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}

.active\:text-gray-100:active {
  --tw-text-opacity: 1;
  color: rgb(244 245 247 / var(--tw-text-opacity, 1));
}

.active\:text-gray-700:active {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.active\:text-gray-800:active {
  --tw-text-opacity: 1;
  color: rgb(37 47 63 / var(--tw-text-opacity, 1));
}

.active\:text-indigo-600:active {
  --tw-text-opacity: 1;
  color: rgb(88 80 236 / var(--tw-text-opacity, 1));
}

.active\:outline:active {
  outline-style: solid;
}

.hover\:enabled\:bg-white\/50:enabled:hover {
  background-color: #ffffff80;
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:cursor-default:disabled {
  cursor: default;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:border-blue-50:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(235 245 255 / var(--tw-border-opacity, 1));
}

.disabled\:bg-cool-gray-100:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.disabled\:bg-cool-gray-50:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}

.disabled\:bg-cool-gray-700:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity, 1));
}

.disabled\:bg-gray-200:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.disabled\:bg-gray-400:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(159 166 178 / var(--tw-bg-opacity, 1));
}

.disabled\:bg-gray-50:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.disabled\:bg-indigo-50:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(240 245 255 / var(--tw-bg-opacity, 1));
}

.disabled\:bg-transparent:disabled {
  background-color: #0000;
}

.disabled\:bg-none:disabled {
  background-image: none;
}

.disabled\:text-cool-gray-300:disabled {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity, 1));
}

.disabled\:text-cool-gray-400:disabled {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity, 1));
}

.disabled\:text-cool-gray-500:disabled {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}

.disabled\:text-gray-400:disabled {
  --tw-text-opacity: 1;
  color: rgb(159 166 178 / var(--tw-text-opacity, 1));
}

.disabled\:text-gray-500:disabled {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.disabled\:opacity-0:disabled {
  opacity: 0;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.disabled\:hover\:bg-gray-400:hover:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(159 166 178 / var(--tw-bg-opacity, 1));
}

.disabled\:hover\:bg-transparent:hover:disabled {
  background-color: #0000;
}

.disabled\:hover\:shadow-sm:hover:disabled {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}

.group:hover .group-hover\:bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(210 214 220 / var(--tw-bg-opacity, 1));
}

.group:hover .group-hover\:bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.group:hover .group-hover\:bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}

.group:hover .group-hover\:bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(88 80 236 / var(--tw-bg-opacity, 1));
}

.group:hover .group-hover\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 66 159 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(104 117 245 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgb(66 56 157 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:text-primary {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:text-tremor-content-emphasis {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:ring-gray-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(244 245 247 / var(--tw-ring-opacity, 1));
}

.group:focus .group-focus\:text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.group:active .group-active\:scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 131 248 / var(--tw-bg-opacity, 1));
}

.peer:checked ~ .peer-checked\:bg-indigo-bright-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity, 1));
}

.peer:checked ~ .peer-checked\:after\:left-\[calc\(100\%-1\.25rem-2px\)\]:after {
  content: var(--tw-content);
  left: calc(100% - 1.25rem - 2px);
}

.peer:checked ~ .peer-checked\:after\:left-\[calc\(100\%-1\.35rem-2px\)\]:after {
  content: var(--tw-content);
  left: calc(100% - 1.35rem - 2px);
}

.peer:checked ~ .peer-checked\:after\:left-\[calc\(100\%-1rem-2px\)\]:after {
  content: var(--tw-content);
  left: calc(100% - 1rem - 2px);
}

.peer:checked ~ .peer-checked\:after\:translate-x-\[calc\(100\%-4px\)\]:after {
  content: var(--tw-content);
  --tw-translate-x: calc(100% - 4px);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:after\:border-white:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.peer:focus ~ .peer-focus\:outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.has-\[button\:focus-visible\]\:ring-1:has(button:focus-visible) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.aria-selected\:\!bg-tremor-background-subtle[aria-selected="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1)) !important;
}

.aria-selected\:bg-tremor-background-emphasis[aria-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity, 1));
}

.aria-selected\:\!text-tremor-content[aria-selected="true"] {
  --tw-text-opacity: 1 !important;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1)) !important;
}

.aria-selected\:text-tremor-brand-inverted[aria-selected="true"], .aria-selected\:text-tremor-content-inverted[aria-selected="true"] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.ui-selected\:border-b-2[data-headlessui-state~="selected"] {
  border-bottom-width: 2px;
}

.ui-selected\:border-blue-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(225 239 254 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-blue-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(195 221 253 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-blue-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(164 202 254 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-blue-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(118 169 250 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-blue-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(235 245 255 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-blue-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(63 131 248 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-blue-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-blue-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(26 86 219 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-blue-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(30 66 159 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-blue-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(35 56 118 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-cool-gray-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-gray-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(244 245 247 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-gray-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-gray-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(210 214 220 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-gray-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(159 166 178 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-gray-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-gray-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-gray-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-gray-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-gray-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(37 47 63 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-gray-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(22 30 46 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-green-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(222 247 236 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-green-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(188 240 218 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-green-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(132 225 188 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-green-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(49 196 141 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-green-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(243 250 247 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-green-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(14 159 110 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-green-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(5 122 85 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-green-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(4 108 78 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-green-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(3 84 63 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-green-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(1 71 55 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(229 237 255 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(205 219 254 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(180 198 252 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(141 162 251 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(240 245 255 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(104 117 245 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(88 80 236 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(81 69 205 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(66 56 157 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(54 47 120 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-bright-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(224 231 255 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-bright-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(199 210 254 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-bright-200\/\[0\.3\][data-headlessui-state~="selected"] {
  border-color: #c7d2fe4d;
}

.ui-selected\:border-indigo-bright-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(165 180 252 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-bright-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(238 242 255 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-bright-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-bright-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(67 56 202 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-bright-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-indigo-bright-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(54 62 123 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-orange-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(254 236 220 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-orange-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(252 217 189 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-orange-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(253 186 140 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-orange-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(255 138 76 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-orange-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(255 248 241 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-orange-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(255 90 31 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-orange-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(208 56 1 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-orange-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(180 52 3 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-orange-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(138 44 13 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-orange-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(115 35 13 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-pink-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(252 232 243 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-pink-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(250 209 232 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-pink-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(248 180 217 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-pink-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(241 126 184 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-pink-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(253 242 248 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-pink-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(231 70 148 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-pink-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(214 31 105 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-pink-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(191 18 93 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-pink-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(153 21 75 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-pink-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(117 26 61 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-purple-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(237 235 254 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-purple-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(220 215 254 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-purple-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(202 191 253 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-purple-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(172 148 250 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-purple-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(246 245 255 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-purple-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(144 97 249 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-purple-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(126 58 242 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-purple-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(108 43 217 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-purple-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(85 33 181 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-purple-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(74 29 150 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-purple-bright-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-red-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(253 232 232 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-red-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(251 213 213 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-red-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(248 180 180 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-red-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(249 128 128 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-red-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(253 242 242 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-red-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(240 82 82 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-red-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(224 36 36 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-red-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(200 30 30 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-red-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(155 28 28 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-red-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(119 29 29 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-red-warm-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-sky-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(224 242 254 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-sky-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-sky-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-sky-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(56 189 248 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-sky-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(240 249 255 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-sky-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-sky-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(2 132 199 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-sky-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(3 105 161 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-sky-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(7 89 133 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-sky-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(12 74 110 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-sky-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(8 47 73 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-teal-bright-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(153 246 228 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-teal-bright-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(13 148 136 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-tremor-border[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-tremor-brand[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(36 128 254 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-yellow-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(253 246 178 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-yellow-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(252 233 106 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-yellow-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(250 202 21 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-yellow-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(227 160 8 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-yellow-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(253 253 234 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-yellow-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(194 120 3 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-yellow-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(159 88 10 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-yellow-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(142 75 16 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-yellow-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(114 59 19 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-yellow-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(99 49 18 / var(--tw-border-opacity, 1));
}

.ui-selected\:border-yellow-warm-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(251 191 36 / var(--tw-border-opacity, 1));
}

.ui-selected\:bg-blue-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(225 239 254 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-blue-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(195 221 253 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-blue-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(164 202 254 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-blue-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(118 169 250 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-blue-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(235 245 255 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-blue-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(63 131 248 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-blue-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-blue-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(26 86 219 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-blue-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(30 66 159 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-blue-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(35 56 118 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-cool-gray-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-gray-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 245 247 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-gray-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-gray-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(210 214 220 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-gray-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(159 166 178 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-gray-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-gray-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-gray-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-gray-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-gray-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(37 47 63 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-gray-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(22 30 46 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-green-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(222 247 236 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-green-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(188 240 218 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-green-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(132 225 188 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-green-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(49 196 141 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-green-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 250 247 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-green-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(14 159 110 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-green-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(5 122 85 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-green-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(4 108 78 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-green-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(3 84 63 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-green-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(1 71 55 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 237 255 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(205 219 254 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(180 198 252 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(141 162 251 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(240 245 255 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(104 117 245 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(88 80 236 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(81 69 205 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(66 56 157 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(54 47 120 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-bright-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-bright-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-bright-200\/\[0\.3\][data-headlessui-state~="selected"] {
  background-color: #c7d2fe4d;
}

.ui-selected\:bg-indigo-bright-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-bright-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-bright-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-bright-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-bright-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-indigo-bright-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(54 62 123 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-orange-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(254 236 220 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-orange-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(252 217 189 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-orange-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 186 140 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-orange-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 138 76 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-orange-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 248 241 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-orange-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 90 31 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-orange-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(208 56 1 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-orange-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(180 52 3 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-orange-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(138 44 13 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-orange-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(115 35 13 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-pink-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(252 232 243 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-pink-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 209 232 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-pink-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 180 217 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-pink-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 126 184 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-pink-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 248 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-pink-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(231 70 148 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-pink-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(214 31 105 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-pink-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(191 18 93 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-pink-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(153 21 75 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-pink-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(117 26 61 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-purple-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 235 254 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-purple-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(220 215 254 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-purple-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(202 191 253 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-purple-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(172 148 250 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-purple-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 245 255 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-purple-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(144 97 249 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-purple-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(126 58 242 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-purple-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(108 43 217 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-purple-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(85 33 181 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-purple-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(74 29 150 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-purple-bright-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-red-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 232 232 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-red-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(251 213 213 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-red-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 180 180 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-red-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 128 128 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-red-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 242 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-red-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(240 82 82 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-red-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(224 36 36 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-red-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(200 30 30 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-red-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(155 28 28 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-red-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(119 29 29 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-red-warm-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-sky-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-sky-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-sky-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-sky-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-sky-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-sky-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-sky-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-sky-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-sky-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-sky-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-sky-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(8 47 73 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-teal-bright-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-teal-bright-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-tremor-background[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-tremor-background-muted[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-yellow-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 178 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-yellow-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(252 233 106 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-yellow-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 202 21 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-yellow-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(227 160 8 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-yellow-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 253 234 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-yellow-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(194 120 3 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-yellow-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(159 88 10 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-yellow-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(142 75 16 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-yellow-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(114 59 19 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-yellow-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(99 49 18 / var(--tw-bg-opacity, 1));
}

.ui-selected\:bg-yellow-warm-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity, 1));
}

.ui-selected\:text-blue-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(225 239 254 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-blue-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(195 221 253 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-blue-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(164 202 254 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-blue-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(118 169 250 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-blue-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(235 245 255 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-blue-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-blue-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-blue-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(26 86 219 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-blue-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(30 66 159 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-blue-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(35 56 118 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-cool-gray-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-gray-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(244 245 247 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-gray-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-gray-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(210 214 220 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-gray-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(159 166 178 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-gray-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-gray-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-gray-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-gray-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-gray-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(37 47 63 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-gray-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(22 30 46 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-green-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(222 247 236 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-green-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(188 240 218 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-green-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(132 225 188 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-green-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(49 196 141 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-green-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(243 250 247 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-green-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(14 159 110 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-green-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(5 122 85 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-green-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(4 108 78 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-green-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(3 84 63 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-green-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(1 71 55 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(229 237 255 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(205 219 254 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(180 198 252 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(141 162 251 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(240 245 255 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(104 117 245 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(88 80 236 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(81 69 205 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(66 56 157 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(54 47 120 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-bright-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-bright-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-bright-200\/\[0\.3\][data-headlessui-state~="selected"] {
  color: #c7d2fe4d;
}

.ui-selected\:text-indigo-bright-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(165 180 252 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-bright-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-bright-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-bright-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-bright-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-indigo-bright-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(54 62 123 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-orange-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(254 236 220 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-orange-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(252 217 189 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-orange-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(253 186 140 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-orange-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(255 138 76 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-orange-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(255 248 241 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-orange-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(255 90 31 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-orange-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(208 56 1 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-orange-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(180 52 3 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-orange-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(138 44 13 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-orange-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(115 35 13 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-pink-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(252 232 243 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-pink-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(250 209 232 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-pink-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(248 180 217 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-pink-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(241 126 184 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-pink-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(253 242 248 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-pink-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(231 70 148 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-pink-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(214 31 105 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-pink-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(191 18 93 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-pink-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(153 21 75 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-pink-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(117 26 61 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-purple-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(237 235 254 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-purple-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(220 215 254 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-purple-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(202 191 253 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-purple-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(172 148 250 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-purple-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(246 245 255 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-purple-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(144 97 249 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-purple-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(126 58 242 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-purple-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(108 43 217 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-purple-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(85 33 181 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-purple-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(74 29 150 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-purple-bright-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-red-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(253 232 232 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-red-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(251 213 213 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-red-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(248 180 180 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-red-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(249 128 128 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-red-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(253 242 242 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-red-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(240 82 82 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-red-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(224 36 36 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-red-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(200 30 30 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-red-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(155 28 28 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-red-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(119 29 29 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-red-warm-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-sky-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-sky-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(191 219 254 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-sky-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-sky-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(56 189 248 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-sky-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(240 249 255 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-sky-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-sky-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-sky-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-sky-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-sky-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-sky-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(8 47 73 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-teal-bright-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(153 246 228 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-teal-bright-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-tremor-brand[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(36 128 254 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-tremor-content-emphasis[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-tremor-content-strong[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-yellow-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(253 246 178 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-yellow-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(252 233 106 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-yellow-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(250 202 21 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-yellow-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(227 160 8 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-yellow-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(253 253 234 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-yellow-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(194 120 3 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-yellow-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(159 88 10 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-yellow-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(142 75 16 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-yellow-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(114 59 19 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-yellow-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(99 49 18 / var(--tw-text-opacity, 1));
}

.ui-selected\:text-yellow-warm-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity, 1));
}

.ui-selected\:shadow-tremor-input[data-headlessui-state~="selected"] {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-b-2 {
  border-bottom-width: 2px;
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgb(225 239 254 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgb(195 221 253 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgb(164 202 254 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgb(118 169 250 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgb(235 245 255 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(63 131 248 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(26 86 219 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 66 159 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgb(35 56 118 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-cool-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(244 245 247 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(210 214 220 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(159 166 178 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(37 47 63 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(22 30 46 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgb(222 247 236 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgb(188 240 218 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgb(132 225 188 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgb(49 196 141 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgb(243 250 247 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(14 159 110 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgb(5 122 85 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgb(4 108 78 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgb(3 84 63 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgb(1 71 55 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgb(229 237 255 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgb(205 219 254 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgb(180 198 252 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgb(141 162 251 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 245 255 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(104 117 245 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgb(88 80 236 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgb(81 69 205 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgb(66 56 157 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgb(54 47 120 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-bright-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 231 255 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-bright-200 {
  --tw-border-opacity: 1;
  border-color: rgb(199 210 254 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-bright-200\/\[0\.3\] {
  border-color: #c7d2fe4d;
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-bright-300 {
  --tw-border-opacity: 1;
  border-color: rgb(165 180 252 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-bright-50 {
  --tw-border-opacity: 1;
  border-color: rgb(238 242 255 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-bright-600 {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-bright-700 {
  --tw-border-opacity: 1;
  border-color: rgb(67 56 202 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-bright-800 {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-bright-900 {
  --tw-border-opacity: 1;
  border-color: rgb(54 62 123 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-100 {
  --tw-border-opacity: 1;
  border-color: rgb(254 236 220 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-200 {
  --tw-border-opacity: 1;
  border-color: rgb(252 217 189 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 186 140 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgb(255 138 76 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 248 241 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgb(255 90 31 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-600 {
  --tw-border-opacity: 1;
  border-color: rgb(208 56 1 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-700 {
  --tw-border-opacity: 1;
  border-color: rgb(180 52 3 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-800 {
  --tw-border-opacity: 1;
  border-color: rgb(138 44 13 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-900 {
  --tw-border-opacity: 1;
  border-color: rgb(115 35 13 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgb(252 232 243 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgb(250 209 232 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgb(248 180 217 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgb(241 126 184 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgb(253 242 248 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgb(231 70 148 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgb(214 31 105 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgb(191 18 93 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgb(153 21 75 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgb(117 26 61 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgb(237 235 254 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(220 215 254 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgb(202 191 253 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgb(172 148 250 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgb(246 245 255 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(144 97 249 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgb(126 58 242 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgb(108 43 217 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgb(85 33 181 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgb(74 29 150 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-bright-500 {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-100 {
  --tw-border-opacity: 1;
  border-color: rgb(253 232 232 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgb(251 213 213 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgb(248 180 180 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(249 128 128 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-50 {
  --tw-border-opacity: 1;
  border-color: rgb(253 242 242 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(240 82 82 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgb(224 36 36 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgb(200 30 30 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgb(155 28 28 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-900 {
  --tw-border-opacity: 1;
  border-color: rgb(119 29 29 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-warm-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 242 254 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-200 {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-300 {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-400 {
  --tw-border-opacity: 1;
  border-color: rgb(56 189 248 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 249 255 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-500 {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-600 {
  --tw-border-opacity: 1;
  border-color: rgb(2 132 199 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-700 {
  --tw-border-opacity: 1;
  border-color: rgb(3 105 161 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-800 {
  --tw-border-opacity: 1;
  border-color: rgb(7 89 133 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-900 {
  --tw-border-opacity: 1;
  border-color: rgb(12 74 110 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-950 {
  --tw-border-opacity: 1;
  border-color: rgb(8 47 73 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-bright-200 {
  --tw-border-opacity: 1;
  border-color: rgb(153 246 228 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-bright-600 {
  --tw-border-opacity: 1;
  border-color: rgb(13 148 136 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-tremor-border {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-tremor-brand {
  --tw-border-opacity: 1;
  border-color: rgb(36 128 254 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgb(253 246 178 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgb(252 233 106 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgb(250 202 21 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgb(227 160 8 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-50 {
  --tw-border-opacity: 1;
  border-color: rgb(253 253 234 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(194 120 3 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgb(159 88 10 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-700 {
  --tw-border-opacity: 1;
  border-color: rgb(142 75 16 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgb(114 59 19 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-900 {
  --tw-border-opacity: 1;
  border-color: rgb(99 49 18 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-warm-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 191 36 / var(--tw-border-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(225 239 254 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(195 221 253 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(164 202 254 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(118 169 250 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(235 245 255 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 131 248 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 86 219 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 66 159 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(35 56 118 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cool-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 245 247 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(210 214 220 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(159 166 178 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 47 63 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 30 46 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(222 247 236 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(188 240 218 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(132 225 188 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(49 196 141 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 250 247 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 159 110 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 122 85 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 108 78 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 84 63 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(1 71 55 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 237 255 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(205 219 254 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(180 198 252 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(141 162 251 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 245 255 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(104 117 245 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(88 80 236 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(81 69 205 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(66 56 157 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(54 47 120 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-bright-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-bright-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-bright-200\/\[0\.3\] {
  background-color: #c7d2fe4d;
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-bright-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-bright-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-bright-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-bright-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-bright-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-bright-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(54 62 123 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 236 220 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 217 189 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 186 140 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 138 76 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 248 241 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 90 31 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(208 56 1 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(180 52 3 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(138 44 13 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(115 35 13 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 232 243 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 209 232 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 180 217 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 126 184 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 248 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(231 70 148 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(214 31 105 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 18 93 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 21 75 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(117 26 61 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 235 254 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 215 254 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(202 191 253 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(172 148 250 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(246 245 255 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(144 97 249 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 58 242 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(108 43 217 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(85 33 181 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 29 150 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-bright-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 232 232 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 213 213 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 180 180 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 128 128 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 242 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 82 82 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 36 36 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(200 30 30 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(155 28 28 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(119 29 29 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-warm-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 47 73 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-bright-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-bright-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-tremor-background {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-tremor-background-muted {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 178 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 233 106 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 202 21 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(227 160 8 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 253 234 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(194 120 3 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(159 88 10 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(142 75 16 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(114 59 19 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 49 18 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-warm-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-100 {
  --tw-text-opacity: 1;
  color: rgb(225 239 254 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-200 {
  --tw-text-opacity: 1;
  color: rgb(195 221 253 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(164 202 254 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(118 169 250 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-50 {
  --tw-text-opacity: 1;
  color: rgb(235 245 255 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(26 86 219 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 66 159 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(35 56 118 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-cool-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(244 245 247 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(210 214 220 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(159 166 178 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(37 47 63 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(22 30 46 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-100 {
  --tw-text-opacity: 1;
  color: rgb(222 247 236 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-200 {
  --tw-text-opacity: 1;
  color: rgb(188 240 218 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-300 {
  --tw-text-opacity: 1;
  color: rgb(132 225 188 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(49 196 141 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-50 {
  --tw-text-opacity: 1;
  color: rgb(243 250 247 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(14 159 110 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(5 122 85 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(4 108 78 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(3 84 63 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(1 71 55 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgb(229 237 255 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgb(205 219 254 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgb(180 198 252 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgb(141 162 251 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgb(240 245 255 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(104 117 245 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(88 80 236 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgb(81 69 205 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgb(66 56 157 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgb(54 47 120 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-bright-100 {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-bright-200 {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-bright-200\/\[0\.3\] {
  color: #c7d2fe4d;
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-bright-300 {
  --tw-text-opacity: 1;
  color: rgb(165 180 252 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-bright-50 {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-bright-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-bright-700 {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-bright-800 {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-bright-900 {
  --tw-text-opacity: 1;
  color: rgb(54 62 123 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-100 {
  --tw-text-opacity: 1;
  color: rgb(254 236 220 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-200 {
  --tw-text-opacity: 1;
  color: rgb(252 217 189 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-300 {
  --tw-text-opacity: 1;
  color: rgb(253 186 140 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(255 138 76 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-50 {
  --tw-text-opacity: 1;
  color: rgb(255 248 241 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(255 90 31 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-600 {
  --tw-text-opacity: 1;
  color: rgb(208 56 1 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-700 {
  --tw-text-opacity: 1;
  color: rgb(180 52 3 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-800 {
  --tw-text-opacity: 1;
  color: rgb(138 44 13 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-900 {
  --tw-text-opacity: 1;
  color: rgb(115 35 13 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-100 {
  --tw-text-opacity: 1;
  color: rgb(252 232 243 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-200 {
  --tw-text-opacity: 1;
  color: rgb(250 209 232 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-300 {
  --tw-text-opacity: 1;
  color: rgb(248 180 217 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-400 {
  --tw-text-opacity: 1;
  color: rgb(241 126 184 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-50 {
  --tw-text-opacity: 1;
  color: rgb(253 242 248 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(231 70 148 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-600 {
  --tw-text-opacity: 1;
  color: rgb(214 31 105 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-700 {
  --tw-text-opacity: 1;
  color: rgb(191 18 93 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-800 {
  --tw-text-opacity: 1;
  color: rgb(153 21 75 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-900 {
  --tw-text-opacity: 1;
  color: rgb(117 26 61 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-100 {
  --tw-text-opacity: 1;
  color: rgb(237 235 254 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-200 {
  --tw-text-opacity: 1;
  color: rgb(220 215 254 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(202 191 253 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-400 {
  --tw-text-opacity: 1;
  color: rgb(172 148 250 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-50 {
  --tw-text-opacity: 1;
  color: rgb(246 245 255 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(144 97 249 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(126 58 242 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-700 {
  --tw-text-opacity: 1;
  color: rgb(108 43 217 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(85 33 181 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-900 {
  --tw-text-opacity: 1;
  color: rgb(74 29 150 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-bright-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-100 {
  --tw-text-opacity: 1;
  color: rgb(253 232 232 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-200 {
  --tw-text-opacity: 1;
  color: rgb(251 213 213 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(248 180 180 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(249 128 128 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-50 {
  --tw-text-opacity: 1;
  color: rgb(253 242 242 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(240 82 82 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(224 36 36 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(200 30 30 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(155 28 28 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(119 29 29 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-warm-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-100 {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-200 {
  --tw-text-opacity: 1;
  color: rgb(191 219 254 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-300 {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-400 {
  --tw-text-opacity: 1;
  color: rgb(56 189 248 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-50 {
  --tw-text-opacity: 1;
  color: rgb(240 249 255 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-500 {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-700 {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-800 {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-900 {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-950 {
  --tw-text-opacity: 1;
  color: rgb(8 47 73 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-bright-200 {
  --tw-text-opacity: 1;
  color: rgb(153 246 228 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-bright-600 {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-tremor-brand {
  --tw-text-opacity: 1;
  color: rgb(36 128 254 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-tremor-content-emphasis {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-tremor-content-strong {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgb(253 246 178 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgb(252 233 106 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgb(250 202 21 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(227 160 8 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgb(253 253 234 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(194 120 3 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(159 88 10 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgb(142 75 16 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(114 59 19 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgb(99 49 18 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-warm-400 {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:shadow-tremor-input {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ui-active\:bg-tremor-background-muted[data-headlessui-state~="active"] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}

.ui-active\:text-tremor-content-strong[data-headlessui-state~="active"] {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="active"]) .ui-active\:bg-tremor-background-muted {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="active"]) .ui-active\:text-tremor-content-strong {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity, 1));
}

.dark\:bg-opacity-10:is(.dark *) {
  --tw-bg-opacity: .1;
}

.dark\:bg-opacity-25:is(.dark *) {
  --tw-bg-opacity: .25;
}

.dark\:bg-opacity-30:is(.dark *) {
  --tw-bg-opacity: .3;
}

.dark\:opacity-25:is(.dark *) {
  opacity: .25;
}

.dark\:placeholder\:text-tremor-content:is(.dark *)::placeholder {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}

.dark\:placeholder\:text-tremor-content-subtle:is(.dark *)::placeholder {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity, 1));
}

.dark\:hover\:bg-opacity-20:hover:is(.dark *) {
  --tw-bg-opacity: .2;
}

.dark\:hover\:text-tremor-content:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}

.dark\:hover\:text-tremor-content-emphasis:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}

@media (width >= 640px) {
  .sm\:inset-0 {
    inset: 0;
  }

  .sm\:bottom-0 {
    bottom: 0;
  }

  .sm\:order-1 {
    order: 1;
  }

  .sm\:order-first {
    order: -9999;
  }

  .sm\:order-last {
    order: 9999;
  }

  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .sm\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .sm\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .sm\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .sm\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .sm\:m-0 {
    margin: 0;
  }

  .sm\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .sm\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .sm\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:ml-1 {
    margin-left: .25rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:ml-8 {
    margin-left: 2rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-2 {
    margin-top: .5rem;
  }

  .sm\:mt-24 {
    margin-top: 6rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:mt-5 {
    margin-top: 1.25rem;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:inline-flex {
    display: inline-flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-12 {
    height: 3rem;
  }

  .sm\:h-16 {
    height: 4rem;
  }

  .sm\:h-8 {
    height: 2rem;
  }

  .sm\:h-auto {
    height: auto;
  }

  .sm\:w-0 {
    width: 0;
  }

  .sm\:w-10 {
    width: 2.5rem;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:max-w-md {
    max-width: 28rem;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:flex-1 {
    flex: 1;
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .sm\:grid-cols-none {
    grid-template-columns: none;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:overflow-hidden {
    overflow: hidden;
  }

  .sm\:whitespace-nowrap {
    white-space: nowrap;
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:rounded-md {
    border-radius: .375rem;
  }

  .sm\:border {
    border-width: 1px;
  }

  .sm\:border-cool-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
  }

  .sm\:bg-cool-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-3 {
    padding: .75rem;
  }

  .sm\:p-5 {
    padding: 1.25rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .sm\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .sm\:pb-12 {
    padding-bottom: 3rem;
  }

  .sm\:pb-24 {
    padding-bottom: 6rem;
  }

  .sm\:pt-24 {
    padding-top: 6rem;
  }

  .sm\:pt-36 {
    padding-top: 9rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
  }

  .sm\:leading-5 {
    line-height: 1.25rem;
  }

  .sm\:leading-7 {
    line-height: 1.75rem;
  }

  .sm\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(132 225 188 / var(--tw-border-opacity, 1));
  }

  .sm\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(248 180 180 / var(--tw-border-opacity, 1));
  }

  .sm\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(222 247 236 / var(--tw-bg-opacity, 1));
  }

  .sm\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(253 232 232 / var(--tw-bg-opacity, 1));
  }

  .sm\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (width >= 768px) {
  .md\:bottom-12 {
    bottom: 3rem;
  }

  .md\:left-12 {
    left: 3rem;
  }

  .md\:left-auto {
    left: auto;
  }

  .md\:right-12 {
    right: 3rem;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .md\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .md\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .md\:m-2 {
    margin: .5rem;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:ml-\[400px\] {
    margin-left: 400px;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-2 {
    margin-top: .5rem;
  }

  .md\:block {
    display: block;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:h-4 {
    height: 1rem;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:h-screen {
    height: 100vh;
  }

  .md\:min-h-full {
    min-height: 100%;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-4 {
    width: 1rem;
  }

  .md\:w-\[200px\] {
    width: 200px;
  }

  .md\:w-\[calc\(100\%-390px\)\] {
    width: calc(100% - 390px);
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:min-w-\[360px\] {
    min-width: 360px;
  }

  .md\:max-w-screen-lg {
    max-width: 1024px;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .md\:grid-cols-\[360px_1fr\] {
    grid-template-columns: 360px 1fr;
  }

  .md\:grid-cols-\[400px_1fr\] {
    grid-template-columns: 400px 1fr;
  }

  .md\:grid-cols-none {
    grid-template-columns: none;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:gap-x-4 {
    column-gap: 1rem;
  }

  .md\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .md\:overflow-x-visible {
    overflow-x: visible;
  }

  .md\:rounded-lg {
    border-radius: .5rem;
  }

  .md\:border-0 {
    border-width: 0;
  }

  .md\:border-l {
    border-left-width: 1px;
  }

  .md\:p-3 {
    padding: .75rem;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:py-\[80px\] {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:pb-32 {
    padding-bottom: 8rem;
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:pt-4 {
    padding-top: 1rem;
  }

  .md\:pt-\[80px\] {
    padding-top: 80px;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
  }
}

@media (width >= 1024px) {
  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:mr-8 {
    margin-right: 2rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:w-\[240px\] {
    width: 240px;
  }

  .lg\:w-\[calc\(100\%-530px\)\] {
    width: calc(100% - 530px);
  }

  .lg\:max-w-\[200px\] {
    max-width: 200px;
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .lg\:grid-cols-none {
    grid-template-columns: none;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }
}

@media (width >= 1280px) {
  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xl\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .xl\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .xl\:col-span-full {
    grid-column: 1 / -1;
  }

  .xl\:w-\[318px\] {
    width: 318px;
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl\:px-\[224px\] {
    padding-left: 224px;
    padding-right: 224px;
  }
}

@media (width >= 1536px) {
  .\32 xl\:max-w-full {
    max-width: 100%;
  }

  .\32 xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .\32 xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (width >= 390px) {
  .xxs\:inline-flex {
    display: inline-flex;
  }
}

@media (width >= 412px) {
  .xs\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .xs\:inline-flex {
    display: inline-flex;
  }

  .xs\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xs\:pb-7 {
    padding-bottom: 1.75rem;
  }
}

@media (height <= 500px) {
  .short\:-top-\[15\%\] {
    top: -15%;
  }

  .short\:top-\[70\%\] {
    top: 70%;
  }

  .short\:top-\[calc\(50\%-40vh\)\] {
    top: calc(50% - 40vh);
  }

  .short\:hidden {
    display: none;
  }

  .short\:h-\[80vh\] {
    height: 80vh;
  }

  .short\:h-\[90\%\] {
    height: 90%;
  }

  .short\:min-h-\[7rem\] {
    min-height: 7rem;
  }

  .short\:max-w-\[200px\] {
    max-width: 200px;
  }

  .short\:max-w-\[260px\] {
    max-width: 260px;
  }

  .short\:max-w-\[320px\] {
    max-width: 320px;
  }

  .short\:gap-1 {
    gap: .25rem;
  }

  .short\:pb-4 {
    padding-bottom: 1rem;
  }

  .short\:pt-0 {
    padding-top: 0;
  }
}

@media (height <= 400px) {
  .short-xs\:h-\[46\%\] {
    height: 46%;
  }

  .short-xs\:h-\[54\%\] {
    height: 54%;
  }
}

@media (height <= 300px) {
  .short-xxs\:-top-\[23\%\] {
    top: -23%;
  }

  .short-xxs\:top-\[80\%\] {
    top: 80%;
  }

  .short-xxs\:hidden {
    display: none;
  }

  .short-xxs\:h-\[40\%\] {
    height: 40%;
  }

  .short-xxs\:h-\[60\%\] {
    height: 60%;
  }
}

@media (width >= 1346px) {
  .sidekick-xl\:mb-0 {
    margin-bottom: 0;
  }

  .sidekick-xl\:ml-auto {
    margin-left: auto;
  }

  .sidekick-xl\:w-\[290px\] {
    width: 290px;
  }

  .sidekick-xl\:w-\[calc\(100\%-580px\)\] {
    width: calc(100% - 580px);
  }

  .sidekick-xl\:max-w-\[160px\] {
    max-width: 160px;
  }

  .sidekick-xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sidekick-xl\:flex-row {
    flex-direction: row;
  }

  .sidekick-xl\:items-center {
    align-items: center;
  }

  .sidekick-xl\:gap-2 {
    gap: .5rem;
  }
}

@media (width >= 1920px) {
  .\33 xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .\33 xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .\33 xl\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .\33 xl\:ml-0 {
    margin-left: 0;
  }

  .\33 xl\:mr-0 {
    margin-right: 0;
  }

  .\33 xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .\33 xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .\33 xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .\33 xl\:flex-col {
    flex-direction: column;
  }
}

@media (orientation: portrait) {
  .portrait\:mt-1 {
    margin-top: .25rem;
  }

  .portrait\:aspect-\[20\/8\.9\] {
    aspect-ratio: 20 / 8.9;
  }

  .portrait\:h-\[46\%\] {
    height: 46%;
  }

  .portrait\:min-h-0 {
    min-height: 0;
  }

  .portrait\:max-w-\[18rem\] {
    max-width: 18rem;
  }

  .portrait\:max-w-\[320px\] {
    max-width: 320px;
  }

  .portrait\:gap-8 {
    gap: 2rem;
  }

  .portrait\:px-\[20\%\] {
    padding-left: 20%;
    padding-right: 20%;
  }

  .portrait\:pt-0 {
    padding-top: 0;
  }
}

@media (orientation: landscape) {
  .landscape\:mb-\[-7\%\] {
    margin-bottom: -7%;
  }

  .landscape\:mt-\[-11\%\] {
    margin-top: -11%;
  }

  .landscape\:mt-\[-3rem\] {
    margin-top: -3rem;
  }

  .landscape\:block {
    display: block;
  }

  .landscape\:inline {
    display: inline;
  }

  .landscape\:hidden {
    display: none;
  }

  .landscape\:aspect-\[20\/4\.45\] {
    aspect-ratio: 20 / 4.45;
  }

  .landscape\:w-\[105px\] {
    width: 105px;
  }

  .landscape\:w-\[45px\] {
    width: 45px;
  }

  .landscape\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .landscape\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .landscape\:flex-row {
    flex-direction: row;
  }

  .landscape\:gap-3 {
    gap: .75rem;
  }

  .landscape\:px-\[30\%\] {
    padding-left: 30%;
    padding-right: 30%;
  }

  .landscape\:pb-\[12\.5\%\] {
    padding-bottom: 12.5%;
  }

  .landscape\:pt-3 {
    padding-top: .75rem;
  }

  .landscape\:text-xxs {
    font-size: .625rem;
  }
}

.\[\&\:\:-webkit-inner-spin-button\]\:m-0::-webkit-inner-spin-button {
  margin: 0;
}

.\[\&\:\:-webkit-inner-spin-button\]\:appearance-none::-webkit-inner-spin-button {
  appearance: none;
}

.\[\&\:\:-webkit-inner-spin-button\]\:\[-webkit-appearance\:none\]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.\[\&\:\:-webkit-outer-spin-button\]\:m-0::-webkit-outer-spin-button {
  margin: 0;
}

.\[\&\:\:-webkit-outer-spin-button\]\:appearance-none::-webkit-outer-spin-button {
  appearance: none;
}

.\[\&\:\:-webkit-outer-spin-button\]\:\[-webkit-appearance\:none\]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.\[\&\:\:-webkit-scrollbar\]\:hidden::-webkit-scrollbar {
  display: none;
}

.\[\&\:disabled_svg_rect\]\:fill-current:disabled svg rect {
  fill: currentColor;
}

.\[\&\:has\(\:checked\)\]\:border-indigo-600:has(:checked) {
  --tw-border-opacity: 1;
  border-color: rgb(88 80 236 / var(--tw-border-opacity, 1));
}

.\[\&\:has\(\:checked\)\]\:text-indigo-600:has(:checked) {
  --tw-text-opacity: 1;
  color: rgb(88 80 236 / var(--tw-text-opacity, 1));
}

.\[\&\:has\(\:disabled\)\]\:cursor-not-allowed:has(:disabled) {
  cursor: not-allowed;
}

.\[\&\:has\(\:disabled\)\]\:bg-cool-gray-100:has(:disabled) {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.\[\&\:has\(\:focus\)\]\:border-indigo-600:has(:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(88 80 236 / var(--tw-border-opacity, 1));
}

.\[\&\:has\(\:focus\)\]\:text-gray-600:has(:focus) {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.\[\&\:has\(\:focus\)\]\:shadow-lightindigo:has(:focus) {
  --tw-shadow: 0px 0px 2px 2px #4f46e526;
  --tw-shadow-colored: 0px 0px 2px 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\[\&\:has\(\:focus\)\]\:outline:has(:focus) {
  outline-style: solid;
}

.\[\&\:has\(\:focus\)\]\:\!outline-offset-\[-3px\]:has(:focus) {
  outline-offset: -3px !important;
}

.\[\&\:has\(\:focus\)\]\:outline-indigo-600:has(:focus) {
  outline-color: #5850ec;
}

.\[\&\:has\(\:focus\)_\.header-icon_\*\]\:stroke-gray-600:has(:focus) .header-icon * {
  stroke: #4b5563;
}

.\[\&\:has\(input\:disabled\)\:hover\]\:shadow-none:has(input:disabled):hover {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\[\&\:has\(input\:disabled\)\]\:cursor-not-allowed:has(input:disabled) {
  cursor: not-allowed;
}

.\[\&\:has\(input\:disabled\)\]\:bg-\[\#f1f5f9\]:has(input:disabled) {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.\[\&\:has\(input\:disabled\)\]\:shadow-none:has(input:disabled), .\[\&\:has\(textarea\:disabled\)\:hover\]\:shadow-none:has(textarea:disabled):hover {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\[\&\:has\(textarea\:disabled\)\]\:cursor-not-allowed:has(textarea:disabled) {
  cursor: not-allowed;
}

.\[\&\:has\(textarea\:disabled\)\]\:bg-\[\#f1f5f9\]:has(textarea:disabled) {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.\[\&\:has\(textarea\:disabled\)\]\:shadow-none:has(textarea:disabled) {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\[\&\:hover_\.icon\]\:scale-\[1\.2\]:hover .icon {
  --tw-scale-x: 1.2;
  --tw-scale-y: 1.2;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\:hover_\.seat\]\:fill-indigo-300:hover .seat {
  fill: #b4c6fc;
}

.\[\&\:hover_span\]\:text-indigo-bright-600:hover span {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}

.\[\&\:last-child\]\:border-b-0:last-child {
  border-bottom-width: 0;
}

.\[\&\:last-child\]\:pb-0:last-child {
  padding-bottom: 0;
}

.\[\&\>\*\:first-child\]\:mb-2 > :first-child {
  margin-bottom: .5rem;
}

.\[\&\>\*\:first-child\]\:font-medium > :first-child {
  font-weight: 500;
}

.\[\&\>\*\:first-child\]\:leading-\[17px\] > :first-child {
  line-height: 17px;
}

.\[\&\>\*\:first-child\]\:text-cool-gray-500 > :first-child {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}

.\[\&\>\*\:nth-child\(2\)\]\:text-base > :nth-child(2) {
  font-size: 1rem;
}

.\[\&\>\*\:nth-child\(2\)\]\:leading-5 > :nth-child(2) {
  line-height: 1.25rem;
}

.\[\&\>\*\:nth-child\(2\)\]\:text-cool-gray-800 > :nth-child(2) {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity, 1));
}

.\[\&\>\*\]\:scale-\[75\%\] > * {
  --tw-scale-x: 75%;
  --tw-scale-y: 75%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\>\*\]\:scale-\[85\%\] > * {
  --tw-scale-x: 85%;
  --tw-scale-y: 85%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\>\*\]\:cursor-pointer > * {
  cursor: pointer;
}

.\[\&\>\:first-child\]\:flex-1 > :first-child {
  flex: 1;
}

.\[\&\>div\:first-child\]\:mb-0 > div:first-child {
  margin-bottom: 0;
}

.\[\&\>div\:first-of-type\]\:mr-3 > div:first-of-type {
  margin-right: .75rem;
}

.\[\&\>div\]\:mx-0 > div {
  margin-left: 0;
  margin-right: 0;
}

.\[\&\>div\]\:\!bg-cool-gray-300 > div {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity, 1)) !important;
}

.\[\&\>div\]\:shadow-none > div {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\[\&\>form\]\:relative > form {
  position: relative;
}

.\[\&\>label\:first-child\]\:grow > label:first-child {
  flex-grow: 1;
}

.\[\&\>span\]\:h-8 > span {
  height: 2rem;
}

.\[\&\>span\]\:h-\[17px\] > span {
  height: 17px;
}

.\[\&\>span\]\:w-\[64px\] > span {
  width: 64px;
}

.\[\&\>span\]\:w-full > span {
  width: 100%;
}

.\[\&\>span\]\:overflow-hidden > span {
  overflow: hidden;
}

.\[\&\>span\]\:whitespace-nowrap > span {
  white-space: nowrap;
}

.\[\&\>span\]\:p-0 > span {
  padding: 0;
}

.\[\&\>span\]\:px-5 > span {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.\[\&\>span\]\:py-3 > span {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.\[\&\>svg\]\:h-fit > svg {
  height: fit-content;
}

.\[\&\>textarea\]\:text-sm > textarea {
  font-size: .875rem;
}

.\[\&\[data-error\]\]\:m-0[data-error] {
  margin: 0;
}

.\[\&\[data-error\]\]\:border-2[data-error] {
  border-width: 2px;
}

.\[\&\[data-error\]\]\:border-red-600[data-error] {
  --tw-border-opacity: 1;
  border-color: rgb(224 36 36 / var(--tw-border-opacity, 1));
}

.\[\&\[data-error\]\]\:text-gray-600[data-error] {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.\[\&\[data-error\]\]\:shadow-red[data-error] {
  --tw-shadow: 0px 0px 2px 2px #dc262626;
  --tw-shadow-colored: 0px 0px 2px 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\[\&\[data-error\]_\.header-icon_\*\]\:stroke-gray-600[data-error] .header-icon * {
  stroke: #4b5563;
}

.\[\&_\#headlessui-popover-button-1\]\:h-\[38px\] #headlessui-popover-button-1 {
  height: 38px;
}

.\[\&_\#headlessui-popover-button-1\]\:rounded-br-none #headlessui-popover-button-1 {
  border-bottom-right-radius: 0;
}

.\[\&_\#headlessui-popover-button-1\]\:rounded-tr-none #headlessui-popover-button-1 {
  border-top-right-radius: 0;
}

.\[\&_\#headlessui-popover-button-1\]\:border-0 #headlessui-popover-button-1 {
  border-width: 0;
}

.\[\&_\#headlessui-popover-button-1\]\:hover\:bg-cool-gray-50:hover #headlessui-popover-button-1 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}

.\[\&_\*\]\:cursor-pointer * {
  cursor: pointer;
}

.\[\&_\*\]\:whitespace-nowrap * {
  white-space: nowrap;
}

.\[\&_\*\]\:fill-\[\#B51E1E\] * {
  fill: #b51e1e;
}

.\[\&_\*\]\:fill-\[\#EA580C\] * {
  fill: #ea580c;
}

.\[\&_\*\]\:fill-\[\#f59e0b\] * {
  fill: #f59e0b;
}

.\[\&_\*\]\:fill-black * {
  fill: #000;
}

.\[\&_\*\]\:fill-cool-gray-300 * {
  fill: #cbd5e1;
}

.\[\&_\*\]\:fill-cool-gray-400 * {
  fill: #94a3b8;
}

.\[\&_\*\]\:fill-cool-gray-500 * {
  fill: #64748b;
}

.\[\&_\*\]\:fill-cool-gray-800 * {
  fill: #1e293b;
}

.\[\&_\*\]\:fill-green-600 * {
  fill: #057a55;
}

.\[\&_\*\]\:fill-green-warm-600 * {
  fill: #16a34a;
}

.\[\&_\*\]\:fill-indigo-600 * {
  fill: #5850ec;
}

.\[\&_\*\]\:fill-indigo-bright-600 * {
  fill: #4f46e5;
}

.\[\&_\*\]\:fill-orange-500 * {
  fill: #ff5a1f;
}

.\[\&_\*\]\:fill-red-600 * {
  fill: #e02424;
}

.\[\&_\*\]\:fill-white * {
  fill: #fff;
}

.\[\&_\*\]\:\!stroke-white * {
  stroke: #fff !important;
}

.\[\&_\*\]\:stroke-black * {
  stroke: #000;
}

.\[\&_\*\]\:stroke-cool-gray-400 * {
  stroke: #94a3b8;
}

.\[\&_\*\]\:stroke-green-400 * {
  stroke: #31c48d;
}

.\[\&_\*\]\:stroke-red-600 * {
  stroke: #e02424;
}

.\[\&_\*\]\:stroke-white * {
  stroke: #fff;
}

.\[\&_\*\]\:stroke-yellow-300 * {
  stroke: #faca15;
}

.\[\&_\*\]\:stroke-1 * {
  stroke-width: 1px;
}

.\[\&_\*\]\:stroke-\[2\.5px\] * {
  stroke-width: 2.5px;
}

.\[\&_\*\]\:transition-all * {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\[\&_\.Select-input\]\:pl-\[2\.75rem_\!important\] .Select-input, .\[\&_\.Select-placeholder\]\:pl-\[2\.75rem_\!important\] .Select-placeholder {
  padding-left: 2.75rem !important;
}

.\[\&_\.Select-value-label\]\:pl-\[1\.75rem_\!important\] .Select-value-label {
  padding-left: 1.75rem !important;
}

.\[\&_\.circle\]\:fill-indigo-300 .circle {
  fill: #b4c6fc;
}

.\[\&_\.circle\]\:fill-indigo-600 .circle {
  fill: #5850ec;
}

.\[\&_\.fill\]\:fill-\[\#e0e7ff\] .fill {
  fill: #e0e7ff;
}

.\[\&_\.fill\]\:fill-indigo-600 .fill {
  fill: #5850ec;
}

.\[\&_\.fill\]\:fill-white .fill {
  fill: #fff;
}

.\[\&_\.fill\]\:stroke-indigo-600 .fill {
  stroke: #5850ec;
}

.\[\&_\.header-icon_\*\]\:stroke-gray-400 .header-icon * {
  stroke: #9fa6b2;
}

.\[\&_\.icon\:not\(\.circle\)\]\:fill-white .icon:not(.circle) {
  fill: #fff;
}

.\[\&_\.icon\]\:scale-\[1\.2\] .icon {
  --tw-scale-x: 1.2;
  --tw-scale-y: 1.2;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&_\.icon\]\:stroke-white .icon {
  stroke: #fff;
}

.\[\&_\.icon\]\:\[transform-origin\:50\%_50\%\] .icon {
  transform-origin: 50%;
}

.\[\&_\.icon\]\:\[transform-origin\:50\%_63\%\] .icon {
  transform-origin: 50% 63%;
}

.\[\&_\.min-w-min\]\:min-w-\[280px\] .min-w-min {
  min-width: 280px;
}

.\[\&_\.react-datepicker-wrapper\]\:\!block .react-datepicker-wrapper {
  display: block !important;
}

.\[\&_\.recharts-bar-rectangle\:nth-child\(1\)_rect\]\:fill-indigo-bright-600 .recharts-bar-rectangle:first-child rect {
  fill: #4f46e5;
}

.\[\&_\.recharts-bar-rectangle\:nth-child\(2\)_path\]\:fill-red-warm-400 .recharts-bar-rectangle:nth-child(2) path, .\[\&_\.recharts-bar-rectangle\:nth-child\(2\)_rect\]\:fill-red-warm-400 .recharts-bar-rectangle:nth-child(2) rect {
  fill: #f87171;
}

.\[\&_\.recharts-bar-rectangle\:nth-child\(3\)_path\]\:fill-purple-bright-500 .recharts-bar-rectangle:nth-child(3) path, .\[\&_\.recharts-bar-rectangle\:nth-child\(3\)_rect\]\:fill-purple-bright-500 .recharts-bar-rectangle:nth-child(3) rect {
  fill: #a855f7;
}

.\[\&_\.recharts-bar-rectangle\:nth-child\(4\)_path\]\:fill-yellow-warm-400 .recharts-bar-rectangle:nth-child(4) path, .\[\&_\.recharts-bar-rectangle\:nth-child\(4\)_rect\]\:fill-yellow-warm-400 .recharts-bar-rectangle:nth-child(4) rect {
  fill: #fbbf24;
}

.\[\&_\.recharts-cartesian-axis-tick-value\]\:transform-none .recharts-cartesian-axis-tick-value {
  transform: none;
}

.\[\&_\.seat\]\:fill-indigo-300 .seat {
  fill: #b4c6fc;
}

.\[\&_\.seat\]\:fill-indigo-600 .seat {
  fill: #5850ec;
}

.\[\&_\.seat\]\:fill-white .seat {
  fill: #fff;
}

.\[\&_\.seat\]\:stroke-cool-gray-400 .seat {
  stroke: #94a3b8;
}

.\[\&_\.seat\]\:stroke-indigo-300 .seat {
  stroke: #b4c6fc;
}

.\[\&_\.seat\]\:stroke-indigo-600 .seat {
  stroke: #5850ec;
}

.\[\&_\.steering-wheel\]\:fill-indigo-300 .steering-wheel {
  fill: #b4c6fc;
}

.\[\&_\.steering-wheel\]\:fill-indigo-600 .steering-wheel {
  fill: #5850ec;
}

.\[\&_\.steering-wheel\]\:fill-white .steering-wheel {
  fill: #fff;
}

.\[\&_\.steering-wheel\]\:stroke-white .steering-wheel {
  stroke: #fff;
}

.\[\&_\.text-tremor-content-emphasis\]\:text-cool-gray-500 .text-tremor-content-emphasis {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}

.peer:checked ~ .peer-checked\:\[\&_\:first-child\]\:opacity-100 :first-child {
  opacity: 1;
}

.peer:checked ~ .peer-checked\:\[\&_\:last-child\]\:opacity-0 :last-child {
  opacity: 0;
}

.\[\&_\:not\(\.icon\)\]\:stroke-indigo-300 :not(.icon) {
  stroke: #b4c6fc;
}

.\[\&_\:not\(\.icon\)\]\:stroke-indigo-600 :not(.icon) {
  stroke: #5850ec;
}

.\[\&_\:not\(\.icon\)\]\:stroke-white :not(.icon) {
  stroke: #fff;
}

.\[\&_\>_\*\]\:\!px-0 > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.\[\&_a\]\:mb-\[-3px\] a {
  margin-bottom: -3px;
}

.\[\&_a\]\:inline-block a {
  display: inline-block;
}

.\[\&_a\]\:max-w-\[225px\] a {
  max-width: 225px;
}

.\[\&_a\]\:max-w-\[61px\] a {
  max-width: 61px;
}

.\[\&_a\]\:max-w-\[65px\] a {
  max-width: 65px;
}

.\[\&_a\]\:overflow-hidden a {
  overflow: hidden;
}

.\[\&_a\]\:text-ellipsis a {
  text-overflow: ellipsis;
}

.\[\&_a\]\:whitespace-nowrap a {
  white-space: nowrap;
}

.\[\&_a\]\:hover\:text-indigo-bright-600:hover a {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}

@media (width >= 1346px) {
  .sidekick-xl\:\[\&_a\]\:max-w-\[160px\] a {
    max-width: 160px;
  }
}

.\[\&_button\]\:border-b-transparent button {
  border-bottom-color: #0000;
}

.\[\&_button\]\:border-l-transparent button {
  border-left-color: #0000;
}

.\[\&_button\]\:border-t-transparent button {
  border-top-color: #0000;
}

.\[\&_button\]\:bg-cool-gray-100 button {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.\[\&_circle\.icon\]\:fill-indigo-300 circle.icon {
  fill: #b4c6fc;
}

.\[\&_circle\]\:stroke-\[\#D97706\] circle {
  stroke: #d97706;
}

.\[\&_circle\]\:stroke-yellow-700 circle {
  stroke: #8e4b10;
}

.\[\&_div\]\:outline div {
  outline-style: solid;
}

.\[\&_div\]\:outline-\[1\.5px\] div {
  outline-width: 1.5px;
}

.\[\&_div\]\:outline-indigo-500 div {
  outline-color: #6875f5;
}

.\[\&_input\:\:placeholder\]\:\!text-sm input::placeholder {
  font-size: .875rem !important;
}

.\[\&_input\:focus\]\:\!border-indigo-500 input:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgb(104 117 245 / var(--tw-border-opacity, 1)) !important;
}

.\[\&_input\:focus\]\:\!shadow-outline-red input:focus {
  --tw-shadow: 0 0 0 3px #fecaca80 !important;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.\[\&_input\]\:\!h-\[48px\] input {
  height: 48px !important;
}

.\[\&_input\]\:\!w-full input {
  width: 100% !important;
}

.\[\&_input\]\:\!min-w-\[0px\] input {
  min-width: 0 !important;
}

.\[\&_input\]\:max-w-\[100px\] input {
  max-width: 100px;
}

.\[\&_input\]\:flex-1 input {
  flex: 1;
}

.\[\&_input\]\:\!rounded input {
  border-radius: .25rem !important;
}

.\[\&_input\]\:\!border input {
  border-width: 1px !important;
}

.\[\&_input\]\:\!border-gray-300 input {
  --tw-border-opacity: 1 !important;
  border-color: rgb(210 214 220 / var(--tw-border-opacity, 1)) !important;
}

.\[\&_input\]\:\!border-red-700 input {
  --tw-border-opacity: 1 !important;
  border-color: rgb(200 30 30 / var(--tw-border-opacity, 1)) !important;
}

.\[\&_input\]\:\!px-4 input {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.\[\&_input\]\:\!py-0 input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.\[\&_input\]\:pr-0 input {
  padding-right: 0;
}

.\[\&_input\]\:text-center input {
  text-align: center;
}

.\[\&_input\]\:\!text-sm input {
  font-size: .875rem !important;
}

.even\:\[\&_label\>div\>input\]\:max-w-\[5ch\] label > div > input:nth-child(2n) {
  max-width: 5ch;
}

.\[\&_label\]\:w-full label {
  width: 100%;
}

.\[\&_label\]\:max-w-sm label {
  max-width: 24rem;
}

.\[\&_mask\]\:stroke-\[\#D97706\] mask {
  stroke: #d97706;
}

.\[\&_path\:first-child\]\:fill-cool-gray-500 path:first-child {
  fill: #64748b;
}

.\[\&_path\:first-child\]\:fill-gray-500 path:first-child {
  fill: #6b7280;
}

.\[\&_path\:first-child\]\:fill-yellow-warm-700 path:first-child {
  fill: #d97706;
}

.\[\&_path\:first-of-type\]\:fill-red-warm-600 path:first-of-type {
  fill: #dc2626;
}

.\[\&_path\:last-child\]\:fill-white path:last-child {
  fill: #fff;
}

.\[\&_path\:last-of-type\]\:stroke-white path:last-of-type {
  stroke: #fff;
}

.\[\&_path\]\:scale-110 path {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&_path\]\:fill-\[\#047A55\] path {
  fill: #047a55;
}

.\[\&_path\]\:fill-\[\#D97706\] path {
  fill: #d97706;
}

.\[\&_path\]\:fill-blue-500 path {
  fill: #3f83f8;
}

.\[\&_path\]\:fill-blue-600 path {
  fill: #2563eb;
}

.\[\&_path\]\:fill-cool-gray-400 path {
  fill: #94a3b8;
}

.\[\&_path\]\:fill-cool-gray-400\/60 path {
  fill: #94a3b899;
}

.\[\&_path\]\:fill-cool-gray-500 path {
  fill: #64748b;
}

.\[\&_path\]\:fill-cool-gray-600 path {
  fill: #475569;
}

.\[\&_path\]\:fill-cool-gray-700 path {
  fill: #334155;
}

.\[\&_path\]\:fill-gray-100 path {
  fill: #f4f5f7;
}

.\[\&_path\]\:fill-gray-500 path {
  fill: #6b7280;
}

.\[\&_path\]\:fill-gray-600 path {
  fill: #4b5563;
}

.\[\&_path\]\:fill-green-600 path {
  fill: #057a55;
}

.\[\&_path\]\:fill-green-700 path {
  fill: #046c4e;
}

.\[\&_path\]\:fill-green-warm-600 path {
  fill: #16a34a;
}

.\[\&_path\]\:fill-progressive-100 path {
  fill: #0077b3;
}

.\[\&_path\]\:fill-red-600 path {
  fill: #e02424;
}

.\[\&_path\]\:fill-yellow-400 path {
  fill: #e3a008;
}

.\[\&_path\]\:fill-yellow-700 path {
  fill: #8e4b10;
}

.\[\&_path\]\:fill-yellow-warm-600 path {
  fill: #f59e0b;
}

.\[\&_path\]\:stroke-\[\#D97706\] path {
  stroke: #d97706;
}

.\[\&_path\]\:stroke-black path {
  stroke: #000;
}

.\[\&_path\]\:stroke-cool-gray-300 path {
  stroke: #cbd5e1;
}

.\[\&_path\]\:stroke-cool-gray-400 path {
  stroke: #94a3b8;
}

.\[\&_path\]\:stroke-cool-gray-600 path {
  stroke: #475569;
}

.\[\&_path\]\:stroke-gray-300 path {
  stroke: #d2d6dc;
}

.\[\&_path\]\:stroke-gray-700 path {
  stroke: #374151;
}

.\[\&_path\]\:stroke-indigo-300 path {
  stroke: #b4c6fc;
}

.\[\&_path\]\:stroke-2 path {
  stroke-width: 2px;
}

.first\:\[\&_path\]\:fill-gray-600 path:first-child {
  fill: #4b5563;
}

.first\:\[\&_rect\]\:fill-cool-gray-600 rect:first-child {
  fill: #475569;
}

.\[\&_svg\.sedan_\.secondRow\]\:hidden svg.sedan .secondRow {
  display: none;
}

.\[\&_svg\:not\(\.sedan\)\]\:z-10 svg:not(.sedan) {
  z-index: 10;
}

.\[\&_svg\]\:text-cool-gray-500 svg {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}

.\[\&_svg_\.body\]\:hidden svg .body {
  display: none;
}

.\[\&_td\]\:h-\[inherit\] td {
  height: inherit;
}

@media (width >= 1350px) {
  .\[\@media\(min-width\:1350px\)\]\:w-\[calc\(100\%-580px\)\] {
    width: calc(100% - 580px);
  }

  .\[\@media\(min-width\:1350px\)\]\:min-w-\[700px\] {
    min-width: 700px;
  }
}
/*# sourceMappingURL=index.8829cc87.css.map */
